@font-face
  font-family: 'icomoon'
  src:  url('../fonts/icomoon/fonts/icomoon.eot')
  src:  url('../fonts/icomoon/fonts/icomoon.eot') format('embedded-opentype'), url('../fonts/icomoon/fonts/icomoon.ttf') format('truetype'), url('../fonts/icomoon/fonts/icomoon.woff') format('woff'),  url('../fonts/icomoon/fonts/icomoon.svg') format('svg')
  font-weight: normal
  font-style: normal
  font-display: block

[class^="icon-"], [class*=" icon-"]
  font-family: 'icomoon' !important
  speak: never
  speak: never
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-eye:before
  content: "\e910"

.icon-close:before
  content: "\e91a"

.icon-arrow-l:before
  content: "\e900"

.icon-arrow-r:before
  content: "\e901"

.icon-blockquote:before
  content: "\e902"

.icon-burger:before
  content: "\e903"

.icon-calendar:before
  content: "\e904"

.icon-calendar-star:before
  content: "\e905"

.icon-check:before
  content: "\e906"

.icon-chevron-down:before
  content: "\e907"

.icon-chevron-down2:before
  content: "\e908"

.icon-chevron-L:before
  content: "\e909"

.icon-cup .path1:before
  content: "\e90a"
  color: rgb(0, 0, 0)
  opacity: 0.3

.icon-cup .path2:before
  content: "\e90b"
  margin-left: -1em
  color: rgb(0, 0, 0)

.icon-cursor:before
  content: "\e90c"

.icon-document:before
  content: "\e90d"

.icon-dzen:before
  content: "\e90e"
  color: #fff

.icon-external:before
  content: "\e90f"

.icon-eye:before
  content: "\e910"

.icon-filter:before
  content: "\e911"

.icon-hourse:before
  content: "\e912"

.icon-location:before
  content: "\e913"

.icon-plus:before
  content: "\e914"

.icon-price:before
  content: "\e915"

.icon-shevron-R:before
  content: "\e916"

.icon-star:before
  content: "\e917"

.icon-star2:before
  content: "\e918"

.icon-trophy:before
  content: "\e919"

.icon-ic-socials-facebook:before
  content: "\e93e"

.icon-ic-socials-instagram:before
  content: "\e93f"

.icon-ic-socials-odnoklassniki:before
  content: "\e940"

.icon-ic-socials-telegram:before
  content: "\e941"

.icon-ic-socials-viber:before
  content: "\e942"

.icon-ic-socials-vk:before
  content: "\e943"

.icon-ic-socials-whatsapp:before
  content: "\e944"

.icon-ic-socials-youtube:before
  content: "\e945"
