.card
  flex-shrink: 0
  flex-grow: 0
  display: flex
  flex-direction: column
  border-radius: var(--border-corner)
  background-color: var(--c-bck-default)
  overflow: hidden

.card--content
  display: flex
  flex-direction: column
  gap: var(--g-8)
  padding: var(--g-24)
  height: 100%
  //height: 100%

.card--title,
.card--title > *,
.card--text,
.card--text p
  text-overflow: ellipsis
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  line-height: 1.2

.card--text
  font-size: var(--fs-18)
  letter-spacing: 0.02em

.card--title
  font-size: var(--fs-32)
  font-weight: 400
  line-height: 1.33
  letter-spacing: 0.01em

.card--video
  &:after
    pointer-events: none
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52' fill='none'%3E%3Cg filter='url(%23filter0_b_7699_76827)'%3E%3Ccircle cx='26' cy='26' r='26' fill='black' fill-opacity='0.2'/%3E%3Ccircle cx='26' cy='26' r='25' stroke='white' stroke-width='2'/%3E%3C/g%3E%3Cpath d='M35.75 26L19.5 35.75L19.5 16.25L35.75 26Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_b_7699_76827' x='-24' y='-24' width='100' height='100' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImageFix' stdDeviation='12'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_7699_76827'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_7699_76827' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E")
    background-position: center
    background-size: contain
    top: 16px
    left: auto
    right: var(--g-20)
    content: ''
    position: absolute
    width: 64px
    height: 64px
    z-index: 2
    @media(max-width: 768px)
      width: 52px
      height: 52px
  video
    width: 100%
    height: 100%
    object-fit: cover

.card--image,
.card--video
  display: flex
  align-items: flex-start
  position: relative
  z-index: 1
  aspect-ratio: 1 / 1
  height: auto
  width: 100%
  background-image: url('/local/templates/main/assets/img/default-image.jpg')
  background-color: var(--c-surface-subdued)
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  overflow: hidden
  border-radius: var(--border-corner)
  flex: 0 0 auto
  img
    transition: var(--transition)

.card-detail
  .card--image
    background: unset
.card--label
  display: flex
  flex-wrap: wrap
  gap: var(--g-8)
  font-size: var(--fs-14)
  font-weight: 400
  line-height: 1.42
  z-index: 3
  top: 0
  left: 0
  span
    display: flex
    gap: 6px
    white-space: nowrap
    background-color: var(--c-surface-default)
    color: var(--c-text-subdued)
    padding: 10px 10px
    border-radius: 4px
    &:before
      font-family: 'icomoon' !important
      line-height: 1
      font-size: 20px

  .c-accent
    color: var(--accent-2)
  .c-accent-2
    color: var(--accent)
  .label-tourney:before
    content: '\e90a'
  .label-money:before
    content: '\e915'
  .label-horse:before
    content: '\e912'
  .label-date:before
    content: '\e904'

.card--actions
  margin-top: auto
  .button
    margin-top: 16px
    width: 100%
    padding: 9px
    @media(min-width: 769px)
      padding: 13px

.card:not([data-popup_cascade]):not([href])
  .card--actions
    display: none


.card[data-popup_cascade]
  position: relative
  cursor: pointer
  .card--action
    .icon
      font-family: 'icomoon' !important
      &:before
        content: "\e910"
  &:hover,
  &:focus
    .card--action:before
      background-color: rgba(20, 26, 37, 0.35)
    .card--action
      color: var(--c-surface-dark)
      &:after
        transform: rotate(45deg)
        background-color: var(--c-surface-default)


.card[href]
  position: relative
  cursor: pointer
  &:hover,
  &:focus
    .card--image
      img
        scale: 1.1
    .card--action:before
      background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .1965161064) 72%, rgba(220, 220, 220, 0) 100%)
    .card--image:before
      opacity: 1
.content_news .card,
.content_card-image .card
  .card--image
    &:before
      z-index: 2
      content: ""
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0
      background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .1965161064) 72%, rgba(220, 220, 220, 0) 100%)
      transition: var(--transition)
      opacity: 0.4
  &:hover,
  &:focus
    img
      scale: 1.1
.content_hourse
  .card
    background-color: #F2F5FA
    display: flex
    flex-direction: column
    @media(min-width: 769px)
      height: max-content
      display: grid
      grid-template-columns: repeat(2, 1fr)
    &--image
      aspect-ratio: 1/1
      @media(min-width: 769px)
        aspect-ratio: 464 / 575
    &--content
      padding: var(--g-24)
    &--title
      -webkit-line-clamp: 2
      font-size: 32px
    &--subtitle
      color: var( --c-text-subdued)
      font-size: var(--fs-16)
    &--text
      font-size: var(--fs-20)
      padding-bottom: var(--g-24)
      @media(min-width: 769px)
        border-bottom: 1px solid rgba(17, 24, 32,0.4)
      p
        text-overflow: ellipsis
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
    &--info
      padding: 12px 0
      display: flex
      flex-direction: column
      gap: 12px
      @media(max-width: 768px)
        display: none
      & > *
        display: flex
        align-items: baseline
        &:before
          content: ''
          border-bottom: 2px dotted  #cccccc
          flex-grow: 1
          order: 1
          margin: 0 5px
          min-width: 20px
        p:first-child
          color: var( --c-text-subdued)
        p + p
          order: 2
    &--actions
      .button
        width: 100%
    &--label
      position: absolute
      padding: 16px
      max-width: 25%
    .c-accent
      &:before
        content: '\e917'
  &.block_list:not(block_list-slider)
    .card
      @media(min-width: 1024px)
        grid-template-columns: repeat(2, 1fr)


.content_hourse-detail
  .block--content
    grid-row: 1 / span 2
  .card
    background-color: transparent
    display: flex
    flex-direction: row-reverse
    gap: 8px
    &--content
      padding: var(--g-32) var(--g-80)
      border-radius: var(--border-corner)
      background-color: #f2f5fa
    &--label
      position: static
      padding: 0
      max-width: 100%
    &--image
      max-width: 50%
      aspect-ratio: 1/1
    &--title
      font-size: var(--fs-96)
      h3
        margin-top: 0
    &--subtitle
      font-size: var(--fs-24)
    &--text
      font-size: var(--fs-32)
      border-bottom: 0
    &--info
      display: flex
      gap: var(--g-24)
      & > *
        font-size: var(--fs-24)
    &--actions
      gap: 16px
      display: flex
      .button
        width: auto
        padding: 13px 20px

.content_news
  .card
    position: relative
    aspect-ratio: 360 / 480
    cursor: pointer
    width: 100%
    height: auto
    max-height: 533px
    align-self: start
    @media(min-width: 769px)
      aspect-ratio: 613 / 656
    .card--content
      display: grid
      grid-template-columns: 1fr auto
      grid-template-rows: 1fr
      align-items: end
      position: absolute
      left: 0
      bottom: 0
      width: 100%
      height: 100%
      color: var(--c-text-primary)
      z-index: 3
      justify-content: flex-end
    &--title,
    &--label
      grid-column: span 2
    &--text
      color: var(--c-text-primary)
      opacity: 0.8
    .card--image
      width: 100%
      max-width: 100%
      height: 100%
      aspect-ratio: initial
      position: relative
      &:after
        content: ""
        width: 100%
        height: 100%
        position: absolute
        left: 0
        top: 0
        background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.196516106442577) 72%, rgba(220,220,220,0) 100%)
        z-index: 2
        pointer-events: none
    .card--actions
      grid-column: 2


.content_offers
  .card
    display: flex
    flex-direction: column
    @media(min-width: 768px)
      display: grid
      grid-template-columns: 1fr
    @media(min-width: 998px)
      grid-template-columns: repeat(2, 1fr)
  .card--content
    @media(max-width: 768px)
      gap: 0
  .card--title
    grid-area: title
    padding-bottom: var(--g-24)
    border-bottom: 1px solid rgba(17, 24, 32, .4)
    h3
      -webkit-line-clamp: 2
    @media(max-width: 768px)
      line-height: 1.5
  .card--label
    margin-bottom: 8px
  .card--text
    padding: 16px 0
    font-size: clamp(14px, calc(14px + (20 - 14) * ((100vw - 1024px) / (1920 - 1024))), 20px)
    line-height: 1.4
    p
      -webkit-line-clamp: 4
  .card--actions
    margin-top: auto
  .card--image
    aspect-ratio: 320/240
    @media(min-width: 998px)
      aspect-ratio: initial



.content_events,
.content_events-calendar
  .card
    display: grid
    grid-template-columns: 1fr
    @media(min-width: 1140px)
      grid-template-columns: minmax(250px, 300px) 1fr
    &--image
      aspect-ratio: 320 / 220
      @media(min-width: 1024px)
        aspect-ratio: 1/1
    &--content
      display: grid
      grid-template-columns: 1fr max-content
      //height: auto
    &--subtitle
      grid-column: span 2
      color: var(--accent-2)
      font-size: var(--fs-28)
      display: flex
      gap: 6px
      span:last-of-type
        opacity: 0.7
    &--title
      grid-column: span 2
    &--actions
      grid-column: 2
      margin-top: auto
      @media(max-width: 1024px)
        margin-left: auto
    &--text
      grid-column: 1
    &--label
      margin-bottom: auto
      grid-column: 1

body:not(.page-calendar) .content_events
  .card:first-of-type
    @media(min-width: 1140px)
      display: block
    .card--image
      @media(min-width: 1140px)
        aspect-ratio: initial
        height: 100%
        position: absolute
        inset: 0
    .card--content
      @media(min-width: 1140px)
        z-index: 2
        position: absolute
        top: auto
        right: 27%
        left: 8px
        bottom: 8px
        height: auto
        background-color: var(--c-surface-default)
        border-radius: var(--border-corner)
      .card--label
        span
          @media(min-width: 1140px)
            background-color: var(--c-bck-default)
  .card:not(:nth-child(-n+1))
    .card--image
      @media(min-width: 1141px)
        aspect-ratio: 16/14
  .card:not(:nth-child(-n+2))

    @media(min-width: 1141px)
      margin-top: var(--g-20)


.content_events-calendar
  .card
    display: flex
    flex-direction: column
    &--image
      aspect-ratio: 453/368
    &--content
      grid-template-rows: max-content


.content_card-image
  .card
    aspect-ratio: 1/1
    height: max-content
    &--content
      z-index: 2
      position: absolute
      top: auto
      left: 16px
      right: 16px
      bottom: 16px
      color: var(--c-surface-default)
      display: grid
      grid-template-columns: 1fr auto
      padding: 0
      height: auto
    &--title,
    &--text
      grid-column: 1
    &--actions
      grid-column: 2
      margin-top: auto
  a.card,
  .card[data-popup_cascade]
    .card--image:after
      position: absolute
      z-index: 2
      inset: 0
      content: ''
      pointer-events: none
      background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .1965161064) 40%, rgba(220, 220, 220, 0) 100%)

.content_advantages
  .card
    overflow: hidden
    &--content
      gap: clamp(48px, calc(48px + (110 - 48) * ((100vw - 768px) / (1920 - 768))), 110px)
      height: 100%
      justify-content: space-between
      transition: var(--transition)
    &--title
      font-size: var(--fs-52)
      text-transform: uppercase
      transition: var(--transition)
    &--text
      font-size: var(--fs-24)
      -webkit-line-clamp: 20
      p
        -webkit-line-clamp: 20
    &:hover
      .card--title
        color: var(--c-surface-default)
      .card--content
        color: var(--c-text-primary)
        background-color: var(--accent)
.content_advantages:not(.block_list-slider)
  .card
    overflow: hidden
    @media(min-width: 768px)
      flex: 25% 1 1


.content_partners.content_card-image
  .card--image:before
    content: none
.content_history
  .card
    gap: 24px
    border-radius: 0
    background-color: transparent

    &--pin
      display: flex
      align-items: center
      gap: 6px

      .pin
        width: 32px
        height: 32px
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath d='M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z' fill='white'/%3E%3Cpath d='M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16Z' fill='%231654CE'/%3E%3C/svg%3E")
        background-position: center
        background-size: contain
        background-repeat: no-repeat
        scale: 0.7
        transition: var(--transition)
        @media(min-width: 768px)
          width: 48px
          height: 48px
      .line
        width: 100%
        height: 4px
        //border-bottom: dashed 4px #fff
        background-image: linear-gradient(to right, #fff 55%, transparent 55%)
        background-size: 32px 4px
        background-repeat: repeat-x
        position: relative

    &--content
      margin-right: var(--g-56)
      padding: 0
      height: auto
    &--title
      p
        font-size: var(--fs-52)
    &--text,
    &--text p
      font-size: var(--fs-20)
      -webkit-line-clamp: initial
    &--image
      width: auto
      margin-right: var(--g-56)
      aspect-ratio: 560/360
  .card:last-of-type
    .line
      display: none
  .card.swiper-slide-active
    .pin
      scale: 1
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'%3E%3Ccircle cx='24' cy='24' r='24' fill='white'/%3E%3Ccircle cx='24' cy='24' r='15' fill='%23FC563B'/%3E%3C/svg%3E")

.content_news,
.content_card-image,
.content_events,
.content_events-calendar
  .button:before
    transition: var(--transition)


