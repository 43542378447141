.content_history
  overflow: hidden
  background-color: var(--accent)
  color: var(--c-surface-default)
  .block--elements
    height: max-content
  .slider_controls
    .button
      --outlined-bc-base: #e0e7f3
      --outlined-c-base: #e0e7f3
      --outlined-bc-hover: #e0e7f3
      --outlined-bgc-hover: #e0e7f3
      --outlined-c-hover: #111820
  .slider_controls .swiper-pagination-progressbar
    border-color: #FFFFFF
    .swiper-pagination-progressbar-fill
      background-color: #FFFFFF
  .block--sections button
    color: var(--c-text-primary)
    &:hover
      color: var(--c-text-disabled)

