.is_processing:not(.form)
  position: relative
  &:after
    content: ''
    inset: 0
    z-index: 3
    position: absolute
    background-color: rgba(244, 248, 255, 0.7)
    background-image: url('/local/templates/main/assets/img/preloader.gif')
    background-repeat: no-repeat
    background-position: center
