.block_list.content_faq
  .block--wrapper
    display: grid
    grid-template-areas: inherit
    grid-template-columns: 1fr 1fr
    gap: 20px
    background-color: var(--bg-color, transparent)
    @media(max-width: 768px)
      display: flex
      flex-direction: column
      grid-gap: 8px
    .block--head
      border-bottom: none
      display: flex
      flex-direction: column
      grid-column: 1
      @media(min-width: 769px)
        padding: 16px var(--g-80)
    .block--elements,
    .block--actions
      grid-column: 2
      @media(max-width: 768px)
        grid-column: 1
    .block--actions
      padding: 0
      margin-right: auto
    .block--actions:empty
      display: none
    .block--elements
      margin-top: 0
      width: 100%
      grid-template-columns: 1fr
      gap: 0
      .accordion
        background-color: var(--bg-c-content, #f2f5fa)
        max-width: 100%
