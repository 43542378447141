@charset "UTF-8";
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 1em;
  font-weight: 300;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden], .hidden {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

html {
  -webkit-tap-highlight-color: transparent;
}

html {
  --c-surface-default: #fff;
  --c-surface-subdued: #f2f5fa;
  --c-surface-subdued-hovered: #E0E1E3;
  --c-surface-dark: #111820;
  --c-surface-dark-secondary: #141A25;
  --c-surface-primary: #F4EEEB;
  --c-surface-primary-hovered: #D3BBAE;
  --c-text-default: #111820;
  --c-text-subdued: #404B57;
  --c-text-disabled: #AAADB1;
  --c-text-primary: #FFFFFF;
  --c-text-accent-default: #AAADB1;
  --c-text-accent-hovered: #AAADB1;
  --c-text-critical: #FA5184;
  --c-bck-dark-blue: #052360;
  --c-bck-green: #055152;
  --c-bck-default: #F2F5FA;
  --c-border-default: #111820;
  --c-border-subdued: #FFFFFF;
  --c-border-hovered: #141A25;
  --c-border-others: #E0E1E3;
  --c-border-secondary: rgba(17, 24, 32, 0.16);
  --border-corner: 16px;
  --border-corner-button: 50px;
  --border-corner-input: 4px;
  --c-critical: #FA5184;
  --c-success: #08AA66;
  --c-warning: #E5B408;
  --accent: #093282;
  --accent-2: #FC563B;
  --g-8: 8px;
  --g-16: 16px;
  --g-container-vertical: clamp(32px, calc(32px + (60 - 32) * ((100vw - 375px) / (1920 - 375))), 60px);
  --g-container: clamp(8px, calc(8px + (20 - 8) * ((100vw - 375px) / (1920 - 375))), 20px);
  --g-section-inner: clamp(16px, calc(16px + (56 - 16) * ((100vw - 1024px) / (1920 - 1024))), 56px);
  --g-24: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --g-20: 20px;
  --g-32: clamp(16px, calc(16px + (32 - 16) * ((100vw - 1024px) / (1920 - 1024))), 32px);
  --g-48: clamp(16px, calc(16px + (48 - 16) * ((100vw - 1024px) / (1920 - 1024))), 48px);
  --g-elements: clamp(8px, calc(8px + (24 - 8) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --g-content: clamp(24px, calc(24px + (40 - 24) * ((100vw - 1024px) / (1920 - 1024))), 40px);
  --g-section: clamp(56px, calc(56px + (120 - 56) * ((100vw - 1024px) / (1920 - 1024))), 120px);
  --g-52: clamp(20px, calc(20px + (52 - 20) * ((100vw - 1024px) / (1920 - 1024))), 52px);
  --g-56: clamp(24px, calc(24px + (56 - 24) * ((100vw - 1024px) / (1920 - 1024))), 56px);
  --g-80: clamp(16px, calc(16px + (80 - 16) * ((100vw - 1024px) / (1920 - 1024))), 80px);
  --g-footer: clamp(8px, calc(8px + (130 - 8) * ((100vw - 768px) / (1920 - 768))), 130px);
  --fs-14: clamp(12px, calc(12px + (14 - 12) * ((100vw - 1024px) / (1920 - 1024))), 14px);
  --fs-16: 16px;
  --fs-18: clamp(16px, calc(16px + (18 - 16) * ((100vw - 1024px) / (1920 - 1024))), 18px);
  --fs-20: clamp(16px, calc(16px + (20 - 16) * ((100vw - 1024px) / (1920 - 1024))), 20px);
  --fs-24: clamp(16px, calc(16px + (24 - 16) * ((100vw - 1024px) / (1920 - 1024))), 24px);
  --fs-28: clamp(20px, calc(20px + (28 - 20) * ((100vw - 1024px) / (1920 - 1024))), 28px);
  --fs-32: clamp(20px, calc(20px + (32 - 20) * ((100vw - 1024px) / (1920 - 1024))), 32px);
  --fs-36: clamp(20px, calc(20px + (36 - 20) * ((100vw - 1024px) / (1920 - 1024))), 36px);
  --fs-40: clamp(23px, calc(23px + (40 - 23) * ((100vw - 1024px) / (1920 - 1024))), 40px);
  --fs-52: clamp(28px, calc(28px + (52 - 28) * ((100vw - 1024px) / (1920 - 1024))), 52px);
  --fs-56: clamp(24px, calc(24px + (56 - 24) * ((100vw - 1024px) / (1920 - 1024))), 56px);
  --fs-60: clamp(20px, calc(20px + (60 - 20) * ((100vw - 1024px) / (1920 - 1024))), 60px);
  --fs-72: clamp(32px, calc(32px + (72 - 32) * ((100vw - 1024px) / (1920 - 1024))), 72px);
  --fs-96: clamp(40px, calc(40px + (96 - 40) * ((100vw - 1024px) / (1920 - 1024))), 96px);
  --transition: 0.18s ease-out;
}
@media (max-width: 1024px) {
  html {
    --border-corner: 8px;
  }
}

.ac-background-1 {
  --bg-color: var(--accent);
  --text-color: var(--c-surface-subdued);
  --subtitle-color: var(--accent-2);
}

.ac-background-2 {
  --bg-c-content: var(--c-surface-default);
  --bg-color: var(--c-surface-subdued);
  --subtitle-color: var(--accent);
}

.ac-background-3 {
  --bg-color: #055152;
}
.ac-background-3 .block--sections .slider_controls:after {
  background: linear-gradient(90deg, rgba(255, 254, 254, 0) 0%, #055152 15%);
}
.ac-background-3 .button.button-accent {
  --filled-bgc-base: #FBB10D;
  --filled-bc-base: #FBB10D;
  --filled-c-base: #fff;
  --filled-bgc-hover: #FC9F00;
  --filled-bc-hover: #FC9F00;
  --filled-c-hover: #fff;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/Gilroy/Gilroy-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "InterVariable";
  font-style: normal;
  src: url("../fonts/InterVariable/InterVariable.ttf") format("opentype");
}
html {
  font-size: clamp(16px, 16px + 2 * (100vw - 1024px) / 896, 18px);
  line-height: 1.25;
  font-family: "InterVariable", sans-serif;
  letter-spacing: -0.02em;
  color: var(--c-text-default, #141A25);
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

.f-h1 {
  font-size: clamp(52px, 52px + 96 * (100vw - 1024px) / 896, 148px);
}

.f-h2 {
  font-size: var(--fs-96);
}

.f-h3 {
  font-size: var(--fs-72);
}

.f-h4 {
  font-size: var(--fs-60);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  font-family: "Gilroy";
  line-height: 1;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/fonts/icomoon.eot");
  src: url("../fonts/icomoon/fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon/fonts/icomoon.woff") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye:before {
  content: "\e910";
}

.icon-close:before {
  content: "\e91a";
}

.icon-arrow-l:before {
  content: "\e900";
}

.icon-arrow-r:before {
  content: "\e901";
}

.icon-blockquote:before {
  content: "\e902";
}

.icon-burger:before {
  content: "\e903";
}

.icon-calendar:before {
  content: "\e904";
}

.icon-calendar-star:before {
  content: "\e905";
}

.icon-check:before {
  content: "\e906";
}

.icon-chevron-down:before {
  content: "\e907";
}

.icon-chevron-down2:before {
  content: "\e908";
}

.icon-chevron-L:before {
  content: "\e909";
}

.icon-cup .path1:before {
  content: "\e90a";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}

.icon-cup .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-cursor:before {
  content: "\e90c";
}

.icon-document:before {
  content: "\e90d";
}

.icon-dzen:before {
  content: "\e90e";
  color: #fff;
}

.icon-external:before {
  content: "\e90f";
}

.icon-eye:before {
  content: "\e910";
}

.icon-filter:before {
  content: "\e911";
}

.icon-hourse:before {
  content: "\e912";
}

.icon-location:before {
  content: "\e913";
}

.icon-plus:before {
  content: "\e914";
}

.icon-price:before {
  content: "\e915";
}

.icon-shevron-R:before {
  content: "\e916";
}

.icon-star:before {
  content: "\e917";
}

.icon-star2:before {
  content: "\e918";
}

.icon-trophy:before {
  content: "\e919";
}

.icon-ic-socials-facebook:before {
  content: "\e93e";
}

.icon-ic-socials-instagram:before {
  content: "\e93f";
}

.icon-ic-socials-odnoklassniki:before {
  content: "\e940";
}

.icon-ic-socials-telegram:before {
  content: "\e941";
}

.icon-ic-socials-viber:before {
  content: "\e942";
}

.icon-ic-socials-vk:before {
  content: "\e943";
}

.icon-ic-socials-whatsapp:before {
  content: "\e944";
}

.icon-ic-socials-youtube:before {
  content: "\e945";
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

a[href], button, .button {
  cursor: pointer;
  text-decoration-skip-ink: none;
  transition: 0.18s ease-out;
}

.disabled, [disabled] {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.hidden {
  display: none !important;
}

.ux_col {
  padding: 180px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ui_grid_head {
  margin: 24px auto;
}

.ui_grid_buttons {
  max-width: 1440px;
  margin: 24px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  gap: 24px;
  padding: 24px;
}
.ui_grid_buttons_title {
  grid-column: span 3;
  margin: 48px 24px 24px;
}
.ui_grid_buttons .theme-dark {
  padding: 24px;
  background-color: black;
}

img[src=""] {
  opacity: 0;
}

* {
  scroll-margin: calc(var(--headerHeight) + 2rem);
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 1920px;
  margin: 0 auto;
}

/* Common styles for button */
.button {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 4px;
  flex-shrink: 0;
  font-size: var(--fs-18);
  line-height: 1.55;
  font-weight: 400;
  letter-spacing: -0.02em;
  border: 1px solid transparent;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  transition: var(--transition);
}
.button span {
  color: inherit;
  line-height: inherit;
  text-align: center;
  z-index: 2;
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: -2%;
}
.button span:not([class*=" icon-"]) {
  transition: 0.1s linear;
}
.button span[class^=icon-],
.button span[class*=" icon-"],
.button svg[class^=icon] {
  color: inherit;
  width: 1.33em;
  height: 1.33em;
  font-size: 1.2rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Button appearance */
.button.button-filled {
  background: var(--filled-bgc-base);
  border-color: var(--filled-bc-base);
  color: var(--filled-c-base);
  border-radius: var(--border-corner-button);
  justify-content: center;
  padding: 13px 28px;
}
.button.button-filled:hover, .button.button-filled:focus, .button.button-filled:active {
  border-color: var(--filled-bc-hover);
  color: var(--filled-c-hover);
}
.button.button-filled:hover:before, .button.button-filled:focus:before, .button.button-filled:active:before {
  background: var(--filled-bgc-hover);
}

.button.button-outlined {
  background-color: transparent;
  border-color: var(--outlined-bc-base);
  color: var(--outlined-c-base);
  border-radius: var(--border-corner-button);
  align-items: center;
  justify-content: center;
  padding: 13px 28px;
}

.button.button-outlined:is(:hover, :focus, :active),
.card:is(:hover, :focus, :active) .button.button-outlined,
.footer--lk:is(:hover, :focus, :active) .button.button-outlined,
.events--item .item--text-link:is(:hover, :focus, :active) .button.button-outlined,
.events--item .item--image-link:is(:hover, :focus, :active) .button.button-outlined {
  border-color: var(--outlined-bc-hover);
  color: var(--outlined-c-hover);
  background-color: var(--outlined-bgc-hover);
  transition: 0.35s linear background-color;
}
.button.button-outlined:is(:hover, :focus, :active):before,
.card:is(:hover, :focus, :active) .button.button-outlined:before,
.footer--lk:is(:hover, :focus, :active) .button.button-outlined:before,
.events--item .item--text-link:is(:hover, :focus, :active) .button.button-outlined:before,
.events--item .item--image-link:is(:hover, :focus, :active) .button.button-outlined:before {
  background-color: var(--outlined-bgc-hover);
}

.button.button-link {
  justify-content: flex-start;
  background: transparent;
  border-color: transparent;
  color: var(--link-c-base);
}
.button.button-link:visited {
  opacity: 0.8;
}
.button.button-link:hover, .button.button-link:focus, .button.button-link:active {
  color: var(--link-c-hover);
}
.button.button-link span:not([class]),
.button.button-link span:not([class]) {
  padding: 0;
}

/* Button  colors  */
.button.button-accent,
.card {
  --filled-bgc-base: #FC563B;
  --filled-bc-base: #FC563B;
  --filled-c-base: #fff;
  --filled-bgc-hover: #F63719;
  --filled-bc-hover: #F63719;
  --filled-c-hover: #fff;
  --outlined-bc-base: #FC563B;
  --outlined-c-base: #000;
  --outlined-bgc-hover: #FC563B;
  --outlined-c-hover: #fff;
  --link-c-base: #093282;
  --link-c-hover: rgba(9, 50, 130, 0.6);
}
.button.button-accent.button-dark,
.card.button-dark {
  --outlined-bc-base: #FC563B;
  --outlined-c-base: #fff;
  --outlined-bgc-hover: #FC563B;
  --outlined-bc-hover: transparent;
  --outlined-c-hover: #fff;
  --link-c-base: rgba(255, 255, 255, 1);
  --link-c-hover: rgba(255, 255, 255, 0.8);
}

.button.button-primary,
.card {
  --filled-bgc-base: #093282;
  --filled-bc-base: #093282;
  --filled-c-base: #FFF;
  --filled-bgc-hover: #144FC3;
  --filled-bc-hover: #144FC3;
  --filled-c-hover: #fff;
  --outlined-bc-base: #111820;
  --outlined-c-base: #111820;
  --outlined-bgc-hover: #093282;
  --outlined-c-hover: #fff;
  --link-c-base: #093282;
  --link-c-hover: #111820;
}
.button.button-primary.button-dark,
.card.button-dark {
  --outlined-bc-base: #fff;
  --outlined-c-base: #fff;
  --outlined-bgc-hover: #093282;
  --outlined-bc-hover: #093282;
  --outlined-c-hover: #fff;
}

.button.button-secondary,
.card {
  --filled-bgc-base: #F2F5FA;
  --filled-bc-base: #F2F5FA;
  --filled-c-base: #111820;
  --filled-bgc-hover: #E0E7F3;
  --filled-bc-hover: #E0E7F3;
  --filled-c-hover: #111820;
  --outlined-bc-base: rgba(17, 24, 32, 0.4);
  --outlined-c-base: #111820;
  --outlined-bc-hover: #E0E7F3;
  --outlined-bgc-hover: #E0E7F3;
  --outlined-c-hover: #111820;
  --link-c-base: #111820;
  --link-c-hover: #093282;
}
.button.button-secondary.button-dark,
.card.button-dark {
  --outlined-bc-base: #E0E7F3;
  --outlined-c-base: #E0E7F3;
  --outlined-bc-hover: transparent;
  --outlined-bgc-hover: #E0E7F3;
  --outlined-c-hover: #111820;
  --link-c-base: #E0E7F3;
  --link-c-hover: #093282;
}

.button.button-filled:before,
.button.button-outlined:before {
  content: "";
  position: absolute;
  height: auto;
  aspect-ratio: 1/1;
  pointer-events: none;
  z-index: -1;
  border-radius: 100%;
  left: 50%;
  top: 100%;
  transition: 0.3s linear;
  width: 200%;
  scale: 0;
  translate: -50% -50%;
  will-change: transform;
}

.button.button-filled:is(:hover, :focus, :active):before,
.button.button-outlined:is(:hover, :focus, :active):before,
.card:is(:hover, :focus, :active) .button.button-outlined:before,
.card:is(:hover, :focus, :active) .button.button-filled:before,
.footer--lk:is(:hover, :focus, :active) .button.button-outlined:before,
.events--item .item--text-link:is(:hover, :focus, :active) .button.button-outlined:before,
.events--item .item--image-link:is(:hover, :focus, :active) .button.button-outlined:before {
  scale: 120%;
}

.swiper-slide,
.card,
.gallery--item,
.card-detail,
.banner-group .banner {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.swiper-wrapper {
  -webkit-transform-style: preserve-3d;
}

.swiper--next,
.swiper--prev,
.swiper-button-next,
.swiper-button-prev {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  width: clamp(36px, 36px + 18 * (100vw - 1024px) / 896, 54px);
  height: clamp(36px, 36px + 18 * (100vw - 1024px) / 896, 54px);
  pointer-events: all;
  transition: var(--transition);
  border-radius: var(--border-corner-button);
}
.swiper--next:after,
.swiper--prev:after,
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.3rem;
  font-family: "icomoon" !important;
}
.swiper--next:before,
.swiper--prev:before,
.swiper-button-next:before,
.swiper-button-prev:before {
  transition: 0.15s linear !important;
}
.swiper--next.swiper-button-disabled,
.swiper--prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.4;
  cursor: auto;
  box-shadow: unset !important;
}

.swiper-button-disabled {
  pointer-events: none;
}

.swiper-button-prev:after,
.swiper--prev:after {
  z-index: 3;
  content: "\e909";
}

.swiper-button-next:after,
.swiper--next:after {
  z-index: 3;
  content: "\e916";
}

.swiper-pagination {
  width: fit-content;
}

.swiper-pagination-bullets {
  --swiper-pagination-bottom: 12px;
}

.block_list-slider .slider_controls,
.content_events .slider_controls,
.block_banner:not(.block_banner-hero) .slider_controls,
.gallery .slider_controls {
  margin-top: var(--g-20);
  z-index: 2;
}
.block_list-slider .slider_controls .swiper-pagination-container,
.content_events .slider_controls .swiper-pagination-container,
.block_banner:not(.block_banner-hero) .slider_controls .swiper-pagination-container,
.gallery .slider_controls .swiper-pagination-container {
  width: 100%;
  margin-right: 20px;
  position: relative;
}
.block_list-slider .slider_controls .swiper-pagination-progressbar,
.content_events .slider_controls .swiper-pagination-progressbar,
.block_banner:not(.block_banner-hero) .slider_controls .swiper-pagination-progressbar,
.gallery .slider_controls .swiper-pagination-progressbar {
  translate: 0 -50%;
  top: 50%;
  border: 1px solid var(--c-border-secondary);
  background-color: transparent;
  height: 8px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.block_list-slider .slider_controls .swiper-pagination-progressbar .swiper-pagination-progressbar-fill,
.content_events .slider_controls .swiper-pagination-progressbar .swiper-pagination-progressbar-fill,
.block_banner:not(.block_banner-hero) .slider_controls .swiper-pagination-progressbar .swiper-pagination-progressbar-fill,
.gallery .slider_controls .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: var(--c-surface-dark);
  border-radius: 20px;
}
.block_list-slider .slider_controls .button,
.content_events .slider_controls .button,
.block_banner:not(.block_banner-hero) .slider_controls .button,
.gallery .slider_controls .button {
  padding: 15px;
}

.slider_controls {
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  gap: 10px;
}

.swiper-pagination {
  display: flex;
  gap: 8px;
}

.swiper-button-prev,
.swiper-button-next {
  border: 1px solid var(--c-border-default, #ECEDEE);
}
.swiper-button-prev:hover, .swiper-button-prev:focus,
.swiper-button-next:hover,
.swiper-button-next:focus {
  border-color: rgb(20, 26, 37);
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.5;
}

.swiper-button-lock,
.swiper-pagination-lock {
  display: none;
}

.block_list-slider-v2 .slider_controls,
.block_image_text-slider .slider_controls {
  margin-top: auto;
  width: 100%;
  align-items: center;
}
.block_list-slider-v2 .slider_controls .swiper-button-prev,
.block_list-slider-v2 .slider_controls .swiper-button-next,
.block_image_text-slider .slider_controls .swiper-button-prev,
.block_image_text-slider .slider_controls .swiper-button-next {
  border: 1px solid currentColor;
}
.block_list-slider-v2 .slider_controls .swiper-pagination,
.block_image_text-slider .slider_controls .swiper-pagination {
  margin-left: auto;
}

.swiper-pagination-bullet {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid var(--c-surface-default);
  border-radius: 50px;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--c-surface-default);
}

.swiper-notification {
  display: none;
}

.block--image-slider {
  position: relative;
}
.block--image-slider:before {
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0.4;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1965161064) 40%, rgba(220, 220, 220, 0) 100%);
}
.block--image-slider .slider_controls {
  width: auto;
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 2;
  bottom: var(--g-32);
  left: var(--g-32);
  right: var(--g-32);
  top: auto;
  position: absolute;
}
@media (max-width: 768px) {
  .block--image-slider .slider_controls {
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .block--image-slider .slider_controls .swiper-button-next,
  .block--image-slider .slider_controls .swiper-button-prev {
    display: none;
  }
}
@media (min-width: 769px) {
  .block--image-slider .slider_controls .swiper-pagination {
    margin-left: auto;
  }
}

.note {
  position: relative;
  background-color: #F2F5FA;
  padding: var(--g-20) var(--g-80);
  transition: var(--transition);
}
.note-close {
  position: absolute;
  right: var(--g-80);
}
.note-content {
  max-width: 1240px;
  padding-right: 32px;
}
.note-title {
  display: flex;
  gap: 6px;
  margin-bottom: 8px;
  font-size: var(--fs-20);
}
@media (max-width: 768px) {
  .note-title {
    flex-direction: column;
  }
}
.note-title span:first-of-type {
  opacity: 0.5;
}
.note-text {
  opacity: 0.7;
}

.header {
  position: sticky;
  top: -1px;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 50;
  transform: translateY(0);
  transition: 0.18s linear;
  --hover--bck-color: rgba(255, 255 ,255 , 0.1);
  will-change: tranform;
}
@media (max-width: 1024px) {
  .header {
    transition: var(--transition);
  }
}
.header--main {
  color: var(--c-text-primary);
  background: var(--accent);
}
.header .header--logo svg {
  fill: #fff;
}
.header.is_scrolled .header--main {
  color: var(--c-text-default);
  background-color: #FFFFFF;
}
.header.is_scrolled .header--logo svg {
  fill: var(--accent);
}
.header.is_menu_opened {
  position: fixed;
}
.header.is_menu_opened .header--main {
  color: var(--c-text-primary);
  background: var(--accent);
}
.header.is_menu_opened .header--logo svg {
  fill: #fff;
}

.header--main {
  padding: 18px var(--g-container);
  display: flex;
}
@media (max-width: 1024px) {
  .header--main {
    display: flex;
    justify-content: space-between;
  }
}

.header--nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header--nav .nav {
  width: auto;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header--nav .nav--item {
  padding: 10px 16px;
  white-space: nowrap;
  border-radius: var(--border-corner-button);
}
@media (min-width: 1440px) {
  .header--nav .nav--item {
    padding: 12px 20px;
  }
}
.header--nav .nav--item:hover {
  background-color: var(--hover--bck-color);
}
.header--nav .nav--item .is_dropdown--content {
  display: flex;
  gap: 16px var(--g-32);
}
.header--nav .nav--item .is_dropdown--content a span {
  padding: 0;
}
.header--nav .nav--item .is_dropdown--content-col {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 1024px) {
  .header--nav .nav--item .is_dropdown--content-col-mob {
    display: none;
  }
}
.header--nav .nav--item .is_dropdown--content-title {
  font-size: var(--fs-24);
  text-transform: uppercase;
  margin-bottom: 16px;
  max-width: 200px;
  white-space: normal;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
@media (max-width: 1024px) {
  .header--nav .nav--item:not(.menu_button) {
    display: none;
  }
}
@media (max-width: 1024px) {
  .header--nav .nav--item.menu_button .button_text {
    display: none;
  }
}

.header--actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: clamp(8px, 8px + 8 * (100vw - 1024px) / 896, 16px);
  padding-left: 24px;
}
@media (max-width: 768px) {
  .header--actions {
    padding-left: 16px;
  }
}
.header--actions .header--contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.header--actions .header--contacts-adress {
  font-size: 12px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 2px;
}
@media (max-width: 768px) {
  .header--actions .header--contacts-adress {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  .header--actions .header--contacts-adress span {
    padding: 0;
  }
}
@media (max-width: 998px) {
  .header--actions .header--contacts .icon-chevron-down {
    display: none;
  }
}
.header--actions .header--contacts .is_dropdown--content {
  padding: var(--g-32);
}
.header--actions .header--contacts .is_dropdown--wrapper {
  right: 0;
  left: auto;
}

.header--actions_mobile .button {
  background-color: var(--accent-2);
  border: var(--border-corner-button);
  color: var(--c-text-primary);
  padding: 8px;
  border-radius: var(--border-corner-button);
}
.header--actions_mobile span {
  font-size: 18px;
}
@media (min-width: 1025px) {
  .header--actions_mobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header--lk {
    display: none;
  }
}

.header--logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header--logo svg {
  height: 60px;
}
@media (min-width: 1025px) {
  .header--logo svg {
    padding-right: 24px;
    min-width: 180px;
  }
}
@media (max-width: 1024px) {
  .header--logo svg {
    justify-content: center;
    max-width: 120px;
    height: 50px;
  }
}

.button.menu_button .icon-close {
  display: none;
}
.is_menu_opened .button.menu_button .icon-close {
  display: inline-flex;
}
.is_menu_opened .button.menu_button .icon-burger {
  display: none;
}

.is_menu_opened ~ .header_mobile .icon-close {
  display: inline-flex;
}
.is_menu_opened ~ .header_mobile .icon-burger {
  display: none;
}

.breadcrumbs {
  --main-color: #8C9196;
  --non-active-color: var(--c-text-dark);
  --hover-color: #6D7175;
  padding: var(--g-24) var(--g-container);
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin: 0 auto;
  z-index: 45;
  list-style: none;
}
@media (max-width: 768px) {
  .breadcrumbs {
    flex-wrap: nowrap;
    width: 100vw;
    overflow-x: auto;
    padding: var(--g-24) var(--g-container);
    margin: 0;
    justify-content: flex-start;
  }
}
.breadcrumbs a {
  transition: var(--transition);
  position: relative;
  color: var(--main-color);
  white-space: nowrap;
  font-size: 13px;
}
@media (min-width: 769px) {
  .breadcrumbs a {
    font-size: 16px;
  }
}
.breadcrumbs a:after {
  content: "/";
  margin-left: 12px;
}
.breadcrumbs a:hover, .breadcrumbs a:focus, .breadcrumbs a:active {
  color: var(--hover-color);
}
.breadcrumbs li:last-child a {
  pointer-events: none;
  color: var(--non-active-color);
}
.breadcrumbs li:last-child a:after {
  display: none;
}

.header-transparent ~ .breadcrumbs {
  position: absolute;
  top: var(--headerHeight);
  left: calc((100vw - var(--container)) / 2);
  --main-color: rgba(250, 251, 251, 0.6);
  --non-active-color: rgba(250, 251, 251, 1);
  --hover-color: rgba(250, 251, 251, 1);
}
@media (max-width: 768px) {
  .header-transparent ~ .breadcrumbs {
    left: 0;
  }
}

.nav {
  display: flex;
  align-items: center;
  gap: 0;
}

.footer--col .nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--g-content) 24px;
  align-items: flex-start;
  width: 100%;
}
@media (min-width: 768px) {
  .footer--col .nav {
    grid-template-columns: repeat(4, 1fr);
  }
}
.footer--col .nav--item:hover {
  color: var(--c-text-disabled);
}

.nav--list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nav--list_title {
  color: var(--c-surface-default, #fff);
  font-size: var(--fs-28);
  font-family: "Gilroy";
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.nav--item {
  font-size: var(--fs-24);
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  transition: var(--transition);
}

.accordion {
  cursor: pointer;
  position: relative;
  padding: var(--g-24);
  border-radius: var(--border-corner);
  max-width: 720px;
  height: max-content;
  background-color: var(--c-bck-default);
}
.accordion.is_open .accordion--content {
  height: auto;
  max-height: 100%;
  margin: var(--g-24) 0 0;
  /* padding: 16px 0; * */
  -webkit-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  pointer-events: auto;
  opacity: 0.8;
}
.accordion.is_open .icon-plus {
  transform: rotate(45deg);
}
.accordion.is_open .icon-plus:before {
  content: "\e914";
}
.accordion--button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 18px;
  align-items: center;
  position: relative;
}
.accordion--button .accordion--button_text {
  margin: 0;
  font-size: var(--fs-28);
  font-weight: 400;
}
.accordion--button .icon-plus {
  font-size: 1.5rem;
  transition: var(--transition);
  transform: rotate(0);
}
.accordion--content {
  overflow: hidden;
  height: 0;
  max-height: 0;
  transition: all 0.5s;
  pointer-events: none;
  opacity: 0;
}
.accordion--content.is_cascade > p:first-child {
  margin: 0;
}
.accordion + .accordion {
  margin-top: 16px;
}

.popup {
  padding: var(--g-40, 40px) 0;
  overscroll-behavior: contain;
  background-color: var(--others-fog, rgba(20, 26, 37, 0.45));
}
@media (max-width: 768px) {
  .popup {
    padding: 25px 8px;
    place-items: start;
    height: -webkit-fill-available;
    height: calc(100 * var(--vh, 1vh));
    max-height: calc(100 * var(--vh, 1vh));
  }
}
.popup--content {
  background-color: var(--c-surface-default);
  padding: var(--g-container);
  width: 100%;
  border-radius: var(--border-corner);
}
@media (max-width: 768px) {
  .popup--content {
    padding: 28px 16px;
  }
}
.popup--content-title {
  margin-bottom: var(--g-24);
}
.popup--content-buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.popup--content-buttons .button {
  width: 100%;
}
.popup--close {
  background-color: #F2F5FA;
  border-radius: var(--border-corner-button);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: -56px;
  z-index: 42;
  cursor: pointer;
  transition: 0.3s ease-out;
  color: var(--c-text-subdued);
}
@media (max-width: 1024px) {
  .popup--close {
    top: 0;
    right: 16px;
  }
}
.popup--close .icon-ic24-close {
  font-size: 24px;
}
@media (max-width: 768px) {
  .popup--close {
    left: unset;
    right: 16px;
    top: 16px;
  }
}
.popup--close .button {
  padding: 7px;
  border: none;
}
.popup fieldset {
  border: none;
  padding: 0;
}
.popup .select--button {
  display: none;
}
.popup .select--option_wrapper {
  position: static;
  opacity: 1;
  padding: 0;
  border: none;
}
.popup .select--option_list {
  transform: translateY(0);
  border: none;
  padding: 0;
}
.popup .form--input_select_title {
  display: block;
}
.popup .filter--buttons {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.popup .filter--buttons button {
  width: 100%;
}

.popup[data-popup=popup_for_filters] .popup--content > button {
  width: 100%;
  margin-top: 16px;
}

.popup.popup-menu {
  place-items: start;
  padding: 0;
  background-color: var(--accent);
  color: var(--c-text-primary);
  top: 0;
  height: calc(100 * var(--vh, 1vh));
  z-index: 49;
}
@media (max-width: 992px) {
  .popup.popup-menu {
    padding-top: var(--headerHeight);
  }
}
.popup.popup-menu .popup--content {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  transition: 0.24s ease-out;
  transition-delay: 0.05s;
  display: grid;
  gap: 24px;
  background-color: transparent;
}
@media (min-width: 1024px) {
  .popup.popup-menu .popup--content {
    grid-template-columns: repeat(12, 1fr);
  }
}
.popup.popup-menu .nav {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding-bottom: 16px;
}
.popup.popup-menu .nav--item {
  align-items: flex-start;
  flex-direction: column;
  font-size: 40px;
}
@media (max-width: 575px) {
  .popup.popup-menu .nav--item {
    width: 100%;
  }
}
.popup.popup-menu .nav--item > .button {
  font-size: 40px;
}
.popup.popup-menu .nav--item > .button span {
  padding: 0;
  white-space: initial;
  line-height: 1.1;
  text-align: left;
}
.popup.popup-menu .nav--item > .button .icon-calendar {
  justify-content: flex-start;
  font-size: inherit;
}
.popup.popup-menu .is_dropdown.is_open .is_dropdown--wrapper {
  position: static;
  padding: 0;
}
.popup.popup-menu .is_dropdown-events .events--item .item--image-link {
  aspect-ratio: 340/120;
}
.popup.popup-menu .is_dropdown-events .events--item .item--text-link .button {
  --outlined-bc-base: #e0e7f3;
  --outlined-c-base: #e0e7f3;
  --outlined-bc-hover: #e0e7f3;
  --outlined-bgc-hover: #e0e7f3;
  --outlined-c-hover: #111820;
}
.popup.popup-menu .is_dropdown-events .events--item .item--text-link,
.popup.popup-menu .is_dropdown-events .events--item .item--image-link {
  background-color: #144FC3;
}
.popup.popup-menu .is_dropdown-events .events--item .item--text-link .title [class^=icon-],
.popup.popup-menu .is_dropdown-events .events--item .item--text-link .title [class*=" icon-"],
.popup.popup-menu .is_dropdown-events .events--item .item--image-link .title [class^=icon-],
.popup.popup-menu .is_dropdown-events .events--item .item--image-link .title [class*=" icon-"] {
  color: currentColor;
}
.popup.popup-menu .is_dropdown--content {
  display: flex;
  padding: 0;
  flex-direction: column;
  border: none;
  background-color: var(--accent);
  gap: 16px;
}
.popup.popup-menu .is_dropdown--content .is_dropdown--content-col {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.popup.popup-menu .is_dropdown--content .is_dropdown--content-title,
.popup.popup-menu .is_dropdown--content .is_dropdown--content-title .button {
  color: var(--c-text-primary);
  font-size: 24px;
  text-transform: uppercase;
}
.popup.popup-menu .is_dropdown--content .button,
.popup.popup-menu .is_dropdown--content .events--title,
.popup.popup-menu .is_dropdown--content .item--text-link .title {
  color: var(--c-text-primary);
}
.popup.popup-menu .is_dropdown--content .button span {
  pointer-events: none;
}

.popup.popup-cascade .popup--content {
  max-width: 880px;
  width: 100%;
  padding-top: var(--g-20);
  max-height: 100%;
  overscroll-behavior: contain;
}

.popup-notification.is_active {
  pointer-events: none;
}
.popup-notification.is_active .popup--content {
  pointer-events: all;
}

.popup-notification {
  background-color: transparent;
  gap: var(--g-elements);
  padding: var(--g-32);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.popup-notification .popup--content {
  max-width: 540px;
  width: 100%;
  min-width: 275px;
  background-color: var(--c-surface-default);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05), 0px 4px 24px 0px rgba(23, 29, 40, 0.1);
  padding: var(--fs-24) 48px var(--fs-24) var(--fs-24);
  border-radius: var(--border-corner);
}
@media (max-width: 768px) {
  .popup-notification .popup--content {
    max-width: 100vw;
    min-width: 225px;
  }
}
.popup-notification .popup--content a {
  text-decoration: underline;
  color: var(--accent);
}
.popup-notification .popup--content .button {
  margin-top: 16px;
  font-size: var(--fs-24);
  padding: 0;
  line-height: 1;
}

.popup-form {
  padding: var(--g-56) 8px;
}
@media (max-width: 768px) {
  .popup-form {
    padding: var(--g-8);
  }
}
.popup-form .popup--content {
  transition: transform 0.18s 0.03s ease-in-out;
  max-width: 860px;
  height: 100%;
  padding: var(--g-section-inner) var(--g-48);
}
@media (max-width: 768px) {
  .popup-form .popup--content {
    max-width: 100vw;
  }
}

.popup-tour_map {
  position: absolute;
  width: auto;
  height: auto;
  padding: var(--g-content) calc((100vw - var(--container)) / 2 + 32px);
  background: transparent;
  place-items: end;
  pointer-events: none;
  padding: var(--g-56);
}
.popup-tour_map.is_active {
  pointer-events: none;
}
.popup-tour_map.is_active .popup--content {
  pointer-events: auto;
}
.popup-tour_map .popup--close {
  position: absolute;
  left: auto;
  right: 16px;
  top: 16px;
}
.popup-tour_map .popup--content {
  pointer-events: none;
  width: 100%;
  max-width: 760px;
  padding: var(--g-32) var(--g-24);
  border: 1px solid var(--border-color, #f5f4f4);
  border-radius: var(--border-corner);
  background: var(--surface-default, #fff);
  background-color: var(--c-surface-default);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
}
.popup-tour_map .popup--content-title {
  font-size: var(--fs-32);
  font-weight: 500;
  font-family: "Gilroy";
  margin-bottom: 12px;
}

.popup-infrastructure {
  display: flex;
}
@media (min-width: 769px) {
  .popup-infrastructure {
    position: static;
    width: auto;
    height: 100%;
    z-index: 1;
    border-radius: var(--border-corner);
    overflow: hidden;
  }
}
@media (min-width: 769px) {
  .popup-infrastructure {
    padding: 0;
  }
}
.popup-infrastructure .popup--number {
  display: none;
}
@media (min-width: 769px) {
  .popup-infrastructure .popup--number {
    background-color: #FFFFFF;
    border-radius: 50%;
    width: 52px;
    position: absolute;
    left: 16px;
    top: 16px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .popup-infrastructure .popup--number:after {
    content: attr(data-number);
    font-weight: 500;
    font-size: var(--fs-24);
    text-align: center;
    display: inline-block;
  }
}
.popup-infrastructure .popup--content {
  border-radius: var(--border-corner);
  overflow: hidden;
  height: 100%;
  color: var(--c-text-default);
  padding: 0;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .popup-infrastructure .popup--content {
    height: auto;
  }
}
@media (min-width: 769px) {
  .popup-infrastructure .popup--close {
    display: none;
  }
}
.popup-infrastructure .popup--content-title,
.popup-infrastructure .popup--content-text,
.popup-infrastructure .button_wrapper {
  padding: 0 var(--g-container) 0;
}
.popup-infrastructure .popup--content-image {
  aspect-ratio: 560/360;
  border-radius: var(--border-corner);
  overflow: hidden;
  margin-bottom: var(--g-24);
}
.popup-infrastructure .popup--content-title {
  font-size: var(--fs-32);
  font-weight: 500;
  font-family: "Gilroy";
  line-height: 1;
}
.popup-infrastructure .button_wrapper {
  padding-bottom: var(--g-container);
  margin-top: 16px;
}
.popup-infrastructure .button_wrapper .button {
  margin-top: 0;
  padding: 9px 20px;
}

.popup.is_active .select--option_wrapper {
  pointer-events: auto;
}

.is_cascade {
  overflow: hidden;
  line-height: 1.5;
  font-size: var(--fs-24);
}
.is_cascade h1 {
  line-height: 1;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: -0.02em;
  font-size: clamp(40px, 40px + 108 * (100vw - 1024px) / 896, 148px);
  margin: var(--g-section-inner) 0 0;
}
.is_cascade h2 {
  font-size: var(--fs-96);
  margin: var(--g-section-inner) 0 0;
}
.is_cascade h3 {
  font-size: var(--fs-72);
  margin: var(--g-section-inner) 0 0;
}
.is_cascade h4 {
  font-size: var(--fs-52);
  margin: var(--g-section-inner) 0 0;
}
.is_cascade h5, .is_cascade h6 {
  font-size: var(--fs-40);
  margin: var(--g-section-inner) 0 0;
}
.is_cascade > p {
  margin-top: var(--g-24);
}
.is_cascade p > a:not(.button) {
  color: #144FC3;
  text-decoration: underline;
  margin: 0 0.5em;
}
.is_cascade p > a:not(.button):hover, .is_cascade p > a:not(.button):active {
  color: var(--accent);
}
.is_cascade p > a:not(.button):visited {
  opacity: 0.8;
}
.is_cascade strong, .is_cascade b {
  font-weight: 700;
}
.is_cascade ul, .is_cascade ol {
  margin: var(--g-24) 0;
  padding-left: 26px;
}
.is_cascade ul li:not(:last-of-type), .is_cascade ol li:not(:last-of-type) {
  margin-bottom: 8px;
}
.is_cascade figure {
  margin: var(--g-content) 0;
  border-radius: var(--border-corner);
  overflow: hidden;
}
.is_cascade figure img, .is_cascade figure iframe, .is_cascade figure video {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: var(--border-corner);
  overflow: hidden;
}
.is_cascade figure img {
  aspect-ratio: initial;
  object-fit: contain;
  max-height: 720px;
  height: auto;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.is_cascade figure figcaption {
  color: var(--c-text-disabled);
  font-size: 16px;
  margin-top: 16px;
}
@media (max-width: 768px) {
  .is_cascade figure figcaption {
    padding: 0 var(--g-container);
  }
}
.is_cascade figure figcaption:empty {
  margin: 0;
}
.is_cascade > img, .is_cascade > video, .is_cascade > iframe, .is_cascade > object {
  margin: var(--g-32) 0;
}
.is_cascade video, .is_cascade img, .is_cascade iframe, .is_cascade object {
  aspect-ratio: 870/472;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 0;
  overflow: hidden;
  margin: var(--g-content) 0;
}
.is_cascade video figure, .is_cascade img figure, .is_cascade iframe figure, .is_cascade object figure {
  margin: 0;
}
.is_cascade blockquote {
  padding: var(--g-32);
  background-color: var(--c-surface-subdued);
  border-radius: var(--border-corner);
  position: relative;
  margin: var(--g-content) 0;
}
.is_cascade blockquote .blockquote--text {
  position: relative;
  padding-left: var(--g-32);
  font-size: var(--fs-36);
  line-height: 1.5;
  font-weight: 500;
}
.is_cascade blockquote .blockquote--text:before {
  content: "“";
  position: absolute;
  left: -6px;
  top: 0;
}
.is_cascade blockquote .blockquote--author {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-left: var(--g-32);
}
.is_cascade blockquote .blockquote--author--image {
  border-radius: var(--border-corner-button);
  overflow: hidden;
}
.is_cascade blockquote .blockquote--author--image img {
  border-radius: var(--border-corner-button);
  max-width: 56px;
  aspect-ratio: 1/1;
}
.is_cascade blockquote .blockquote--author--text p {
  font-size: var(--fs-24);
}
.is_cascade blockquote .blockquote--author--text span {
  font-size: 16px;
  color: var(--c-text-subdued);
  margin-top: 8px;
}
.is_cascade .button_wrapper, .is_cascade .block--actions {
  display: flex;
  gap: 8px;
  margin: var(--g-32) 0;
}
.is_cascade .is_cascade--accordion {
  margin: var(--g-content) 0;
}
.is_cascade .is_cascade--accordion .accordion {
  max-width: 100%;
}
.is_cascade p + .button, .is_cascade ul + .button {
  margin-top: var(--g-32);
}
.is_cascade .is_cascade--table {
  margin: var(--g-content) 0;
}
@media (max-width: 768px) {
  .is_cascade .is_cascade--table {
    max-width: 100vw;
    overflow-x: auto;
    margin: var(--g-content) calc(var(--g-container) * -1);
    padding: 0 var(--g-container);
    scroll-margin-block-end: var(--g-container);
  }
}
.is_cascade table {
  border-collapse: collapse;
  width: 100%;
  border-radius: var(--border-corner);
  border-collapse: separate;
  border-spacing: 0;
}
.is_cascade table thead, .is_cascade table th {
  color: #404B57;
  font-weight: 600;
  background-color: #E0E7F3;
  text-align: left;
}
.is_cascade table td, .is_cascade table th {
  border: 1px solid rgba(17, 24, 32, 0.1607843137);
  padding: 8px 16px;
  min-width: 100px;
}
.is_cascade table td > a:not(.button), .is_cascade table th > a:not(.button) {
  color: #144FC3;
}
.is_cascade table td > a:not(.button):hover, .is_cascade table td > a:not(.button):active, .is_cascade table th > a:not(.button):hover, .is_cascade table th > a:not(.button):active {
  color: var(--accent);
}
.is_cascade table td > a:not(.button):visited, .is_cascade table th > a:not(.button):visited {
  opacity: 0.8;
}
.is_cascade table tbody td:first-of-type {
  background-color: #F2F5FA;
}
.is_cascade thead tr:first-of-type td:first-of-type {
  border-top-left-radius: var(--border-corner);
}
.is_cascade thead tr:first-of-type td:last-of-type {
  border-top-right-radius: var(--border-corner);
}
.is_cascade tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: var(--border-corner);
}
.is_cascade tbody tr:last-of-type td:last-of-type {
  border-bottom-right-radius: var(--border-corner);
}

input, textarea, select {
  border: none;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form {
  max-width: 868px;
}
.form fieldset {
  border-color: transparent;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
}
.form--title {
  font-size: var(--fs-96);
  font-weight: 300;
  text-transform: uppercase;
  font-family: "Gilroy";
  margin-bottom: 12px;
  letter-spacing: -0.02em;
  line-height: 1;
}
.form--text {
  color: var(--c-text-subdued);
  font-size: var(--fs-32);
  margin-bottom: 16px;
}
.form--title .form--input:first-of-type, .form--text .form--input:first-of-type {
  margin-top: var(--g-content);
}
.form--input:first-of-type {
  margin-top: 0;
}
.form--input {
  display: flex;
  flex-direction: column;
  margin-top: var(--g-content);
}
.form--input > span {
  order: -1;
  font-size: 20px;
  color: var(--c-text-default);
  font-weight: 300;
  text-transform: uppercase;
  font-family: "Gilroy";
  letter-spacing: -0.02em;
  transition: var(--transition);
  transform-origin: left top;
  margin-bottom: 8px;
}
.form--input > span + label {
  margin-top: 16px;
}
.form--input > input, .form--input textarea, .form--input select {
  outline: none;
  padding: 0 0 8px;
  line-height: 30px;
  font-size: var(--fs-20);
  font-weight: 300;
  border-bottom: 1px solid #E8E8E8;
  background-color: transparent;
  transition: var(--transition);
  transform-origin: left top;
}
.form--input > input:is(:focus, :active), .form--input textarea:is(:focus, :active), .form--input select:is(:focus, :active) {
  border-bottom: 1px solid rgba(20, 79, 195, 0.4);
}
.form--input > input::placeholder, .form--input > input:placeholder-shown, .form--input textarea::placeholder, .form--input textarea:placeholder-shown, .form--input select::placeholder, .form--input select:placeholder-shown {
  color: var(--c-text-disabled);
  font-size: 16px;
}
.form--input > input:invalid:not(:placeholder-shown), .form--input textarea:invalid:not(:placeholder-shown), .form--input select:invalid:not(:placeholder-shown) {
  border-color: var(--c-critical);
}
.form--input > input:valid:not(:placeholder-shown), .form--input textarea:valid:not(:placeholder-shown), .form--input select:valid:not(:placeholder-shown) {
  border-color: rgba(20, 79, 195, 0.4);
}
.form--input.error .form--input_error {
  opacity: 1;
  max-height: 48px;
  height: auto;
}
.form--input.error input, .form--input.error textarea, .form--input.error select {
  border-color: #E2A0A0;
}
.form--input_error {
  color: var(--c-critical);
  opacity: 0;
  font-size: 14px;
  line-height: 20px;
  max-height: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.03s 0.15s ease-out, max-height 0.18s linear;
  margin-top: 8px;
}
.form--input > label {
  display: flex;
  align-items: flex-start;
}
.form--input > label + label {
  margin-top: 16px;
}
.form input[type=radio],
.form input[type=checkbox] {
  display: none;
  order: -1;
}
.form input[type=radio] ~ span,
.form input[type=checkbox] ~ span {
  order: 1;
  font-size: 16px;
  margin-left: 12px;
  line-height: 20px;
}
.form input[type=radio] ~ svg,
.form input[type=checkbox] ~ svg {
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
}
.form input[type=checkbox] ~ svg rect {
  stroke: #000;
}
.form input[type=checkbox]:checked ~ svg rect {
  fill: #144FC3;
}
.form input[type=radio] ~ svg rect:nth-child(1) {
  stroke: #000;
}
.form input[type=radio]:hover ~ svg rect:nth-child(1), .form input[type=radio]:focus ~ svg rect:nth-child(1) {
  stroke: #144FC3;
}
.form input[type=radio]:checked ~ svg rect:nth-child(2) {
  stroke: #144FC3;
  fill: #144FC3;
}
.form input[type=date] {
  appearance: none;
}
.form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 15L8 10L16 10L12 15Z' fill='%23141A25'/%3E%3C/svg%3E");
  background-position: right 4px center;
  background-repeat: no-repeat;
  background-size: 24px;
  background-color: transparent;
  border-radius: 0;
  display: block;
  padding-right: 48px;
}
.form select option {
  background: #fff;
  border-radius: 0;
  padding: 4px 6px;
}
.form--policy {
  font-size: 16px;
  font-weight: 400;
  margin-top: 8px;
  color: var(--c-text-secondary);
}
.form--policy a:not(.button) {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  color: var(--accent);
}
.form--policy a:not(.button):hover {
  opacity: 0.7;
}
.form--button {
  margin-top: var(--g-24);
  display: flex;
  align-items: center;
  gap: 6px;
}
@media (max-width: 575px) {
  .form--button {
    flex-direction: column;
  }
}
@media (max-width: 575px) {
  .form--button .button {
    width: 100%;
  }
}
.form--button .button:after {
  content: attr(data-text);
  display: inline-block;
}
.form .switch {
  align-items: center;
}
.form .switch .slider {
  position: relative;
  width: 46px;
  height: 24px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 30px;
}
.form .switch .slider:before {
  position: absolute;
  top: 50%;
  left: 30%;
  translate: -50% -50%;
  width: 18px;
  height: 18px;
  content: "";
  transition: 0.4s;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #000;
}
.form .switch input:checked ~ .slider {
  background-color: #144FC3;
}
.form .switch input:checked ~ .slider:before {
  left: 70%;
}

.form.is_sending button[type=submit] {
  background: var(--c-surface-subdued);
  pointer-events: none;
}

.confirmation {
  max-width: 320px;
}
@media (max-width: 768px) {
  .confirmation {
    text-align: center;
  }
}
.confirmation .access {
  color: var(--accent);
}
.confirmation .error {
  color: var(--c-critical);
}

.content_subscription {
  margin-top: auto;
}
.content_subscription .form {
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-top: 24px;
}
.content_subscription .form p {
  color: var(--c-text-disabled);
}
.content_subscription .form--input {
  margin-top: 0;
  gap: 16px;
  flex-direction: row;
  border-bottom: 1px solid #E8E8E8;
  position: relative;
}
.content_subscription .form--input input {
  flex-grow: 1;
  border-bottom: none;
}
.content_subscription .form--input input:placeholder-shown, .content_subscription .form--input input::placeholder {
  text-transform: uppercase;
  border-color: rgba(255, 255, 255, 0.7);
}
.content_subscription .form--input > span {
  font-size: var(--fs-28);
}
.content_subscription .form--input .form--input_error {
  position: absolute;
  top: 100%;
}

.form--input_select {
  display: flex;
  flex-direction: column;
}
.form--input_select > span {
  font-family: "Gilroy";
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 8px;
  transition: var(--transition);
  transform-origin: left top;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: var(--c-text-default);
  order: -1;
}
.form--input_select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
.form--input_select select option {
  display: none;
}
.form--input_select_title {
  margin-bottom: 16px;
  display: none;
  font-weight: 500;
  font-family: "Gilroy";
  line-height: 1;
}

.select {
  position: relative;
}
.select:hover .select--button {
  border-color: rgba(17, 24, 32, 0.4);
}
.select:has(.is_selected) .select--button {
  border-color: rgba(17, 24, 32, 0.4);
  color: var(--c-text-default);
}
.select--button {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 12px 16px;
  position: relative;
  font-size: 16px;
  cursor: pointer;
  transition: var(--transition);
  color: var(--c-text-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select--button:hover, .select--button:focus {
  border-color: var(--c-accent);
}
.select--button:after {
  font-family: "icomoon" !important;
  content: "\e907";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 100%;
  right: 10px;
  top: 0;
}
.select--option_wrapper {
  opacity: 0;
  pointer-events: none;
  padding-top: 12px;
  position: absolute;
  width: auto;
  min-width: 100%;
  height: fit-content;
  max-height: 320px;
  z-index: 3;
}
.select--option_wrapper.is_opened {
  opacity: 1;
  pointer-events: all;
}
.select--option_wrapper.is_opened .select--option_list {
  transform: translateY(0);
}
.select--option_wrapper.is_opened .select--option:nth-child(1) {
  display: none;
}
.select--option_list {
  background-color: #fff;
  transition: var(--transition);
  transform: translateY(-8px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}
.select--option {
  padding: 12px;
  transition: var(--transition);
  cursor: pointer;
  border-radius: 8px;
  font-size: 20px;
  display: flex;
  gap: 6px;
  align-items: center;
  white-space: nowrap;
}
.select--option:first-of-type {
  display: none;
}
.select--option .select--checkbox {
  position: relative;
  order: -1;
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  border-radius: 4px;
  border: 1px solid #111820;
}
.select--option .select--checkbox:before {
  font-family: "icomoon" !important;
  content: "\e906";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  color: #FFFFFF;
  padding: 2px;
}
.select--option:hover .select--checkbox, .select--option:focus .select--checkbox, .select--option:active .select--checkbox {
  border-color: #144FC3;
}
.select--option.is_selected .select--checkbox {
  background-color: #144FC3;
  border-color: #144FC3;
}

form:not(.filter) .select--checkbox {
  display: none;
}
form:not(.filter) .select--option.is_selected {
  background-color: #F2F5FA;
  color: #144FC3;
}
form:not(.filter) .select--option_wrapper.is_opened.select--option_list {
  box-shadow: 0px 4px 24px 0px rgba(23, 29, 40, 0.1019607843);
}

form.is_processing .button[type=submit] {
  pointer-events: none;
  opacity: 0.7;
}
form.is_processing .button[type=submit]:after {
  content: "Отправляется";
}

.form--input_file {
  border-bottom: 1px solid #E8E8E8;
  cursor: pointer;
  width: 100%;
  padding-bottom: 8px;
  padding-right: 32px;
  margin-top: 16px;
}
.form--input_file p, .form--input_file span {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  color: var(--c-text-subdued);
}
.form--input_file p span, .form--input_file span span {
  text-transform: none;
  color: var(--c-text-default);
}
.form--input_file .js-clearFile {
  display: none;
}
.form--input_file.loaded {
  border-color: var(--c-text-default);
  position: relative;
}
.form--input_file.loaded ~ span {
  transform: scale(0.6);
}
.form--input_file.loaded .js-changeDescriptionText {
  position: relative;
  color: var(--c-text-default);
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.form--input_file.loaded .js-changeDescriptionText:before {
  font-family: "icomoon" !important;
  content: "\e92b";
  font-size: 20px;
  line-height: 24px;
  margin-right: 6px;
  vertical-align: middle;
}
.form--input_file.loaded .js-clearFile {
  display: block;
  position: absolute;
  right: 8px;
  top: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.707 12.293C14.098 12.684 14.098 13.316 13.707 13.707C13.512 13.902 13.256 14 13 14C12.744 14 12.488 13.902 12.293 13.707L10 11.414L7.707 13.707C7.512 13.902 7.256 14 7 14C6.744 14 6.488 13.902 6.293 13.707C5.902 13.316 5.902 12.684 6.293 12.293L8.586 10L6.293 7.707C5.902 7.316 5.902 6.684 6.293 6.293C6.684 5.902 7.316 5.902 7.707 6.293L10 8.586L12.293 6.293C12.684 5.902 13.316 5.902 13.707 6.293C14.098 6.684 14.098 7.316 13.707 7.707L11.414 10L13.707 12.293ZM10 2C5.582 2 2 5.582 2 10C2 14.418 5.582 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2Z' fill='%23010812' fill-opacity='0.35'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.content_gallery {
  max-width: 100%;
  overflow: hidden;
}
.content_gallery figcaption {
  display: none;
}
.content_gallery .gallery--item {
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  position: relative;
  border-radius: var(--border-corner);
  aspect-ratio: 640/440;
}
.content_gallery .gallery--item:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(20, 26, 37, 0.35);
  transition: var(--transition);
  z-index: 2;
  pointer-events: none;
  opacity: 0;
}
.content_gallery .gallery--item:hover:after {
  opacity: 1;
}
.content_gallery .gallery--item img, .content_gallery .gallery--item figure {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.gallery:not(.js-gallerySwiper):not([class*=grid]) {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  gap: 20px;
}
@media (max-width: 992px) {
  .gallery:not(.js-gallerySwiper):not([class*=grid]) {
    gap: 8px;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 575px) {
  .gallery:not(.js-gallerySwiper):not([class*=grid]) {
    grid-template-columns: 1fr;
  }
}

.gallery[class*=grid] {
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  display: grid;
  gap: 20px;
}
@media (max-width: 992px) {
  .gallery[class*=grid] {
    gap: 8px;
  }
}
@media (max-width: 575px) {
  .gallery[class*=grid] {
    grid-template-columns: repeat(2, 1fr);
  }
}

.gallery--grid:not(.gallery-reversed) .gallery--item {
  height: 100%;
}
@media (max-width: 575px) {
  .gallery--grid:not(.gallery-reversed) .gallery--item {
    aspect-ratio: 180/155;
  }
}
.gallery--grid:not(.gallery-reversed) .gallery--item:nth-child(6n+1) {
  grid-column: span 2;
  grid-row: span 2;
}
@media (max-width: 575px) {
  .gallery--grid:not(.gallery-reversed) .gallery--item:nth-child(6n+1) {
    grid-column: 1;
  }
}
.gallery--grid:not(.gallery-reversed) .gallery--item:nth-child(n+4):nth-child(6n-1) {
  grid-column: 2/4;
  grid-row: span 2;
}
@media (max-width: 575px) {
  .gallery--grid:not(.gallery-reversed) .gallery--item:nth-child(n+4):nth-child(6n-1) {
    grid-column: 2;
  }
}
.gallery--grid-reversed .gallery--item {
  height: 100%;
}
@media (max-width: 575px) {
  .gallery--grid-reversed .gallery--item {
    aspect-ratio: 180/155;
  }
}
.gallery--grid-reversed .gallery--item:nth-child(6n+2) {
  grid-column: 2/4;
  grid-row: span 2;
}
@media (max-width: 575px) {
  .gallery--grid-reversed .gallery--item:nth-child(6n+2) {
    grid-column: 2;
  }
}
.gallery--grid-reversed .gallery--item:nth-child(n+4):nth-child(6n-2) {
  grid-column: span 2;
  grid-row: span 2;
}
@media (max-width: 575px) {
  .gallery--grid-reversed .gallery--item:nth-child(n+4):nth-child(6n-2) {
    grid-column: 1;
  }
}

.js-gallerySwiper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
@media (max-width: 575px) {
  .js-gallerySwiper {
    width: 95%;
    margin: auto;
  }
}
.js-gallerySwiper figcaption {
  display: none;
}
.js-gallerySwiper .gallery--item {
  aspect-ratio: 1600/920;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}
@media (max-width: 560px) {
  .js-gallerySwiper .gallery--item {
    aspect-ratio: 360/298;
  }
}
.js-gallerySwiper .gallery--item figure {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.js-gallerySwiper .gallery--item img {
  width: 100%;
  object-fit: cover;
}

.is_cascade__slider .gallery--item {
  aspect-ratio: 828/480;
}

.is_processing:not(.form) {
  position: relative;
}
.is_processing:not(.form):after {
  content: "";
  inset: 0;
  z-index: 3;
  position: absolute;
  background-color: rgba(244, 248, 255, 0.7);
  background-image: url("/local/templates/main/assets/img/preloader.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.footer {
  padding: clamp(64px, 64px + 16 * (100vw - 1024px) / 896, 80px) var(--g-container);
  background-color: var(--c-bck-dark-blue);
  color: var(--c-text-primary);
}
.footer .block--wrapper {
  margin: 0 var(--g-footer);
  padding: 0;
}
.footer--contacts {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 769px) {
  .footer--contacts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.footer--contacts .block--content {
  margin-top: 0;
  padding: var(--g-32);
  color: var(--c-surface-dark);
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: var(--g-content);
}
.footer--contacts > div {
  overflow: hidden;
  border-radius: var(--border-corner);
}
@media (max-width: 768px) {
  .footer--contacts .block--map {
    aspect-ratio: 1/1;
  }
}
.footer--contacts .block--map #map {
  width: 100%;
  max-height: 100%;
}
.footer--row {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--g-80);
  padding-bottom: var(--g-container);
}
@media (min-width: 992px) {
  .footer--row {
    grid-template-columns: 3fr 9fr;
  }
}
.footer--row-bottom {
  display: flex;
  justify-content: space-between;
  padding: var(--g-32) 0;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
}
@media (max-width: 768px) {
  .footer--row-bottom {
    flex-direction: column;
  }
}
.footer--col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--g-32);
}
.footer--soc {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: var(--g-24);
}
.footer--soc .soc--list {
  display: flex;
  gap: 8px;
}
.footer--soc .soc--link {
  padding: 10px;
  background-color: var(--accent);
  border-radius: var(--border-corner-button);
  font-size: var(--fs-32);
}
.footer--links {
  display: flex;
  gap: 20px 40px;
  flex-wrap: wrap;
}
.footer--links a {
  white-space: nowrap;
}
.footer--links a:hover {
  color: var(--c-text-accent-hovered);
}
.footer--lk {
  width: 100%;
  padding: var(--g-24);
  background-color: var(--accent);
  border-radius: var(--border-corner);
  display: flex;
  flex-direction: column;
  gap: var(--g-32);
  font-size: var(--fs-32);
}
.footer--lk .button {
  margin-left: auto;
  padding: 13px;
}
@media (max-width: 768px) {
  .footer--lk .button {
    padding: 9px;
  }
}
.footer--lk .button:before {
  transition: var(--transition);
}
.footer .developer {
  grid-column: 1;
  white-space: nowrap;
  display: inline-flex;
}
.footer .developer a {
  filter: invert(100%);
  display: flex;
  margin-left: 8px;
}
.footer .developer a:hover {
  filter: invert(50%);
}
.footer .developer a img {
  object-fit: contain;
}
@media (min-width: 768px) {
  .footer .developer {
    grid-column: 2;
  }
}
.footer .copyright {
  color: var(--c-text-disabled);
}

#map {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-corner);
}

.ymaps-2-1-79-map-copyrights-promo {
  display: none;
}

.content_events-calendar .block--elements {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--g-20);
}
@media (max-width: 1440px) {
  .content_events-calendar .block--elements {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 998px) {
  .content_events-calendar .block--elements {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 640px) {
  .content_events-calendar .block--elements {
    grid-template-columns: 1fr;
  }
}
.content_events-calendar .block--filter {
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .content_events-calendar .block--filter {
    margin: var(--g-section-inner) 0;
  }
}
.content_events-calendar .block--filter .filter .select--option_wrapper {
  right: 0;
}
@media (min-width: 769px) {
  .content_events-calendar .block--filter .filter .button[disabled] {
    opacity: 0;
  }
}
.content_events-calendar .block--filter .filter .filter--buttons {
  display: flex;
}
@media (min-width: 769px) {
  .content_events-calendar .block--filter .filter .filter--buttons .button[type=reset] {
    display: flex;
  }
}
.content_events-calendar .block--calendar {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}
.content_events-calendar .block--controls {
  display: flex;
  gap: 8px;
}
@media (min-width: 769px) {
  .content_events-calendar .block--controls {
    position: absolute;
    left: 0;
    top: calc((50px + 0.625em) * -1);
  }
}
@media (max-width: 768px) {
  .content_events-calendar .block--controls {
    margin: 16px 0;
  }
}
.content_events-calendar .litepicker {
  width: 100%;
}
.content_events-calendar .litepicker .container__main {
  width: 100%;
}
.content_events-calendar .litepicker .container__main .container__months {
  box-shadow: none;
  width: 100%;
  gap: 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
@media (max-width: 1024px) {
  .content_events-calendar .litepicker .container__main .container__months {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575px) {
  .content_events-calendar .litepicker .container__main .container__months {
    grid-template-columns: 1fr;
  }
}
.content_events-calendar .litepicker .container__main .container__months .month-item {
  background-color: var(--c-surface-subdued);
  border-radius: var(--border-corner);
  padding: var(--g-32);
  width: auto;
}
.content_events-calendar .litepicker .container__main .container__months .month-item-header {
  font-size: var(--fs-52);
  font-family: "Gilroy";
  line-height: 1;
  text-transform: uppercase;
}
.content_events-calendar .litepicker .container__main .container__months .month-item-header strong {
  font-weight: 500;
}
.content_events-calendar .litepicker .container__main .container__months .month-item-header .button-previous-month,
.content_events-calendar .litepicker .container__main .container__months .month-item-header .button-next-month {
  aspect-ratio: 1/1;
  padding: 18px;
  border: 1px solid rgba(17, 24, 32, 0.4);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  transition: var(--transition);
}
@media (min-width: 1025px) {
  .content_events-calendar .litepicker .container__main .container__months .month-item-header .button-previous-month,
  .content_events-calendar .litepicker .container__main .container__months .month-item-header .button-next-month {
    padding: 24px;
    background-size: 24px;
  }
}
@media (max-width: 575px) {
  .content_events-calendar .litepicker .container__main .container__months .month-item-header .button-previous-month,
  .content_events-calendar .litepicker .container__main .container__months .month-item-header .button-next-month {
    border: none;
  }
}
.content_events-calendar .litepicker .container__main .container__months .month-item-header .button-previous-month svg,
.content_events-calendar .litepicker .container__main .container__months .month-item-header .button-next-month svg {
  display: none;
}
@media (min-width: 576px) {
  .content_events-calendar .litepicker .container__main .container__months .month-item-header .button-previous-month:hover,
  .content_events-calendar .litepicker .container__main .container__months .month-item-header .button-next-month:hover {
    background-color: #e0e7f3;
    border-color: #e0e7f3;
  }
}
.content_events-calendar .litepicker .container__main .container__months .month-item-header .button-previous-month {
  cursor: pointer;
  background-image: url("../img/icons/chevron-L.svg");
}
.content_events-calendar .litepicker .container__main .container__months .month-item-header .button-next-month {
  cursor: pointer;
  background-image: url("../img/icons/shevron-R.svg");
}
.content_events-calendar .litepicker .container__main .container__months .month-item-weekdays-row div {
  font-size: 16px;
  text-transform: uppercase;
}
.content_events-calendar .litepicker .container__main .container__months .container__days .day-item {
  position: relative;
  border: none;
  aspect-ratio: 1/1;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  z-index: 2;
}
.content_events-calendar .litepicker .container__main .container__months .container__days .day-item:hover {
  border: none;
}
.content_events-calendar .litepicker .container__main .container__months .container__days .day-item:hover:after {
  background-color: #FFFFFF;
}
.content_events-calendar .litepicker .container__main .container__months .container__days .day-item:not(.is-locked):after {
  transition: var(--transition);
  z-index: -1;
  content: "";
  position: absolute;
  inset: 2px;
  border-radius: 50%;
  border: #144FC3 1px solid;
}
.content_events-calendar .litepicker .container__main .container__months .container__days .day-item.is-start-date:after, .content_events-calendar .litepicker .container__main .container__months .container__days .day-item.is-end-date:after {
  background-color: var(--accent);
}
.content_events-calendar .litepicker .container__main .container__months .container__days .day-item.is-start-date:hover, .content_events-calendar .litepicker .container__main .container__months .container__days .day-item.is-end-date:hover {
  color: #fff;
}
.content_events-calendar #calendar {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.content_events-calendar .reset-button {
  display: none;
  align-items: center;
  position: relative;
  cursor: pointer;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: var(--fs-40);
  padding: 4px 23px;
  text-align: center;
  white-space: nowrap;
  color: var(--c-text-disabled);
  border-radius: var(--border-corner-button);
  border: 1px solid transparent;
  width: max-content;
  border-color: currentColor;
  color: var(--c-text-default);
  margin-top: var(--g-section-inner);
}
@media (max-width: 768px) {
  .content_events-calendar .reset-button {
    order: -1;
    margin: 20px 8px;
  }
}
.content_events-calendar .reset-button.is_active {
  display: flex;
}
.content_events-calendar .not-found {
  padding: var(--g-32) 0;
  grid-column: span 4;
  text-align: center;
}
@media (max-width: 1440px) {
  .content_events-calendar .not-found {
    grid-column: span 3;
  }
}
@media (max-width: 998px) {
  .content_events-calendar .not-found {
    grid-column: span 2;
  }
}
@media (max-width: 640px) {
  .content_events-calendar .not-found {
    grid-column: span 1;
  }
}
.content_events-calendar .not-found p {
  font-weight: 500;
  font-family: "Gilroy";
  line-height: 1;
  font-size: var(--fs-52);
  margin-bottom: 16px;
}
.content_events-calendar .not-found span {
  font-size: var(--fs-32);
}

.block--wrapper {
  padding: var(--g-container-vertical) var(--g-container);
  margin: 0 auto;
}
.block--content, .block--elements {
  margin-top: var(--g-section-inner);
}

main .block:first-child .block--wrapper {
  padding-top: 0;
}

.block--head {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: var(--g-24);
  border-bottom: 1px solid currentColor;
}
@media (min-width: 998px) {
  .block--head {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "subtitle caption" "title caption" "title action";
  }
}
.block--head_subtitle {
  grid-area: subtitle;
  font-family: "Gilroy";
  font-weight: 500;
  grid-column: 1;
  font-size: var(--fs-24);
  width: fit-content;
  color: var(--accent);
}
.block--head_subtitle:empty {
  display: none;
}
.block--head_subtitle p {
  letter-spacing: 0.28px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .block--head_subtitle p {
    margin-bottom: 8px;
  }
}
.block--head_subtitle p:empty {
  display: none;
}
.block--head_caption {
  grid-area: caption;
  grid-column: 2;
  max-width: 868px;
  font-size: var(--fs-32);
}
.block--head_caption:empty {
  display: none;
}
.block--head_caption p {
  font-weight: 300;
  letter-spacing: -0.4px;
}
.block--head_caption p:empty {
  display: none;
}
.block--head_caption img {
  object-fit: contain;
}
.block--head_title {
  grid-area: title;
  grid-column: 1;
  max-width: 1160px;
  text-align: left;
}
.block--head_title h1, .block--head_title h2, .block--head_title h3 {
  text-transform: uppercase;
}
.block--head_title a:hover, .block--head_title a:focus {
  color: var(--c-text-accent-default);
}
.block--head_link {
  grid-area: action;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  grid-column: 2;
}
.block--head_link .button {
  text-transform: uppercase;
  color: var(--accent-2);
  font-size: var(--fs-32);
  padding-right: 6px;
}
.block--head_link .button .icon-arrow-r {
  transition: var(--transition) translate;
}
.block--head_link .button:hover .icon-arrow-r {
  translate: 6px 0;
}
.block--head .block--actions {
  grid-area: action;
  justify-content: flex-end;
  align-items: flex-end;
  grid-column: 2;
}
@media (max-width: 998px) {
  .block--head .block--actions {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.footer .block--head {
  display: flex;
  align-items: center;
}

.block--actions, .button_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.block--actions .button, .button_wrapper .button {
  margin-top: 24px;
}
@media (max-width: 768px) {
  .block--actions .button, .button_wrapper .button {
    width: 100%;
    margin-top: 16px;
  }
}

.block--actions:empty {
  display: none;
}

.block--pagination {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 8px;
  margin-top: var(--g-48);
}
.block--pagination:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  background-color: var(--c-border-subdued);
}
.block--pagination .button {
  background-color: #fff;
}
.block--pagination .button:before {
  transition: 0.15s linear;
}
@media (max-width: 768px) {
  .block--pagination .button:last-of-type span:not([class^=icon-shevron-R]) {
    display: none;
  }
  .block--pagination .button:first-of-type span:not([class^=icon-chevron-L]) {
    display: none;
  }
}
.block--pagination .button span:not([class]) ~ span[class^=icon-shevron-R] {
  padding-right: 0;
  padding-left: 0;
}
.block--pagination .block--pagination_gap {
  display: flex;
  align-items: flex-end;
}
@media (max-width: 768px) {
  .block--pagination {
    grid-column: span 1;
  }
}

.block--sections {
  height: max-content;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: sections;
  margin-top: var(--g-section-inner);
}
@media (max-width: 768px) {
  .block--sections {
    padding: 20px 8px;
    margin: 0 -8px;
  }
}
.block--sections button, .block--sections a {
  display: inline-block;
  cursor: pointer;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: var(--fs-40);
  padding: 4px 23px;
  text-align: center;
  white-space: nowrap;
  color: var(--c-text-disabled);
  border-radius: var(--border-corner-button);
  border: 1px solid transparent;
}
@media (max-width: 475px) {
  .block--sections button, .block--sections a {
    max-width: 260px;
    width: max-content;
    display: flex;
  }
}
@media (max-width: 475px) {
  .block--sections button span, .block--sections a span {
    display: inline-block;
    width: 100%;
    max-width: calc(100vw - 35%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.block--sections button:hover, .block--sections button:focus, .block--sections a:hover, .block--sections a:focus {
  color: var(--c-text-default);
}
.block--sections button.is_active, .block--sections a.is_active {
  border-color: currentColor;
  color: var(--c-text-default);
  position: relative;
}
.block--sections_container {
  max-width: calc(100% - 100px - var(--g-24));
}
.block--sections .slider_controls {
  display: none;
}
@media (min-width: 769px) {
  .block--sections .slider_controls {
    display: flex;
    width: max-content;
    z-index: 2;
    position: relative;
    pointer-events: all;
  }
  .block--sections .slider_controls:after {
    background: linear-gradient(90deg, rgba(255, 254, 254, 0) 0%, rgb(255, 255, 255) 15%);
    content: "";
    position: absolute;
    left: -40%;
    right: -20%;
    top: -16px;
    bottom: -16px;
  }
  .block--sections .slider_controls .button {
    padding: 15px;
  }
  .block--sections .slider_controls .button:before {
    transition: 0.15s linear;
  }
}

.block_banner {
  overflow: hidden;
  position: relative;
}
@media (min-width: 520px) and (orientation: landscape) {
  .block_banner {
    max-height: 1080px;
    min-height: 620px;
  }
}
.block_banner .block--wrapper {
  height: 100%;
  position: relative;
  z-index: 2;
}
.block_banner .block--head,
.block_banner .block--actions {
  z-index: 2;
  position: relative;
}
.block_banner .block--actions .button {
  margin-top: 0;
}
.block_banner .block--head_subtitle {
  color: #fff;
}
.block_banner .block--content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.block_banner .block--content img, .block_banner .block--content video {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}
.block_banner .block--head {
  padding-bottom: 0;
  color: var(--c-text-primary);
  border-bottom: none;
}
.block_banner .block--head > * {
  z-index: 3;
  position: relative;
}
.block_banner .block--head_title {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.block_banner .banner {
  display: flex;
  padding: var(--g-container);
  flex-shrink: 0;
  flex-grow: 0;
  height: auto;
  overflow: hidden;
  position: relative;
  place-content: end start;
  place-items: end start;
}

.block_banner.block_banner-group:not(.block_banner-hero) .block--wrapper {
  overflow: hidden;
}

.block_banner:not(.block_banner-hero) .banner {
  margin: auto;
  padding: 84px var(--g-container);
  place-content: center;
  place-items: center;
  border-radius: var(--border-corner);
}
@media (min-width: 560px) {
  .block_banner:not(.block_banner-hero) .banner {
    height: calc(100vh - var(--headerHeight, 95px));
  }
}
.block_banner:not(.block_banner-hero) .banner:before {
  content: "";
  position: absolute;
  inset: 0;
  display: block;
  background: rgba(20, 26, 37, 0.3);
  pointer-events: none;
  z-index: 1;
}
.block_banner:not(.block_banner-hero) .block--head {
  text-align: center;
  align-items: center;
  position: relative;
  padding: var(--g-container) var(--g-container) 0;
  display: flex;
  align-items: center;
  z-index: 2;
}
.block_banner:not(.block_banner-hero) .block--head > div {
  text-align: center;
}

@media (min-width: 998px) {
  .block_banner.block_banner-hero.block_banner-group .block--head {
    padding-right: 140px;
  }
}
@media (max-width: 768px) {
  .block_banner.block_banner-hero.block_banner-group .banner {
    padding-bottom: 70px;
  }
}

.block_banner.block_banner-hero {
  margin: 0;
  position: relative;
  background-color: var(--c-surface-dark);
  height: calc(100vh - var(--headerHeight, 77px) - 100px);
}
@media (min-width: 769px) {
  .block_banner.block_banner-hero {
    height: calc(100vh - var(--headerHeight, 95px));
  }
}
.block_banner.block_banner-hero .block--wrapper {
  padding: 0;
  max-width: 100%;
}
.block_banner.block_banner-hero .banner {
  position: relative;
  height: 100%;
  min-width: 100%;
  padding: var(--headerHeight, 95px) var(--g-32) 42px;
}
@media (min-width: 769px) {
  .block_banner.block_banner-hero .banner {
    padding: var(--headerHeight, 95px) var(--g-32) var(--g-32);
  }
}
.block_banner.block_banner-hero .banner:before {
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1965161064) 33%, rgba(220, 220, 220, 0) 47%, rgba(0, 0, 0, 0.65) 100%);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 2;
}
.block_banner.block_banner-hero .block--content {
  margin-top: auto;
}
.block_banner.block_banner-hero .block--head {
  color: var(--c-text-primary);
  border-top: 1px solid #fff;
}
@media (max-width: 998px) {
  .block_banner.block_banner-hero .block--head {
    width: 100%;
  }
}
.block_banner.block_banner-hero .block--head_caption p {
  font-size: var(--fs-36);
}
.block_banner.block_banner-hero .block--head_subtitle {
  position: relative;
  width: 100%;
}
.block_banner.block_banner-hero .block--head_subtitle p {
  position: absolute;
  top: -50px;
}
@media (max-width: 998px) {
  .block_banner.block_banner-hero .block--head_subtitle p {
    top: -30px;
  }
}
.block_banner.block_banner-hero .block--actions {
  width: 100%;
  justify-content: flex-start;
}
.block_banner.block_banner-hero .slider_controls {
  align-items: center;
  position: absolute;
  padding: 0 var(--g-container) var(--g-container);
  bottom: 0;
  right: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .block_banner.block_banner-hero .slider_controls {
    width: 100%;
    padding: var(--g-container) var(--g-container);
  }
}
.block_banner.block_banner-hero .swiper--prev,
.block_banner.block_banner-hero .swiper--next {
  padding: 12px;
  border: 1px solid var(--c-surface-subdued);
}
.block_banner.block_banner-hero .swiper-pagination {
  margin-right: auto;
}
@media (min-width: 768px) {
  .block_banner.block_banner-hero .swiper-pagination {
    display: none;
  }
}

.block_banner.banner-no_media .banner {
  padding: var(--g-content) 0;
  height: auto;
  min-height: 80vh;
  aspect-ratio: unset;
}
.block_image_text .block--head {
  display: flex;
  flex-direction: column;
  border-bottom: none;
  border-radius: var(--border-corner) var(--border-corner) 0 0;
  grid-column: 1;
}
.block_image_text .block--head_title {
  font-size: var(--fs-72);
  padding-bottom: var(--g-24);
  border-bottom: none;
  overflow-wrap: anywhere;
  padding-bottom: 0;
}
@media (max-width: 475px) {
  .block_image_text .block--head .block--actions {
    width: 100%;
  }
  .block_image_text .block--head .block--actions .button {
    width: 100%;
  }
}
.block_image_text .block--wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: start;
  gap: 0 8px;
  grid-template-rows: repeat(auto-fit, auto);
  align-items: center;
}
@media (max-width: 768px) {
  .block_image_text .block--wrapper {
    display: flex;
    flex-direction: column;
  }
}
.block_image_text .block--content {
  height: 100%;
  border-radius: 0 0 var(--border-corner) var(--border-corner);
  margin-bottom: auto;
  grid-column: 2;
}
@media (max-width: 768px) {
  .block_image_text .block--content {
    grid-column: 1;
    min-height: 15px;
  }
}
.block_image_text .block--image ~ .block--head,
.block_image_text .block--image ~ .block--content {
  height: 100%;
  grid-column: 1;
  width: 100%;
  background-color: var(--c-surface-default);
}
@media (min-width: 769px) {
  .block_image_text .block--image ~ .block--head,
  .block_image_text .block--image ~ .block--content {
    padding-left: var(--g-80);
    padding-right: var(--g-80);
  }
}
.block_image_text .block--image ~ .block--head {
  justify-content: flex-end;
}
.block_image_text .block--image {
  display: flex;
  grid-column: 2;
  grid-row: 1/span 2;
  min-height: 100%;
  max-width: 100%;
  border-radius: var(--border-corner);
}
@media (max-width: 768px) {
  .block_image_text .block--image {
    grid-column: 1;
    grid-row: 1/span 1;
    aspect-ratio: 1/1;
  }
}
.block_image_text .block--image img, .block_image_text .block--image video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: var(--border-corner);
  overflow: hidden;
  aspect-ratio: 1/1;
}
@media (min-width: 769px) {
  .block_image_text .block--image img, .block_image_text .block--image video {
    position: sticky;
    top: calc(var(--headerHeight));
  }
}
.block_image_text .block--head {
  align-items: flex-start;
  padding-top: var(--g-80);
}
.block_image_text .block--content {
  margin-top: 0;
}
.block_image_text.block_image_text-reversed .block--wrapper .block--image {
  grid-column: 1;
}
.block_image_text.block_image_text-reversed .block--wrapper .block--head,
.block_image_text.block_image_text-reversed .block--wrapper .block--content {
  grid-column: 2;
}
@media (max-width: 768px) {
  .block_image_text.block_image_text-reversed .block--wrapper .block--head,
  .block_image_text.block_image_text-reversed .block--wrapper .block--content {
    grid-column: 1;
  }
}
.block_image_text.block_image_text-bg .block--head,
.block_image_text.block_image_text-bg .block--content {
  background-color: var(--bg-color);
  color: var(--text-color);
}
.block_image_text.block_image_text-bg .block--head_subtitle {
  color: var(--subtitle-color);
}
.block_image_text.block_image_text-wrapper_bg .block--wrapper {
  background-color: var(--bg-color);
}
.block_image_text.block_image_text-no_media .block--wrapper {
  grid-template-rows: auto;
}
@media (max-width: 768px) {
  .block_image_text.block_image_text-no_media .block--wrapper {
    padding: var(--g-container);
  }
}
.block_image_text.block_image_text-no_media .block--head {
  width: 100%;
  padding-top: 0;
}
.block_image_text.block_image_text-mask #mask,
.block_image_text.block_image_text-mask #mask-2 {
  position: absolute;
  width: 0;
  height: 0;
}
.block_image_text.block_image_text-mask .block--image img {
  border-radius: 1000px 1000px 1000px 0;
  height: auto;
}
.block_image_text.block_image_text-reversed.block_image_text-mask .block--image img {
  border-radius: 1000px 1000px 0 1000px;
}
.block_image_text .block--image.block--image-slider {
  overflow: hidden;
  height: max-content;
  overflow: hidden;
  min-height: auto;
  margin-bottom: auto;
}
.block_image_text .block--image.block--image-slider img {
  height: auto;
  flex-shrink: 0;
  flex-grow: 0;
}
@media (min-width: 769px) {
  .block_image_text .block--image.block--image-slider .slider_controls .swiper-pagination {
    margin-left: 0;
    margin-right: auto;
  }
}
.block_image_text .block--image.block--image-slider .slider_controls .button {
  padding: 13px;
}

@media (max-width: 768px) {
  .block_image_text[class*=background] .block--image ~ .block--head,
  .block_image_text[class*=background] .block--image ~ .block--content {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }
  .block_image_text[class*=background] .block--image {
    margin-bottom: 8px;
  }
}

.block_list:not(.block_list-slider) .block--elements {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  place-items: stretch;
}
@media (max-width: 1440px) {
  .block_list:not(.block_list-slider) .block--elements {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}
@media (max-width: 768px) {
  .block_list:not(.block_list-slider) .block--elements {
    grid-template-columns: repeat(2, 1fr);
  }
}
.block_list:not(.block_list-slider) .block--elements .block--sections {
  grid-area: sections;
}
.block_list:not(.block_list-slider) .block--elements .block--pagination {
  grid-column: span 4;
}
@media (max-width: 1440px) {
  .block_list:not(.block_list-slider) .block--elements .block--pagination {
    grid-column: span 3;
  }
}
@media (max-width: 640px) {
  .block_list:not(.block_list-slider) .block--elements .block--pagination {
    grid-column: span 2;
  }
}
@media (max-width: 768px) {
  .block_list:not(.block_list-slider) .block--elements {
    display: flex;
    flex-direction: column;
  }
  .block_list:not(.block_list-slider) .block--elements .card {
    width: 100%;
    height: max-content;
  }
  .block_list:not(.block_list-slider) .block--elements .block--pagination {
    grid-column: span 1;
  }
}
.block_list.block_list-slider {
  position: relative;
  overflow: hidden;
}
.block_list.block_list-slider .block--elements {
  display: flex;
  flex-direction: column;
}
.block_list.block_list-slider-v2 .block--wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (min-width: 998px) {
  .block_list.block_list-slider-v2 .block--wrapper {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
  }
}
.block_list.block_list-slider-v2 .block--head {
  display: flex;
  flex-direction: column;
  background-color: var(--accent);
  color: var(--c-surface-default);
  display: flex;
  flex-direction: column;
  padding: var(--g-content);
  text-align: left;
  align-items: flex-start;
  border-radius: var(--border-corner);
  gap: 16px;
}
.block_list.block_list-slider-v2 .block--head h2 {
  font-size: var(--fs-52);
}
.block_list.block_list-slider-v2 .block--head_subtitle {
  color: var(--accent-2);
}
.block_list.block_list-slider-v2 .block--head_caption {
  color: #E0E7F3;
  font-size: var(--fs-24);
}
.block_list.block_list-slider-v2 .block--elements {
  overflow: hidden;
  margin: 0;
  margin-right: calc(var(--g-container) * -1);
  padding-right: 18%;
}
@media (max-width: 768px) {
  .block_list.block_list-slider-v2 .block--elements {
    padding-right: 10%;
  }
}
.block_list.block_list-slider-v2 .block--elements .card--label {
  display: none;
}
.block_list.block_list-slider-v2 .block--elements .card--content {
  gap: 12px;
}
.block_list.block_list-slider-v2 .block--elements .card--actions {
  margin-top: auto;
}

.block_list:not(.block_list-slider-v2) .block--wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "heading heading  " "sections  filter " " elements elements ";
  gap: 0;
  align-items: center;
}
@media (max-width: 768px) {
  .block_list:not(.block_list-slider-v2) .block--wrapper {
    grid-template-areas: "heading heading  " "sections sections" "filter filter" " elements elements ";
  }
}
.block_list:not(.block_list-slider-v2) .block--wrapper .block--head {
  grid-area: heading;
}
.block_list:not(.block_list-slider-v2) .block--wrapper .block--elements {
  grid-area: elements;
}
.block_list:not(.block_list-slider-v2) .block--wrapper .block--sections {
  grid-area: sections;
}

.block--stripe .block--wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
.block--stripe .item {
  display: flex;
  gap: 8px;
}
@media (max-width: 768px) {
  .block--stripe .item {
    flex-direction: column;
  }
}
.block--stripe .item--image, .block--stripe .item--actions {
  overflow: hidden;
  flex: 20% 0 0;
  border-radius: var(--border-corner);
}
.block--stripe .item--content {
  display: flex;
  flex-direction: column;
  gap: var(--g-20);
  width: 100%;
  border-radius: var(--border-corner);
  background-color: var(--c-surface-subdued);
  padding: var(--g-48);
}
.block--stripe .item--title {
  text-transform: uppercase;
  font-family: "Gilroy";
  font-size: var(--fs-52);
}
.block--stripe .item--text {
  font-size: var(--fs-24);
}
.block--stripe .item--image {
  display: flex;
  aspect-ratio: 420/230;
}
@media (max-width: 768px) {
  .block--stripe .item--image {
    aspect-ratio: 360/200;
  }
}
.block--stripe .item--actions {
  display: grid;
  grid-template-areas: "icon title" "icon text" "auto button";
  grid-template-columns: auto 100%;
  gap: 8px 0;
  font-size: var(--fs-32);
  font-family: "Gilroy";
  padding: var(--g-24);
  background-color: var(--bg-color, #f2f5fa);
  color: var(--text-color);
}
.block--stripe .item--actions-title {
  grid-area: title;
  display: flex;
  gap: 6px;
}
.block--stripe .item--actions .button {
  margin-left: auto;
  grid-area: button;
  margin-top: auto;
  padding: 15px;
}
@media (max-width: 768px) {
  .block--stripe .item--actions .button {
    padding: 9px;
  }
}
.block--stripe .item--actions .button:before {
  transition: var(--transition);
}
.block--stripe .item--actions .item--text {
  font-size: var(--fs-20);
  grid-area: text;
}
.block--stripe .item--actions > span[class^=icon-] {
  color: currentColor;
  font-size: 1em;
  margin-right: 8px;
  grid-area: icon;
}
.block--stripe .item--actions:not([href]) .button {
  display: none;
}

.block--filter {
  margin-top: var(--g-section-inner);
  display: flex;
  align-items: flex-end;
}
@media (max-width: 575px) {
  .block--filter {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .block--filter .filter {
    display: none;
  }
}
.block--filter .filter fieldset {
  display: flex;
  align-items: center;
  gap: 16px;
  border: none;
}
.block--filter .filter fieldset .form--input {
  margin-top: 0;
}
.block--filter .filter fieldset .form--input select {
  border-bottom: none;
}
.block--filter .filter .filter--buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (max-width: 768px) {
  .block--filter .filter .filter--buttons {
    margin-top: 16px;
  }
}
@media (min-width: 769px) {
  .block--filter .filter .filter--buttons {
    order: -1;
    display: none;
  }
}
@media (min-width: 769px) {
  .block--filter .filter .filter--buttons .button[type=reset] {
    padding: 0;
    border: none;
  }
  .block--filter .filter .filter--buttons .button[type=reset]:before {
    content: none;
  }
}
@media (min-width: 769px) and (min-width: 769px) {
  .block--filter .filter .filter--buttons .button[type=reset]:hover, .block--filter .filter .filter--buttons .button[type=reset]:active, .block--filter .filter .filter--buttons .button[type=reset]:focus {
    color: var(--accent);
    background-color: transparent;
  }
}
@media (min-width: 769px) {
  .block--filter .filter .filter--buttons .button {
    display: none;
  }
}
.block--filter .filter .filter--buttons button {
  width: 100%;
}
.block--filter [data-openpopup] {
  width: 100%;
}
@media (min-width: 768px) {
  .block--filter [data-openpopup] {
    display: none;
  }
}

.block--sections ~ .block--filter .select--option_wrapper {
  left: auto;
  right: 0;
}

.card {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-corner);
  background-color: var(--c-bck-default);
  overflow: hidden;
}

.card--content {
  display: flex;
  flex-direction: column;
  gap: var(--g-8);
  padding: var(--g-24);
  height: 100%;
}

.card--title,
.card--title > *,
.card--text,
.card--text p {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.2;
}

.card--text {
  font-size: var(--fs-18);
  letter-spacing: 0.02em;
}

.card--title {
  font-size: var(--fs-32);
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.01em;
}

.card--video:after {
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52' fill='none'%3E%3Cg filter='url(%23filter0_b_7699_76827)'%3E%3Ccircle cx='26' cy='26' r='26' fill='black' fill-opacity='0.2'/%3E%3Ccircle cx='26' cy='26' r='25' stroke='white' stroke-width='2'/%3E%3C/g%3E%3Cpath d='M35.75 26L19.5 35.75L19.5 16.25L35.75 26Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_b_7699_76827' x='-24' y='-24' width='100' height='100' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImageFix' stdDeviation='12'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_7699_76827'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_7699_76827' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  top: 16px;
  left: auto;
  right: var(--g-20);
  content: "";
  position: absolute;
  width: 64px;
  height: 64px;
  z-index: 2;
}
@media (max-width: 768px) {
  .card--video:after {
    width: 52px;
    height: 52px;
  }
}
.card--video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card--image,
.card--video {
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  aspect-ratio: 1/1;
  height: auto;
  width: 100%;
  background-image: url("/local/templates/main/assets/img/default-image.jpg");
  background-color: var(--c-surface-subdued);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: var(--border-corner);
  flex: 0 0 auto;
}
.card--image img,
.card--video img {
  transition: var(--transition);
}

.card-detail .card--image {
  background: unset;
}

.card--label {
  display: flex;
  flex-wrap: wrap;
  gap: var(--g-8);
  font-size: var(--fs-14);
  font-weight: 400;
  line-height: 1.42;
  z-index: 3;
  top: 0;
  left: 0;
}
.card--label span {
  display: flex;
  gap: 6px;
  white-space: nowrap;
  background-color: var(--c-surface-default);
  color: var(--c-text-subdued);
  padding: 10px 10px;
  border-radius: 4px;
}
.card--label span:before {
  font-family: "icomoon" !important;
  line-height: 1;
  font-size: 20px;
}
.card--label .c-accent {
  color: var(--accent-2);
}
.card--label .c-accent-2 {
  color: var(--accent);
}
.card--label .label-tourney:before {
  content: "\e90a";
}
.card--label .label-money:before {
  content: "\e915";
}
.card--label .label-horse:before {
  content: "\e912";
}
.card--label .label-date:before {
  content: "\e904";
}

.card--actions {
  margin-top: auto;
}
.card--actions .button {
  margin-top: 16px;
  width: 100%;
  padding: 9px;
}
@media (min-width: 769px) {
  .card--actions .button {
    padding: 13px;
  }
}

.card:not([data-popup_cascade]):not([href]) .card--actions {
  display: none;
}

.card[data-popup_cascade] {
  position: relative;
  cursor: pointer;
}
.card[data-popup_cascade] .card--action .icon {
  font-family: "icomoon" !important;
}
.card[data-popup_cascade] .card--action .icon:before {
  content: "\e910";
}
.card[data-popup_cascade]:hover .card--action:before, .card[data-popup_cascade]:focus .card--action:before {
  background-color: rgba(20, 26, 37, 0.35);
}
.card[data-popup_cascade]:hover .card--action, .card[data-popup_cascade]:focus .card--action {
  color: var(--c-surface-dark);
}
.card[data-popup_cascade]:hover .card--action:after, .card[data-popup_cascade]:focus .card--action:after {
  transform: rotate(45deg);
  background-color: var(--c-surface-default);
}

.card[href] {
  position: relative;
  cursor: pointer;
}
.card[href]:hover .card--image img, .card[href]:focus .card--image img {
  scale: 1.1;
}
.card[href]:hover .card--action:before, .card[href]:focus .card--action:before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1965161064) 72%, rgba(220, 220, 220, 0) 100%);
}
.card[href]:hover .card--image:before, .card[href]:focus .card--image:before {
  opacity: 1;
}

.content_news .card .card--image:before,
.content_card-image .card .card--image:before {
  z-index: 2;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1965161064) 72%, rgba(220, 220, 220, 0) 100%);
  transition: var(--transition);
  opacity: 0.4;
}
.content_news .card:hover img, .content_news .card:focus img,
.content_card-image .card:hover img,
.content_card-image .card:focus img {
  scale: 1.1;
}

.content_hourse .card {
  background-color: #F2F5FA;
  display: flex;
  flex-direction: column;
}
@media (min-width: 769px) {
  .content_hourse .card {
    height: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.content_hourse .card--image {
  aspect-ratio: 1/1;
}
@media (min-width: 769px) {
  .content_hourse .card--image {
    aspect-ratio: 464/575;
  }
}
.content_hourse .card--content {
  padding: var(--g-24);
}
.content_hourse .card--title {
  -webkit-line-clamp: 2;
  font-size: 32px;
}
.content_hourse .card--subtitle {
  color: var(--c-text-subdued);
  font-size: var(--fs-16);
}
.content_hourse .card--text {
  font-size: var(--fs-20);
  padding-bottom: var(--g-24);
}
@media (min-width: 769px) {
  .content_hourse .card--text {
    border-bottom: 1px solid rgba(17, 24, 32, 0.4);
  }
}
.content_hourse .card--text p {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.content_hourse .card--info {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (max-width: 768px) {
  .content_hourse .card--info {
    display: none;
  }
}
.content_hourse .card--info > * {
  display: flex;
  align-items: baseline;
}
.content_hourse .card--info > *:before {
  content: "";
  border-bottom: 2px dotted #cccccc;
  flex-grow: 1;
  order: 1;
  margin: 0 5px;
  min-width: 20px;
}
.content_hourse .card--info > * p:first-child {
  color: var(--c-text-subdued);
}
.content_hourse .card--info > * p + p {
  order: 2;
}
.content_hourse .card--actions .button {
  width: 100%;
}
.content_hourse .card--label {
  position: absolute;
  padding: 16px;
  max-width: 25%;
}
.content_hourse .card .c-accent:before {
  content: "\e917";
}
@media (min-width: 1024px) {
  .content_hourse.block_list:not(block_list-slider) .card {
    grid-template-columns: repeat(2, 1fr);
  }
}

.content_hourse-detail .block--content {
  grid-row: 1/span 2;
}
.content_hourse-detail .card {
  background-color: transparent;
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}
.content_hourse-detail .card--content {
  padding: var(--g-32) var(--g-80);
  border-radius: var(--border-corner);
  background-color: #f2f5fa;
}
.content_hourse-detail .card--label {
  position: static;
  padding: 0;
  max-width: 100%;
}
.content_hourse-detail .card--image {
  max-width: 50%;
  aspect-ratio: 1/1;
}
.content_hourse-detail .card--title {
  font-size: var(--fs-96);
}
.content_hourse-detail .card--title h3 {
  margin-top: 0;
}
.content_hourse-detail .card--subtitle {
  font-size: var(--fs-24);
}
.content_hourse-detail .card--text {
  font-size: var(--fs-32);
  border-bottom: 0;
}
.content_hourse-detail .card--info {
  display: flex;
  gap: var(--g-24);
}
.content_hourse-detail .card--info > * {
  font-size: var(--fs-24);
}
.content_hourse-detail .card--actions {
  gap: 16px;
  display: flex;
}
.content_hourse-detail .card--actions .button {
  width: auto;
  padding: 13px 20px;
}

.content_news .card {
  position: relative;
  aspect-ratio: 360/480;
  cursor: pointer;
  width: 100%;
  height: auto;
  max-height: 533px;
  align-self: start;
}
@media (min-width: 769px) {
  .content_news .card {
    aspect-ratio: 613/656;
  }
}
.content_news .card .card--content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  align-items: end;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: var(--c-text-primary);
  z-index: 3;
  justify-content: flex-end;
}
.content_news .card--title, .content_news .card--label {
  grid-column: span 2;
}
.content_news .card--text {
  color: var(--c-text-primary);
  opacity: 0.8;
}
.content_news .card .card--image {
  width: 100%;
  max-width: 100%;
  height: 100%;
  aspect-ratio: initial;
  position: relative;
}
.content_news .card .card--image:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1965161064) 72%, rgba(220, 220, 220, 0) 100%);
  z-index: 2;
  pointer-events: none;
}
.content_news .card .card--actions {
  grid-column: 2;
}

.content_offers .card {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .content_offers .card {
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 998px) {
  .content_offers .card {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .content_offers .card--content {
    gap: 0;
  }
}
.content_offers .card--title {
  grid-area: title;
  padding-bottom: var(--g-24);
  border-bottom: 1px solid rgba(17, 24, 32, 0.4);
}
.content_offers .card--title h3 {
  -webkit-line-clamp: 2;
}
@media (max-width: 768px) {
  .content_offers .card--title {
    line-height: 1.5;
  }
}
.content_offers .card--label {
  margin-bottom: 8px;
}
.content_offers .card--text {
  padding: 16px 0;
  font-size: clamp(14px, 14px + 6 * (100vw - 1024px) / 896, 20px);
  line-height: 1.4;
}
.content_offers .card--text p {
  -webkit-line-clamp: 4;
}
.content_offers .card--actions {
  margin-top: auto;
}
.content_offers .card--image {
  aspect-ratio: 320/240;
}
@media (min-width: 998px) {
  .content_offers .card--image {
    aspect-ratio: initial;
  }
}

.content_events .card,
.content_events-calendar .card {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 1140px) {
  .content_events .card,
  .content_events-calendar .card {
    grid-template-columns: minmax(250px, 300px) 1fr;
  }
}
.content_events .card--image,
.content_events-calendar .card--image {
  aspect-ratio: 320/220;
}
@media (min-width: 1024px) {
  .content_events .card--image,
  .content_events-calendar .card--image {
    aspect-ratio: 1/1;
  }
}
.content_events .card--content,
.content_events-calendar .card--content {
  display: grid;
  grid-template-columns: 1fr max-content;
}
.content_events .card--subtitle,
.content_events-calendar .card--subtitle {
  grid-column: span 2;
  color: var(--accent-2);
  font-size: var(--fs-28);
  display: flex;
  gap: 6px;
}
.content_events .card--subtitle span:last-of-type,
.content_events-calendar .card--subtitle span:last-of-type {
  opacity: 0.7;
}
.content_events .card--title,
.content_events-calendar .card--title {
  grid-column: span 2;
}
.content_events .card--actions,
.content_events-calendar .card--actions {
  grid-column: 2;
  margin-top: auto;
}
@media (max-width: 1024px) {
  .content_events .card--actions,
  .content_events-calendar .card--actions {
    margin-left: auto;
  }
}
.content_events .card--text,
.content_events-calendar .card--text {
  grid-column: 1;
}
.content_events .card--label,
.content_events-calendar .card--label {
  margin-bottom: auto;
  grid-column: 1;
}

@media (min-width: 1140px) {
  body:not(.page-calendar) .content_events .card:first-of-type {
    display: block;
  }
}
@media (min-width: 1140px) {
  body:not(.page-calendar) .content_events .card:first-of-type .card--image {
    aspect-ratio: initial;
    height: 100%;
    position: absolute;
    inset: 0;
  }
}
@media (min-width: 1140px) {
  body:not(.page-calendar) .content_events .card:first-of-type .card--content {
    z-index: 2;
    position: absolute;
    top: auto;
    right: 27%;
    left: 8px;
    bottom: 8px;
    height: auto;
    background-color: var(--c-surface-default);
    border-radius: var(--border-corner);
  }
}
@media (min-width: 1140px) {
  body:not(.page-calendar) .content_events .card:first-of-type .card--content .card--label span {
    background-color: var(--c-bck-default);
  }
}
@media (min-width: 1141px) {
  body:not(.page-calendar) .content_events .card:not(:nth-child(-n+1)) .card--image {
    aspect-ratio: 16/14;
  }
}
@media (min-width: 1141px) {
  body:not(.page-calendar) .content_events .card:not(:nth-child(-n+2)) {
    margin-top: var(--g-20);
  }
}

.content_events-calendar .card {
  display: flex;
  flex-direction: column;
}
.content_events-calendar .card--image {
  aspect-ratio: 453/368;
}
.content_events-calendar .card--content {
  grid-template-rows: max-content;
}

.content_card-image .card {
  aspect-ratio: 1/1;
  height: max-content;
}
.content_card-image .card--content {
  z-index: 2;
  position: absolute;
  top: auto;
  left: 16px;
  right: 16px;
  bottom: 16px;
  color: var(--c-surface-default);
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0;
  height: auto;
}
.content_card-image .card--title, .content_card-image .card--text {
  grid-column: 1;
}
.content_card-image .card--actions {
  grid-column: 2;
  margin-top: auto;
}
.content_card-image a.card .card--image:after,
.content_card-image .card[data-popup_cascade] .card--image:after {
  position: absolute;
  z-index: 2;
  inset: 0;
  content: "";
  pointer-events: none;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1965161064) 40%, rgba(220, 220, 220, 0) 100%);
}

.content_advantages .card {
  overflow: hidden;
}
.content_advantages .card--content {
  gap: clamp(48px, 48px + 62 * (100vw - 768px) / 1152, 110px);
  height: 100%;
  justify-content: space-between;
  transition: var(--transition);
}
.content_advantages .card--title {
  font-size: var(--fs-52);
  text-transform: uppercase;
  transition: var(--transition);
}
.content_advantages .card--text {
  font-size: var(--fs-24);
  -webkit-line-clamp: 20;
}
.content_advantages .card--text p {
  -webkit-line-clamp: 20;
}
.content_advantages .card:hover .card--title {
  color: var(--c-surface-default);
}
.content_advantages .card:hover .card--content {
  color: var(--c-text-primary);
  background-color: var(--accent);
}

.content_advantages:not(.block_list-slider) .card {
  overflow: hidden;
}
@media (min-width: 768px) {
  .content_advantages:not(.block_list-slider) .card {
    flex: 25% 1 1;
  }
}

.content_partners.content_card-image .card--image:before {
  content: none;
}

.content_history .card {
  gap: 24px;
  border-radius: 0;
  background-color: transparent;
}
.content_history .card--pin {
  display: flex;
  align-items: center;
  gap: 6px;
}
.content_history .card--pin .pin {
  width: 32px;
  height: 32px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath d='M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z' fill='white'/%3E%3Cpath d='M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16Z' fill='%231654CE'/%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  scale: 0.7;
  transition: var(--transition);
}
@media (min-width: 768px) {
  .content_history .card--pin .pin {
    width: 48px;
    height: 48px;
  }
}
.content_history .card--pin .line {
  width: 100%;
  height: 4px;
  background-image: linear-gradient(to right, #fff 55%, transparent 55%);
  background-size: 32px 4px;
  background-repeat: repeat-x;
  position: relative;
}
.content_history .card--content {
  margin-right: var(--g-56);
  padding: 0;
  height: auto;
}
.content_history .card--title p {
  font-size: var(--fs-52);
}
.content_history .card--text, .content_history .card--text p {
  font-size: var(--fs-20);
  -webkit-line-clamp: initial;
}
.content_history .card--image {
  width: auto;
  margin-right: var(--g-56);
  aspect-ratio: 560/360;
}
.content_history .card:last-of-type .line {
  display: none;
}
.content_history .card.swiper-slide-active .pin {
  scale: 1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'%3E%3Ccircle cx='24' cy='24' r='24' fill='white'/%3E%3Ccircle cx='24' cy='24' r='15' fill='%23FC563B'/%3E%3C/svg%3E");
}

.content_news .button:before,
.content_card-image .button:before,
.content_events .button:before,
.content_events-calendar .button:before {
  transition: var(--transition);
}

.content_advantages:not(.block_list-slider) .block--elements {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .content_advantages:not(.block_list-slider) .block--elements {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.content_advantages:not(.block_list-slider) .block--pagination {
  grid-column: span 1;
}
@media (min-width: 640px) {
  .content_advantages:not(.block_list-slider) .block--pagination {
    grid-column: span 3;
  }
}

.content_advantages-2:not(.block_list-slider) .block--elements {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 769px) {
  .content_advantages-2:not(.block_list-slider) .block--elements {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
  }
}
.content_advantages-2 .card {
  overflow: hidden;
}
@media (min-width: 768px) {
  .content_advantages-2 .card {
    min-height: 400px;
  }
}
.content_advantages-2 .card--image {
  height: 100%;
  position: absolute;
  translate: 0 100%;
  transition: 0.2s linear;
}
.content_advantages-2 .card .image-front {
  height: 100%;
  object-fit: cover;
  translate: 0 150%;
  transition: 0.2s linear;
  position: absolute;
  inset: 0;
  z-index: 3;
}
.content_advantages-2 .card--content {
  gap: clamp(48px, 48px + 62 * (100vw - 768px) / 1152, 110px);
  height: 100%;
  justify-content: space-between;
}
.content_advantages-2 .card--title {
  transition: 0.2s linear;
  text-transform: uppercase;
  z-index: 2;
  font-size: clamp(32px, 32px + 40 * (100vw - 1024px) / 896, 72px);
}
.content_advantages-2 .card--text {
  font-size: var(--fs-24);
  color: #404B57;
}
.content_advantages-2 .card:nth-child(1) {
  grid-column: 1/14;
}
.content_advantages-2 .card:nth-child(2) {
  grid-column: 14/25;
}
.content_advantages-2 .card:nth-child(3) {
  grid-column: 1/12;
}
.content_advantages-2 .card:nth-child(4) {
  grid-column: 12/25;
}
.content_advantages-2 .card:hover .card--image,
.content_advantages-2 .card:hover .image-front {
  translate: 0 0;
}
.content_advantages-2 .card:hover .card--title {
  color: var(--c-text-primary);
}

.content_contacts {
  background-color: var(--bg-color, #052360);
  color: var(--text-color);
}
.content_contacts .block--wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 769px) {
  .content_contacts .block--wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.content_contacts .block--wrapper .block--content {
  border-radius: var(--border-corner);
  margin-top: 0;
  padding: var(--g-32);
  color: var(--c-surface-dark);
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: var(--g-content);
}
.content_contacts .block--wrapper .block--map {
  height: 100%;
  border-radius: var(--border-corner);
}
@media (max-width: 768px) {
  .content_contacts .block--wrapper .block--map {
    aspect-ratio: 1/1;
  }
}
.content_contacts .block--wrapper .block--map-container {
  position: sticky;
  top: 0;
  aspect-ratio: 1/1;
}

.contact {
  width: 100%;
  max-width: 560px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 16px;
}
.contact--item {
  display: flex;
  flex-direction: column;
  gap: var(--g-20);
}
.contact--title {
  width: max-content;
  font-weight: 500;
  font-size: var(--fs-32);
  text-transform: uppercase;
}
.contact--value {
  opacity: 0.9;
}
.contact--value a {
  text-decoration: underline;
}
.contact .button {
  grid-column: span 2;
  font-weight: 500;
}
.contact--item.adress {
  grid-column: span 2;
}
.contact--value a {
  white-space: nowrap;
}

.content_offers:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 640px) {
  .content_offers:not(.block_list-slider) .block--elements {
    grid-template-columns: 1fr;
  }
}
.content_offers:not(.block_list-slider) .block--elements .block--pagination {
  grid-column: span 1;
}
@media (min-width: 640px) {
  .content_offers:not(.block_list-slider) .block--elements .block--pagination {
    grid-column: span 2;
  }
}

body:not(.page-calendar) .content_events {
  overflow: hidden;
}
body:not(.page-calendar) .content_events:not(.block_list-slider) .block--elements {
  gap: 0 var(--g-20);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}
@media (max-width: 1140px) {
  body:not(.page-calendar) .content_events:not(.block_list-slider) .block--elements {
    gap: var(--g-8) var(--g-20);
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  body:not(.page-calendar) .content_events:not(.block_list-slider) .block--elements .card:first-of-type {
    grid-column: 1;
    grid-row: 1/4;
  }
}
@media (min-width: 1024px) {
  body:not(.page-calendar) .content_events:not(.block_list-slider) .block--elements .card:not(:first-of-type) {
    grid-column: 2;
  }
}
@media (min-width: 1024px) {
  body:not(.page-calendar) .content_events:not(.block_list-slider) .block--elements .card:nth-child(2) {
    grid-row: 1;
  }
}
@media (min-width: 1024px) {
  body:not(.page-calendar) .content_events:not(.block_list-slider) .block--elements .card:nth-child(3) {
    grid-row: 2;
  }
}
@media (min-width: 1024px) {
  body:not(.page-calendar) .content_events:not(.block_list-slider) .block--elements .card:nth-child(4) {
    grid-row: 3;
  }
}
body:not(.page-calendar) .content_events:not(.block_list-slider) .block--pagination {
  grid-column: span 3;
}
@media (max-width: 640px) {
  body:not(.page-calendar) .content_events:not(.block_list-slider) .block--pagination {
    grid-column: span 1;
  }
}

.content_events-detail.block_image_text .block--image {
  grid-row: 1/span 3;
}

.content_events-detail .block--wrapper {
  padding-bottom: 8px;
}
.content_events-detail .block--head {
  padding-bottom: 0;
}
.content_events-detail .date {
  color: var(--accent-2);
  font-size: var(--fs-32);
  display: flex;
  gap: 6px;
}
.content_events-detail .date span:last-child {
  opacity: 0.7;
}
.content_events-detail .info {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.content_events-detail .info > * {
  display: flex;
  align-items: baseline;
}
.content_events-detail .info > *:before {
  content: "";
  border-bottom: 2px dotted #cccccc;
  flex-grow: 1;
  order: 1;
  margin: 0 5px;
  min-width: 30px;
}
.content_events-detail .info > * p:first-child {
  color: var(--c-text-subdued);
}
.content_events-detail .info > * p + p {
  order: 2;
}

.block_list.content_faq .block--wrapper {
  display: grid;
  grid-template-areas: inherit;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background-color: var(--bg-color, transparent);
}
@media (max-width: 768px) {
  .block_list.content_faq .block--wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }
}
.block_list.content_faq .block--wrapper .block--head {
  border-bottom: none;
  display: flex;
  flex-direction: column;
  grid-column: 1;
}
@media (min-width: 769px) {
  .block_list.content_faq .block--wrapper .block--head {
    padding: 16px var(--g-80);
  }
}
.block_list.content_faq .block--wrapper .block--elements,
.block_list.content_faq .block--wrapper .block--actions {
  grid-column: 2;
}
@media (max-width: 768px) {
  .block_list.content_faq .block--wrapper .block--elements,
  .block_list.content_faq .block--wrapper .block--actions {
    grid-column: 1;
  }
}
.block_list.content_faq .block--wrapper .block--actions {
  padding: 0;
  margin-right: auto;
}
.block_list.content_faq .block--wrapper .block--actions:empty {
  display: none;
}
.block_list.content_faq .block--wrapper .block--elements {
  margin-top: 0;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 0;
}
.block_list.content_faq .block--wrapper .block--elements .accordion {
  background-color: var(--bg-c-content, #f2f5fa);
  max-width: 100%;
}

.content_404 .block--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
.content_404 .block--head {
  align-items: center;
  text-align: center;
  border: none;
  display: flex;
  flex-direction: column;
}
.content_404 .block--head_caption p {
  font-family: "Gilroy";
  font-size: var(--fs-72);
  color: #404B57;
  line-height: 1;
}
.content_404 h1 {
  font-size: clamp(48px, 48px + 48 * (100vw - 1024px) / 896, 96px);
  text-align: center;
}

.block_list.content_form {
  background-color: var(--bg-color, #fff);
}
.block_list.content_form .block--wrapper {
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 768px) {
  .block_list.content_form .block--wrapper {
    flex-direction: column;
    display: flex;
  }
}
.block_list.content_form .block--wrapper .block--head {
  display: flex;
  flex-direction: column;
  border: none;
}
.block_list.content_form .block--wrapper .block--content {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--g-20);
  height: 100%;
}
.block_list.content_form .block--wrapper .block--content,
.block_list.content_form .block--wrapper .block--form {
  background-color: var(--c-surface-subdued);
  padding: var(--g-80);
  border-radius: var(--border-corner);
}

.block_list.content_form.ac-background-2 .block--content,
.block_list.content_form.ac-background-2 .block--form {
  background-color: #fff;
}

.content_reviews.block_list:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 992px) {
  .content_reviews.block_list:not(.block_list-slider) .block--elements {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 992px) and (max-width: 640px) {
  .content_reviews.block_list:not(.block_list-slider) .block--elements {
    grid-template-columns: 1fr;
  }
}
.content_reviews.block_list:not(.block_list-slider) .block--elements .block--pagination {
  grid-column: span 4;
}
@media (max-width: 992px) {
  .content_reviews.block_list:not(.block_list-slider) .block--elements .block--pagination {
    grid-column: span 3;
  }
}
@media (max-width: 640px) {
  .content_reviews.block_list:not(.block_list-slider) .block--elements .block--pagination {
    grid-column: span 1;
  }
}
@media (min-width: 992px) {
  .content_reviews.block_list-slider .card {
    min-width: calc((var(--container) - 48px) / 3);
  }
}

.content_reviews-video.block_list:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 992px) {
  .content_reviews-video.block_list:not(.block_list-slider) .block--elements {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 992px) and (max-width: 640px) {
  .content_reviews-video.block_list:not(.block_list-slider) .block--elements {
    grid-template-columns: 1fr;
  }
}

/* Туры карта и день */
.content_tour_map {
  position: relative;
}
@media (max-width: 768px) {
  .content_tour_map .block--sections_container {
    display: flex;
    overflow: auto;
    max-width: 100%;
  }
  .content_tour_map .block--sections_container button, .content_tour_map .block--sections_container a {
    display: inline-block;
    overflow: hidden;
    width: auto;
    max-width: calc(-35% + 100vw);
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.content_tour_map .map {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: 920px;
  min-height: 70vh;
  border-radius: var(--border-corner);
}
.content_tour_map .map--placemark_content {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #FC563B;
  fill: #FC563B;
}
.content_tour_map .map--placemark_content-svg {
  fill: var(--c-text-accent);
}
.content_tour_map .placemark {
  transition: 0.24s ease-in-out;
}
.content_tour_map .placemark circle {
  r: 42;
}
.content_tour_map .placemark text {
  font-size: 42px;
}
.content_tour_map .placemark[data-active=active] circle {
  fill: #FC563B;
}
.content_tour_map .placemark[data-active=active] .map--placemark_content {
  color: #fff;
  fill: #fff;
}
.content_tour_map .placemark[data-active=not_active] {
  fill: #fff;
}
.content_tour_map .placemark[data-active=not_active] .map--placemark_content {
  color: #FC563B;
  fill: #FC563B;
}
.content_tour_map .block--content {
  position: relative;
}
.content_tour_map .block--sections {
  justify-content: flex-start;
  margin-bottom: var(--g-32);
}
.content_tour_map .ymaps-2-1-79-graphics-SVG svg path {
  stroke: #FC563B;
}

.content_road_map {
  position: relative;
  line-height: 1;
}
.content_road_map .map {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: var(--border-corner);
}
@media (max-width: 768px) {
  .content_road_map .map {
    aspect-ratio: 1/1;
    width: 100%;
    height: auto;
  }
}
.content_road_map .block--content {
  border-radius: var(--border-corner);
  position: relative;
  overflow: hidden;
  background-color: var(--c-surface-subdued);
  height: 100%;
  margin-top: 0;
  padding: var(--g-52) var(--g-content);
}
.content_road_map .block--content .item--title {
  font-size: var(--fs-52);
  font-weight: 500;
  font-family: "Gilroy";
}
.content_road_map .block--elements {
  display: flex;
  flex-direction: column;
  gap: var(--g-8);
}
@media (min-width: 769px) {
  .content_road_map .block--elements {
    display: grid;
    grid-template-columns: 4fr 8fr;
  }
}

.content_animation {
  background-color: var(--accent);
  color: var(--c-text-primary);
}
.content_animation .block--wrapper {
  display: grid;
  grid-template-rows: repeat(4, auto);
  gap: 20px;
}
.content_animation .block--wrapper .animation--row {
  gap: 20px;
  display: flex;
  position: relative;
}
@media (max-width: 768px) {
  .content_animation .block--wrapper .animation--row {
    justify-content: center;
    text-align: center;
  }
}
.content_animation .block--wrapper .animation--row .animation--image {
  position: relative;
}
.content_animation .block--wrapper .animation--row .animation--title {
  text-transform: uppercase;
  line-height: 0.8;
  font-family: "Gilroy";
  font-size: 52px;
}
@media (min-width: 769px) {
  .content_animation .block--wrapper .animation--row .animation--title {
    font-size: 15.36vw;
  }
}
.content_animation .block--wrapper .animation--row-1 {
  grid-row: 1;
  align-items: end;
}
.content_animation .block--wrapper .animation--row-1 .animation--image {
  display: none;
}
@media (min-width: 769px) {
  .content_animation .block--wrapper .animation--row-1 .animation--image {
    display: block;
    height: clamp(200px, 200px + 253 * (100vw - 998px) / 642, 453px);
  }
}
.content_animation .block--wrapper .animation--row-2 {
  grid-row: 2;
}
.content_animation .block--wrapper .animation--row-3 {
  grid-row: 3;
}
.content_animation .block--wrapper .animation--row-3 .animation--image {
  display: none;
}
@media (min-width: 769px) {
  .content_animation .block--wrapper .animation--row-3 .animation--image {
    display: block;
    min-width: 33.13vw;
    position: relative;
  }
  .content_animation .block--wrapper .animation--row-3 .animation--image img {
    background-color: #000;
    height: 41.67vw;
    position: absolute;
  }
}
@media (max-width: 768px) {
  .content_animation .block--wrapper .animation--row-3 .animation--content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
.content_animation .block--wrapper .animation--row-3 .animation--content p {
  font-size: var(--fs-24);
}
@media (min-width: 769px) {
  .content_animation .block--wrapper .animation--row-3 .animation--content p {
    font-size: 2.19vw;
  }
}
.content_animation .block--wrapper .animation--row-4 {
  grid-row: 4;
}
.content_animation .block--wrapper .animation--row-4 .animation--image {
  border-radius: 160px;
}
@media (min-width: 769px) {
  .content_animation .block--wrapper .animation--row-4 .animation--image {
    height: 27.08vw;
    max-height: 80vh;
    margin-left: auto;
    width: 50%;
    position: relative;
  }
}
@media (min-width: 769px) {
  .content_animation .block--wrapper .animation--row-4 .animation--image img {
    left: auto;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.content_animation .block--wrapper .animation--row-4 {
  grid-row: 4;
}
@media (max-width: 768px) {
  .content_animation .block--wrapper .animation--row-4 .animation--image {
    border-radius: 160px;
    overflow: hidden;
  }
}

.content_animation-2 {
  position: relative;
  padding: var(--g-section-inner) 0;
}
.content_animation-2 .block--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 769px) {
  .content_animation-2 .block--wrapper {
    height: calc(100vh - var(--headerHeight, 96px));
  }
}
@media (max-width: 768px) {
  .content_animation-2 .block--wrapper {
    position: relative;
  }
}
.content_animation-2 .head {
  position: relative;
}
.content_animation-2 .title-1,
.content_animation-2 .title-2 {
  text-align: center;
  font-size: 13.54vw;
  font-family: "Gilroy";
  line-height: 1;
  letter-spacing: -2.68px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
}
@media (max-width: 768px) {
  .content_animation-2 .title-1 {
    margin-bottom: 100px;
  }
}
.content_animation-2 .title-2 {
  z-index: 2;
  color: var(--accent-2);
}
.content_animation-2 .subtitle {
  text-align: center;
  opacity: 0.7;
  font-size: var(--fs-32);
  z-index: 5;
}
@media (min-width: 769px) {
  .content_animation-2 .subtitle {
    font-size: 1.67vw;
  }
}
.content_animation-2 .img-front {
  z-index: 3;
}
.content_animation-2 .img-back {
  z-index: 1;
}
.content_animation-2 img {
  translate: -50% -50%;
  left: 50%;
  position: absolute;
  top: 50%;
  height: auto;
  width: 50%;
  aspect-ratio: 618/764;
}
@media (min-width: 769px) {
  .content_animation-2 img {
    width: 32.29vw;
  }
}

.content_animation-3 {
  overflow: hidden;
}
.content_animation-3 .block--wrapper {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 769px) {
  .content_animation-3 .block--wrapper {
    min-height: 70vh;
  }
}
.content_animation-3 .animation--title {
  font-size: 10.5vw;
  font-weight: 500;
  font-family: "Gilroy";
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  transition: 0.7s ease-in-out;
}
.content_animation-3 .animation--row:last-of-type .animation--title {
  color: #FBB10D;
}
.content_animation-3 .animation--row-1 .animation--title {
  translate: -120% 0;
}
.content_animation-3 .animation--row-2 .animation--title {
  translate: 120% 0;
}

.content_animation-3.is_playing .animation--row-1 .animation--title {
  translate: 0 0;
}
.content_animation-3.is_playing .animation--row-2 .animation--title {
  translate: 0 0;
}

.content_animation-4 {
  background-color: var(--accent);
  color: var(--c-text-primary);
  overflow: hidden;
}
@media (min-width: 769px) {
  .content_animation-4 {
    height: calc(100vh - var(--headerHeight, 96px));
  }
}
.content_animation-4 .block--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 16px;
  position: relative;
}
.content_animation-4 .animation--subscription {
  display: none;
  text-transform: uppercase;
}
@media (min-width: 769px) {
  .content_animation-4 .animation--subscription {
    display: block;
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 5.2vw;
  }
}
@media (min-width: 769px) {
  .content_animation-4 .animation--row-1,
  .content_animation-4 .animation--row-3 {
    z-index: -1;
    position: relative;
  }
}
.content_animation-4 .animation--title {
  text-transform: uppercase;
  line-height: 0.8;
  font-family: "Gilroy";
  font-size: 52px;
  text-align: center;
  position: relative;
}
@media (min-width: 1025px) {
  .content_animation-4 .animation--title {
    font-size: 6.771vw;
  }
}
@media (min-width: 769px) {
  .content_animation-4 .animation--image {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 30px;
  }
  .content_animation-4 .animation--image .img {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    aspect-ratio: 970/580;
    display: flex;
  }
  .content_animation-4 .animation--image .img-2 {
    z-index: 3;
  }
  .content_animation-4 .animation--image .img-2 img {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: 3;
  }
}
@media (max-width: 768px) {
  .content_animation-4 .animation--image .img {
    flex-shrink: 0;
    flex-grow: 0;
    max-height: 51.2vw;
  }
}

.content_basic:not(.block_list-slider) .block--elements {
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 640px) {
  .content_basic:not(.block_list-slider) .block--elements {
    grid-template-columns: 1fr;
  }
}
.content_basic:not(.block_list-slider) .block--pagination {
  grid-column: span 3;
}
@media (max-width: 640px) {
  .content_basic:not(.block_list-slider) .block--pagination {
    grid-column: span 1;
  }
}

.content_hourse:not(.block_list-slider) .block--elements {
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 1024px) {
  .content_hourse:not(.block_list-slider) .block--elements {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .content_hourse:not(.block_list-slider) .block--elements .block--pagination {
    grid-column: span 2;
  }
}

.block_image_text.content_hourse-detail .block--wrapper {
  padding-bottom: 8px;
}
.block_image_text.content_hourse-detail .block--image ~ .block--head,
.block_image_text.content_hourse-detail .block--image ~ .block--content {
  padding-left: 0;
  padding-right: 0;
}

.content_services {
  color: var(--c-text-primary);
  background-color: var(--bg-color, #093282);
}
.content_services .block--head {
  border-bottom: 1px solid var(--c-border-subdued);
}
.content_services .block--head .block--head_caption {
  text-align: right;
}
@media (max-width: 768px) {
  .content_services .block--head .block--head_caption {
    text-align: center;
    order: -1;
  }
}
.content_services .block--head .block--head_caption img {
  max-width: 256px;
}
@media (max-width: 768px) {
  .content_services .block--head .block--head_caption img {
    max-width: 160px;
    margin-bottom: 16px;
  }
}
.content_services .slider_controls:after {
  background: linear-gradient(90deg, rgba(255, 254, 254, 0) 0%, var(--accent) 15%);
}
.content_services .slider_controls .button {
  --outlined-bc-base: #e0e7f3;
  --outlined-c-base: #e0e7f3;
  --outlined-bc-hover: #e0e7f3;
  --outlined-bgc-hover: #e0e7f3;
  --outlined-c-hover: #111820;
}
.content_services .item {
  display: flex;
  flex-direction: column;
  gap: var(--g-8);
}
@media (min-width: 769px) {
  .content_services .item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.content_services .item--image {
  border-radius: var(--border-corner);
  overflow: hidden;
  display: flex;
}
.content_services .item--image img, .content_services .item--image video {
  aspect-ratio: 950/640;
  width: 100%;
  object-fit: cover;
}
.content_services .item--content {
  padding: var(--g-content) var(--g-section-inner);
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: var(--border-corner);
  color: var(--c-text-default);
  background-color: var(--c-surface-default);
}
.content_services .item .is_cascade p {
  font-size: var(--fs-20);
  opacity: 0.7;
}
.content_services .item--actions {
  margin-top: 0;
}
@media (min-width: 769px) {
  .content_services .item--actions {
    margin-top: 8px;
  }
}
.content_services .item--title {
  text-transform: uppercase;
  font-size: var(--fs-52);
}
.content_services .item--text {
  font-size: var(--fs-24);
}
.content_services .item--links {
  display: none;
}
@media (min-width: 769px) {
  .content_services .item--links {
    margin-top: var(--g-32);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--g-8);
  }
  .content_services .item--links .button {
    justify-content: space-between;
    width: 100%;
  }
  .content_services .item--links .button span:not([class^=icon-]) {
    white-space: initial;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.content_services .block--sections .tab {
  color: var(--c-text-primary);
}
.content_services .block--sections .tab:hover, .content_services .block--sections .tab:focus {
  color: var(--c-text-disabled);
}
.content_services .block--sections .tab.is_active {
  color: var(--c-text-primary);
}

.content_subscription {
  background-color: var(--c-bck-dark-blue);
  color: var(--c-surface-default);
  padding: var(--g-content) 0;
}
.content_subscription .block--wrapper {
  display: flex;
  align-items: center;
  gap: var(--g-20);
  max-width: 1080px;
  flex-direction: column;
}
.content_subscription:last-child {
  margin: 0;
}
.content_subscription .block--head {
  border-bottom: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content_subscription .block--head_subtitle {
  color: var(--accent-2);
}
.content_subscription .block--head_title {
  text-align: center;
}
.content_subscription .block--head_caption {
  color: #CDD9EC;
}
.content_subscription fieldset {
  display: flex;
  gap: 8px;
}
@media (max-width: 768px) {
  .content_subscription fieldset {
    flex-direction: column;
  }
  .content_subscription fieldset .form__input,
  .content_subscription fieldset .form__button,
  .content_subscription fieldset .form__button .button {
    width: 100%;
  }
}
.content_subscription fieldset .form__input {
  flex-grow: 1;
}

.form--subscription {
  width: 100%;
}
.form--subscription fieldset {
  display: flex;
  gap: var(--g-24);
  align-items: center;
}
@media (max-width: 1240px) {
  .form--subscription fieldset {
    flex-direction: column;
  }
}
.form--subscription fieldset .form--input {
  width: 100%;
  border: none;
  align-items: center;
}
@media (max-width: 768px) {
  .form--subscription fieldset .form--input {
    flex-direction: column;
  }
}
.form--subscription fieldset .form--input input {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  color: var(--c-text-primary);
}
@media (max-width: 768px) {
  .form--subscription fieldset .form--input input {
    width: 100%;
  }
}
.form--subscription fieldset .form--input > span {
  color: #FFFFFF;
}
.form--subscription .form--button {
  position: relative;
  margin-top: 0;
}
@media (max-width: 768px) {
  .form--subscription .form--button {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  .form--subscription .form--button .button {
    width: 100%;
  }
}
.form--subscription .confirmation {
  max-width: 100%;
  margin: auto;
}
@media (min-width: 769px) {
  .form--subscription .confirmation {
    width: 100%;
    position: absolute;
    left: 110%;
    top: 0;
    bottom: 0;
    right: -20%;
    display: flex;
    align-items: center;
  }
}
.form--subscription .confirmation .access {
  color: #FFFFFF;
}

.content_table:not(.block_list-slider) .block--elements {
  grid-template-columns: 1fr;
}
.content_table:not(.block_list-slider) .block--pagination {
  grid-column: span 3;
}
@media (max-width: 640px) {
  .content_table:not(.block_list-slider) .block--pagination {
    grid-column: span 1;
  }
}
.content_table:not(.block_list-slider) .is_cascade--table {
  margin-top: 0;
}

.content_gallery-action .block--wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--g-8);
}
@media (max-width: 768px) {
  .content_gallery-action .block--wrapper {
    display: flex;
    flex-direction: column;
  }
}
.content_gallery-action .block--wrapper .block--content {
  margin-top: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--g-8);
}
@media (max-width: 768px) {
  .content_gallery-action .block--wrapper .block--content {
    display: flex;
    flex-direction: column;
  }
}
.content_gallery-action .block--wrapper .block--content .item {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-corner);
  padding: var(--g-24);
  background-color: var(--accent);
  color: var(--c-text-primary);
}
.content_gallery-action .block--wrapper .block--content .item .button {
  margin-top: auto;
  margin-left: auto;
  padding: 9px;
}
@media (min-width: 769px) {
  .content_gallery-action .block--wrapper .block--content .item .button {
    margin-top: var(--g-56);
    padding: 13px;
  }
}
.content_gallery-action .block--wrapper .block--content .item .button:before {
  transition: var(--transition);
}
.content_gallery-action .block--wrapper .block--content .item:first-of-type {
  padding: var(--g-56);
  grid-column: span 2;
}
.content_gallery-action .block--wrapper .block--content .item:nth-child(2) {
  grid-column: 1;
}
.content_gallery-action .block--wrapper .block--content .item:last-of-type {
  grid-column: 2;
}
.content_gallery-action .block--wrapper .block--content .item--title {
  text-transform: uppercase;
  font-family: "Gilroy";
  font-size: var(--fs-96);
}
.content_gallery-action .block--wrapper .block--content .item--text {
  font-size: var(--fs-32);
}
.content_gallery-action .block--wrapper .block--image {
  border-radius: var(--border-corner);
  overflow: hidden;
}
@media (min-width: 769px) {
  .content_gallery-action .slider_controls .swiper-pagination {
    margin-left: 0;
    margin-right: auto;
  }
}
.content_gallery-action .slider_controls .button {
  padding: 13px;
}

.content_infrastructure {
  background-color: var(--accent);
  color: var(--c-surface-default);
}
.content_infrastructure .slider_controls .button {
  --outlined-bc-base: #e0e7f3;
  --outlined-c-base: #e0e7f3;
  --outlined-bc-hover: #e0e7f3;
  --outlined-bgc-hover: #e0e7f3;
  --outlined-c-hover: #111820;
}
.content_infrastructure .slider_controls:after {
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 254, 254, 0)), color-stop(15%, var(--accent)));
  background: linear-gradient(90deg, rgba(255, 254, 254, 0) 0%, var(--accent) 15%);
}
.content_infrastructure .block--sections button {
  color: var(--c-text-primary);
}
.content_infrastructure .block--sections button:hover {
  color: var(--c-text-disabled);
}
.content_infrastructure .infrastructure {
  position: relative;
  gap: var(--g-8);
}
@media (min-width: 769px) {
  .content_infrastructure .infrastructure {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
  }
}
.content_infrastructure .infrastructure--image {
  border-radius: var(--border-corner);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1300/612;
  background-position: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .content_infrastructure .infrastructure--image {
    overflow-x: auto;
    width: auto;
    max-width: 100%;
    height: 600px;
    display: inherit;
  }
  .content_infrastructure .infrastructure--image svg {
    height: 600px;
  }
}
.content_infrastructure .infrastructure .placemark {
  opacity: 0.7;
}
.content_infrastructure .infrastructure .placemark circle {
  transition: var(--transition);
}
.content_infrastructure .infrastructure .placemark:hover {
  opacity: 0.9;
}
.content_infrastructure .infrastructure .placemark:hover circle:not(.pulse) {
  r: 30;
}
.content_infrastructure .infrastructure .placemark.is_active {
  opacity: 1;
}
.content_infrastructure .infrastructure .placemark.is_active .pulse {
  animation: pulse 2s infinite;
  transform-origin: center;
}
.content_infrastructure .infrastructure .placemark.is_active circle:not(.pulse) {
  r: 30;
}
@keyframes pulse {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0;
    stroke-width: 28px;
  }
  100% {
    opacity: 0;
    stroke-width: 28px;
  }
}
.content_infrastructure .infrastructure .placemark text {
  fill: #FC563B;
  font-size: 32px;
  translate: 0 12px;
  text-anchor: middle;
}
.content_infrastructure .infrastructure svg {
  border-radius: var(--border-corner);
}

.content_history {
  overflow: hidden;
  background-color: var(--accent);
  color: var(--c-surface-default);
}
.content_history .block--elements {
  height: max-content;
}
.content_history .slider_controls .button {
  --outlined-bc-base: #e0e7f3;
  --outlined-c-base: #e0e7f3;
  --outlined-bc-hover: #e0e7f3;
  --outlined-bgc-hover: #e0e7f3;
  --outlined-c-hover: #111820;
}
.content_history .slider_controls .swiper-pagination-progressbar {
  border-color: #FFFFFF;
}
.content_history .slider_controls .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #FFFFFF;
}
.content_history .block--sections button {
  color: var(--c-text-primary);
}
.content_history .block--sections button:hover {
  color: var(--c-text-disabled);
}

article .block--wrapper {
  max-width: 1600px;
  margin: 0 auto;
}
article .block--content {
  display: grid;
  grid-template-columns: 9fr auto;
  grid-template-areas: "label side" "cascade side";
}
@media (max-width: 998px) {
  article .block--content {
    grid-template-areas: "side side" "label label" "cascade cascade";
    gap: 16px 0;
  }
}
article .is_cascade {
  grid-area: cascade;
  max-width: 1024px;
  width: 100%;
  margin: auto;
}
article .block--head {
  display: flex;
  flex-direction: column;
  border-bottom: none;
}
article .block--head_title {
  max-width: initial;
}
article .block--article_label {
  grid-area: label;
  display: flex;
  gap: 16px;
}
article .block--article_label > div {
  display: flex;
  gap: 16px;
}
article .block--article_label > div > div {
  display: flex;
  align-items: center;
  gap: 6px;
}
article .block--article_label > div:first-of-type span {
  padding: 8px;
  background-color: var(--c-surface-subdued);
  border-radius: 8px;
}

.is_dropdown {
  position: relative;
  overflow: visible;
}
.is_dropdown.hidden {
  display: none;
}
.is_dropdown .icon-chevron-down {
  transform: scaleY(1);
  transform-origin: center;
  transition: 0.18s transform ease-in-out;
}
@media (min-width: 1024px) {
  .is_dropdown:hover .icon-chevron-down, .is_dropdown:focus .icon-chevron-down {
    transform: scaleY(-1);
  }
  .is_dropdown:hover .is_dropdown--wrapper, .is_dropdown:focus .is_dropdown--wrapper {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
}
.is_dropdown.is_open .icon-chevron-down {
  transform: scaleY(-1);
}
.is_dropdown.is_open .is_dropdown--wrapper {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
  z-index: 1;
}

.is_dropdown--wrapper {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: fit-content;
  min-width: 100%;
  padding-top: 24px;
}
@media (min-width: 992px) {
  .is_dropdown--wrapper {
    transition: opacity 0.13s linear, transform 0.18s linear;
    transform: translateY(-48px);
  }
}

.is_dropdown--content {
  background-color: var(--c-surface-default);
  color: var(--c-text-default);
  padding: 20px;
  border: 1px solid var(--others-divider, #E0E1E3);
  border-radius: var(--border-corner);
  --hover-color: var(--c-text-disabled);
}
@media (min-width: 992px) {
  .is_dropdown--content {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }
}

.is_dropdown-events .events {
  display: flex;
  gap: 16px;
}
.is_dropdown-events .events--item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: var(--fs-28);
}
.is_dropdown-events .events--item .item--text-link,
.is_dropdown-events .events--item .item--image-link {
  height: 100%;
  border-radius: var(--border-corner);
  overflow: hidden;
  padding: 20px;
  background-color: var(--c-surface-subdued);
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.is_dropdown-events .events--item .item--text-link .title,
.is_dropdown-events .events--item .item--image-link .title {
  z-index: 2;
  margin-bottom: auto;
}
.is_dropdown-events .events--item .item--text-link .button,
.is_dropdown-events .events--item .item--image-link .button {
  margin-top: auto;
  position: relative;
  right: -10px;
  bottom: -10px;
  padding: 13px;
}
.is_dropdown-events .events--item .item--text-link .button:before,
.is_dropdown-events .events--item .item--image-link .button:before {
  transition: 0.15s linear;
}
.is_dropdown-events .events--item .item--text-link .title {
  display: flex;
  align-items: center;
}
.is_dropdown-events .events--item .item--text-link .title [class^=icon-], .is_dropdown-events .events--item .item--text-link .title [class*=" icon-"] {
  color: var(--accent);
}
.is_dropdown-events .events--item .item--image-link {
  position: relative;
  color: var(--c-surface-default);
  aspect-ratio: 360/250;
}
.is_dropdown-events .events--item .item--image-link img {
  filter: brightness(70%);
  position: absolute;
  inset: 0;
}
.is_dropdown-events .events--title {
  font-size: var(--fs-32);
  font-family: "Gilroy";
}

.showMore .is_dropdown {
  display: block;
  padding: 0;
}
.showMore .is_dropdown .is_dropdown--wrapper {
  display: none;
}
.showMore .nav--item {
  padding: 0;
}
.showMore .nav--item .button span[class^=icon-] {
  display: none;
}

.sidebar {
  display: block;
  grid-area: side;
  min-height: 100%;
  align-self: stretch;
  flex-shrink: 0;
  flex-grow: 0;
  height: 100%;
  padding-top: 0;
  min-height: 100%;
  align-self: stretch;
  flex-shrink: 0;
  flex-grow: 0;
}
@media (min-width: 998px) {
  .sidebar {
    margin-left: var(--g-32);
  }
}
@media (max-width: 998px) {
  .sidebar {
    margin-right: -8px;
  }
}
.sidebar--sticky {
  padding: var(--g-24) var(--g-32);
  background-color: var(--c-surface-subdued);
  border-radius: var(--border-corner);
  position: sticky;
  top: calc(var(--headerHeight, 137px) + var(--g-32));
}
@media (max-width: 998px) {
  .sidebar--sticky {
    max-width: 100vw;
    overflow-x: auto;
    position: static;
    overflow-y: hidden;
    padding-right: 0;
  }
}
.sidebar--sticky strong {
  margin-bottom: var(--g-24);
  font-weight: 500;
  font-family: "Gilroy";
  font-size: var(--fs-32);
  text-transform: uppercase;
  display: block;
}
@media (max-width: 998px) {
  .sidebar--nav {
    display: flex;
    overflow-x: auto;
  }
}
.sidebar--nav_item {
  display: block;
  padding: var(--g-24) var(--g-20);
}
@media (max-width: 998px) {
  .sidebar--nav_item {
    white-space: nowrap;
    padding: 8px 12px;
  }
}
.sidebar--nav_item.is_active {
  background-color: var(--c-surface-default);
  border-radius: var(--border-corner);
}

.content_scheme {
  overflow: hidden;
}
.content_scheme .scheme {
  position: relative;
  margin-top: var(--g-section-inner);
  overflow: hidden;
  border-radius: var(--border-corner);
  max-height: calc(100vh - var(--headerHeight, 96px) * 2);
  background-image: url("/local/templates/main/assets/img/logo/logo.svg");
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
}
.content_scheme .scheme--container {
  height: 100%;
  width: 100%;
  aspect-ratio: 1880/960;
}
.content_scheme .scheme:after, .content_scheme .scheme:before {
  content: "";
  inset: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  transition: var(--transition);
}
.content_scheme .scheme:after {
  opacity: 0;
  background: linear-gradient(0deg, rgba(0, 31, 58, 0) 70%, rgba(0, 31, 58, 0.650297619) 100%);
}
@media (max-width: 768px) {
  .content_scheme .scheme:after {
    background: linear-gradient(0deg, rgba(0, 31, 58, 0.650297619) 0%, rgba(0, 31, 58, 0) 35%);
  }
}
.content_scheme .scheme:before {
  opacity: 1;
  background: linear-gradient(0deg, rgba(0, 31, 58, 0.650297619) 0%, rgba(0, 31, 58, 0) 35%);
}
@media (max-width: 768px) {
  .content_scheme .scheme:before {
    background: linear-gradient(0deg, rgba(0, 31, 58, 0.5) 0%, rgba(0, 31, 58, 0) 100%);
  }
}
.content_scheme .scheme--actions {
  position: absolute;
  top: var(--g-32);
  left: var(--g-32);
  display: flex;
  gap: 8px;
  z-index: 2;
}
@media (max-width: 768px) {
  .content_scheme .scheme--actions {
    top: auto;
    bottom: var(--g-32);
    left: 10px;
    right: 10px;
  }
}
.content_scheme .scheme--action {
  position: relative;
}
@media (max-width: 768px) {
  .content_scheme .scheme--action {
    width: 100%;
  }
}
.content_scheme .scheme--action_list {
  position: absolute;
  z-index: 3;
  width: auto;
  min-width: 100%;
  height: fit-content;
  pointer-events: none;
  opacity: 0;
  transition: var(--transition);
  background-color: #FFFFFF;
  padding: var(--g-24);
  border-radius: var(--border-corner);
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 90vw;
  width: max-content;
}
@media (max-width: 768px) {
  .content_scheme .scheme--action_list {
    transform: translateY(8px);
    bottom: calc(100% + 8px);
    padding: 12px 8px;
  }
}
.content_scheme .scheme--action_list .item {
  cursor: pointer;
  display: flex;
  gap: 10px;
  transition: var(--transition);
  border-radius: 8px;
  align-items: center;
}
.content_scheme .scheme--action_list .item p {
  font-size: var(--fs-20);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.content_scheme .scheme--action_list .item span:last-child {
  font-size: 14px;
  opacity: 0.7;
}
@media (max-width: 768px) {
  .content_scheme .scheme--action_list .item span:last-child {
    font-size: 11px;
  }
}
.content_scheme .scheme--action_list .item:hover .custom-checkbox {
  border-color: #144FC3;
}
.content_scheme .scheme--action_list .item.is_active .custom-checkbox:after {
  opacity: 1;
}
.content_scheme .scheme--action_list .custom-checkbox {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid #000000;
  border-radius: 50px;
  display: inline-block;
  position: relative;
}
.content_scheme .scheme--action_list .custom-checkbox:after {
  content: "";
  position: absolute;
  inset: 3px;
  background-color: #144FC3;
  border-radius: 50px;
  opacity: 0;
}
.content_scheme .scheme--action .button {
  background-color: #FFFFFF;
  padding: 10px 12px;
  border-radius: var(--border-corner);
}
@media (max-width: 768px) {
  .content_scheme .scheme--action .button {
    width: 100%;
    justify-content: center;
  }
}
.content_scheme .scheme--action .button span:first-of-type {
  font-size: 1.3rem;
}
.content_scheme .scheme--action.is_opened .button {
  color: #144FC3;
}
.content_scheme .scheme--action.is_opened .button .icon-chevron-down {
  rotate: 180deg;
}
.content_scheme .scheme--action.is_opened .scheme--action_list {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}
.content_scheme .scheme--action-road {
  display: flex;
  gap: 10px;
  align-items: center;
}
.content_scheme .scheme--action-road .button {
  background-color: #FFFFFF;
  padding: 10px;
}
.content_scheme .scheme--action-road .text {
  color: #FFFFFF;
  font-size: var(--fs-32);
}
.content_scheme .scheme image[data-content],
.content_scheme .scheme .road {
  opacity: 0;
  transition: var(--transition);
}
.content_scheme .scheme.show_road:after {
  opacity: 1;
}
.content_scheme .scheme.show_road image[data-content],
.content_scheme .scheme.show_road .road {
  opacity: 1;
}
.content_scheme .scheme.show_road .scheme--action {
  display: none;
}
.content_scheme .scheme.show_road .placemark {
  opacity: 0;
  pointer-events: none;
}
.content_scheme .scheme:not(.show_road) .scheme--action-road {
  display: none;
}
.content_scheme .scheme-svg {
  cursor: grab;
  position: relative;
}
.content_scheme .scheme-svg image {
  width: 100%;
}
.content_scheme .scheme--tooltip {
  position: fixed;
  opacity: 0;
  padding: 12px 18px;
  background-color: #fff;
  border-radius: 8px;
  z-index: 6;
  max-width: 240px;
  transition: opacity 0.18s;
  pointer-events: none;
  translate: -50% -130%;
}
.content_scheme .scheme--tooltip:after {
  content: "";
  width: 12px;
  height: 8px;
  top: 105%;
  left: 50%;
  translate: -50% -50%;
  display: inline-block;
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'%3E%3Cpath d='M6 8L12 0H0L6 8Z' fill='white'/%3E%3C/svg%3E");
}
.content_scheme .scheme--tooltip p {
  color: #2A2827;
  font-size: var(--fs-16);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.content_scheme .scheme--tooltip.is_active {
  opacity: 1;
}
.content_scheme .js-shceme-road .item {
  padding: 12px;
}
.content_scheme .js-shceme-road .item:hover {
  color: #144fc3;
  background-color: #f2f5fa;
}
@media (max-width: 768px) {
  .content_scheme .js-shceme-road .scheme--action_list {
    right: 0;
  }
}

.popup .is_hidden {
  display: none;
  opacity: 0;
  transition: var(--transition);
}

.popup-scheme {
  padding: 16px;
}
.popup-scheme .popup--content {
  width: 100%;
  border-radius: 12px;
  position: relative;
  background-color: white;
  max-width: 512px;
  padding: 0;
}
.popup-scheme .popup--close {
  background-color: #FFFFFF;
  border-radius: 50px;
  padding: 5px;
}
.popup-scheme .popup--close .button {
  height: 24px;
  width: 24px;
  padding: 0;
}
.popup-scheme .popup--close .button .icon {
  align-items: center;
  justify-content: center;
}
.popup-scheme .popup--close .button .icon:before {
  font-size: 20px;
}
.popup-scheme .popup--content_description {
  padding: var(--g-32);
}
.popup-scheme .popup--content_title {
  font-weight: 700;
  margin-bottom: 8px;
  font-size: var(--fs-24);
}
.popup-scheme .popup--actions {
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.popup-scheme .popup--content_image {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}
.popup-scheme .popup--content_image img {
  height: auto;
  aspect-ratio: 480/320;
}
.popup-scheme .slider_controls {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}
.popup-scheme .slider_controls .swiper--prev {
  position: static;
  padding: 15px;
  left: 10px;
  background-color: #FFFFFF;
  z-index: 1;
}
.popup-scheme .slider_controls .swiper--next {
  padding: 15px;
  position: static;
  right: 10px;
  background-color: #FFFFFF;
  z-index: 1;
}
.popup-scheme .slider_controls .swiper_pagination {
  z-index: 1;
  position: absolute;
  left: 6px;
  bottom: 6px;
  bottom: 6px;
  top: auto;
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
}
.popup-scheme .slider_controls .swiper_pagination .swiper-pagination-bullet {
  background: white;
}
.popup-scheme .slider_controls .swiper_pagination .swiper-pagination-bullet-active {
  scale: 1.1;
}
.popup-scheme .slider_controls .swiper-button-prev:after,
.popup-scheme .slider_controls .swiper-button-next:after {
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 20px;
  color: black;
}

.content_scheme .clouds {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.content_scheme .clouds:after, .content_scheme .clouds:before {
  pointer-events: none;
  content: "";
  position: absolute;
  inset: 0;
  width: 200%;
  left: 50%;
  background-size: cover;
}
@keyframes clouds-top {
  0% {
    translate: 0% 0%;
  }
  50% {
    translate: -50% 0%;
  }
  100% {
    translate: 0% 0%;
  }
}
@keyframes clouds-bottom {
  0% {
    translate: 0% 0%;
  }
  50% {
    translate: -50% 0%;
  }
  100% {
    translate: 0% 0%;
  }
}
.content_scheme .clouds:after {
  animation: clouds-top 25s linear infinite;
}
.content_scheme .clouds:before {
  animation: clouds-bottom 20s linear infinite;
}

.zoom_buttons {
  z-index: 6;
  position: absolute;
  top: auto;
  left: var(--g-32);
  bottom: var(--g-48);
  align-items: center;
  gap: 8px;
  display: flex;
}
@media (max-width: 768px) {
  .zoom_buttons {
    flex-direction: column;
    top: var(--g-32);
    left: 10px;
    height: max-content;
  }
}
.zoom_buttons .panzoom__button--zoomIn,
.zoom_buttons .panzoom__button--zoomOut {
  padding: 13px;
}
@media (max-width: 768px) {
  .zoom_buttons .panzoom__button--zoomIn,
  .zoom_buttons .panzoom__button--zoomOut {
    padding: 10px;
  }
}
.zoom_buttons .panzoom__button--zoomIn.is_disabled,
.zoom_buttons .panzoom__button--zoomOut.is_disabled {
  opacity: 0.6;
}
.zoom_buttons .icon-ic-lg-plus,
.zoom_buttons .icon-ic-lg-minus {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.zoom_buttons .icon-ic-lg-plus {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0_125_1777' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath d='M12.8333 2H11.1667V11.1667H2V12.8333H11.1667V22H12.8333V12.8333H22V11.1667H12.8333V2Z' fill='black'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_125_1777)'%3E%3Crect width='24' height='24' fill='%23111820'/%3E%3C/g%3E%3C/svg%3E");
}
.zoom_buttons .icon-ic-lg-minus {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0_125_1769' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath d='M22 11.1667H2V12.8334H22V11.1667Z' fill='black'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_125_1769)'%3E%3Crect width='24' height='24' fill='%23111820'/%3E%3C/g%3E%3C/svg%3E");
}

.scheme.active:before {
  opacity: 0;
}

#scheme--hand {
  pointer-events: none;
  position: absolute;
  bottom: var(--g-32);
  left: 50%;
  translate: -50% 0;
  z-index: 1;
}
@media (max-width: 768px) {
  #scheme--hand {
    top: 50%;
    translate: -50% -50%;
    max-width: 30%;
  }
}

#scheme_panzoom {
  max-height: calc(100vh - var(--headerHeight, 96px));
  width: 100%;
}
@media (max-width: 768px) {
  #scheme_panzoom {
    max-height: calc(100 * var(--vh, 1vh));
    height: calc(100 * var(--vh, 1vh));
  }
}

.placemark {
  cursor: pointer;
  transition: var(--transition) opacity;
}
.placemark circle {
  transition: var(--transition);
}
.placemark:hover circle:first-of-type {
  r: 17;
}
.placemark:hover circle:nth-child(2) {
  r: 16;
}
.placemark:hover circle:last-of-type {
  r: 12;
}

.placemark.hidden {
  display: block;
  opacity: 0;
}

.content_scheme-mobile {
  display: none;
}
@media (max-width: 768px) {
  .content_scheme-mobile {
    display: block;
  }
  .content_scheme-mobile-container {
    display: flex;
    margin-top: var(--g-section-inner);
    overflow: hidden;
    border-radius: var(--border-corner);
    position: relative;
  }
  .content_scheme-mobile-container button {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }
}

@media (max-width: 768px) {
  .content_scheme-desktop {
    margin: 0;
    padding: 0;
  }
  .content_scheme-desktop .block--wrapper {
    margin: 0;
    padding: 0;
  }
  .content_scheme-desktop .scheme {
    display: none;
  }
}
.content_scheme-desktop .block--head {
  display: block;
}
@media (max-width: 768px) {
  .content_scheme-desktop .block--head {
    display: none;
  }
}
.content_scheme-desktop .scheme.is_active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  margin: 0;
  background: #FFFFFF;
  border-radius: 0;
  max-height: 100vh;
}
.content_scheme-desktop--close {
  display: none;
}
@media (max-width: 768px) {
  .content_scheme-desktop--close {
    display: block;
    position: absolute;
    top: var(--g-32);
    left: auto;
    right: 10px;
  }
  .content_scheme-desktop--close .button {
    padding: 10px;
  }
}