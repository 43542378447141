@font-face
  font-family: 'Gilroy'
  font-weight: 600
  font-style: normal
  src: url("../fonts/Gilroy/Gilroy-Semibold.ttf") format('truetype')
@font-face
  font-family: 'Gilroy'
  font-weight: 500
  font-style: normal
  src: url("../fonts/Gilroy/Gilroy-Medium.ttf") format('truetype')

@font-face
  font-family: 'InterVariable'
  //font-weight: 300
  font-style: normal
  src: url("../fonts/InterVariable/InterVariable.ttf") format('opentype')

html
  font-size: clamp(16px, calc(16px + (18 - 16) * ((100vw - 1024px) / (1920 - 1024))), 18px)
  line-height: 1.25
  font-family: 'InterVariable', sans-serif
  letter-spacing: -0.02em
  color: var(--c-text-default,#141A25)
  text-size-adjust: none
  -webkit-text-size-adjust: none


.f-h1
  font-size: clamp(52px, calc(52px + (148 - 52) * ((100vw - 1024px) / (1920 - 1024))), 148px)



.f-h2
  font-size: var(--fs-96)

.f-h3
  font-size: var(--fs-72)
.f-h4
  font-size: var(--fs-60)

h1,h2,h3,h4,h5,h6
  font-weight: 500
  font-family: 'Gilroy'
  line-height: 1

