.content_scheme
  overflow: hidden
  .scheme
    position: relative
    margin-top: var(--g-section-inner)
    overflow: hidden
    border-radius: var(--border-corner)
    max-height: calc(100vh - var(--headerHeight,96px) * 2)
    background-image: url('/local/templates/main/assets/img/logo/logo.svg')
    background-size: 40%
    background-position: center
    background-repeat: no-repeat
    &--container
      height: 100%
      width: 100%
      aspect-ratio: 1880 / 960
      //overflow: auto
    &:after,
    &:before
      content: ''
      inset: 0
      position: absolute
      z-index: 1
      pointer-events: none
      transition: var(--transition)

    &:after
      opacity: 0
      background: linear-gradient(0deg, rgba(0,31,58,0) 70%, rgba(0,31,58,0.6502976190476191) 100%)
      @media(max-width: 768px)
        background: linear-gradient(0deg, rgba(0,31,58,0.6502976190476191) 0%, rgba(0,31,58,0) 35%)
    &:before
      opacity: 1
      background: linear-gradient(0deg, rgba(0,31,58,0.6502976190476191) 0%, rgba(0,31,58,0) 35%)
      @media(max-width: 768px)
        background: linear-gradient(0deg, rgba(0,31,58,0.5) 0%, rgba(0,31,58,0) 100%)
    &--actions
      position: absolute
      top: var(--g-32)
      left: var(--g-32)
      display: flex
      gap: 8px
      z-index: 2
      @media(max-width: 768px)
        top: auto
        bottom: var(--g-32)
        left: 10px
        right: 10px
    &--action
      position: relative
      @media(max-width: 768px)
        width: 100%
      &_list
        position: absolute
        z-index: 3
        width: auto
        min-width: 100%
        height: fit-content
        pointer-events: none
        opacity: 0
        transition: var(--transition)
        background-color: #FFFFFF
        padding: var(--g-24)
        border-radius: var(--border-corner)
        margin-top: 8px
        display: flex
        flex-direction: column
        gap: 6px
        max-width: 90vw
        width: max-content
        @media(max-width: 768px)
          transform: translateY(8px)
          bottom: calc(100% + 8px)
          padding: 12px 8px

        .item
          cursor: pointer
          display: flex
          gap: 10px
          transition: var(--transition)
          border-radius: 8px
          align-items: center
          p
            font-size: var(--fs-20)
            display: -webkit-box
            overflow: hidden
            text-overflow: ellipsis
            -webkit-line-clamp: 3
            -webkit-box-orient: vertical
          span:last-child
            font-size: 14px
            opacity: 0.7
            @media(max-width: 768px)
              font-size: 11px
          &:hover
            .custom-checkbox
              border-color: #144FC3
          &.is_active
            .custom-checkbox:after
              opacity: 1

        .custom-checkbox
          width: 1.2rem
          height: 1.2rem
          border: 1px solid #000000
          border-radius: 50px
          display: inline-block
          position: relative
          &:after
            content: ''
            position: absolute
            inset: 3px
            background-color: #144FC3
            border-radius: 50px
            opacity: 0
      .button
        background-color: #FFFFFF
        padding: 10px 12px
        border-radius: var(--border-corner)
        @media(max-width: 768px)
          width: 100%
          justify-content: center
        span:first-of-type
          font-size: 1.3rem
      &.is_opened
        .button
          color: #144FC3
          .icon-chevron-down
            rotate: 180deg
        .scheme--action_list
          transform: translateY(0)
          opacity: 1
          pointer-events: all
    &--action-road
      display: flex
      gap: 10px
      align-items: center
      .button
        background-color: #FFFFFF
        padding: 10px
      .text
        color: #FFFFFF
        font-size: var(--fs-32)

    image[data-content],
    .road
      opacity: 0
      transition: var(--transition)
    &.show_road
      &:after
        opacity: 1
      image[data-content],
      .road
        opacity: 1
      .scheme--action
        display: none
      .placemark
        opacity: 0
        pointer-events: none
    &:not(.show_road)
      .scheme--action-road
        display: none
    &-svg
      cursor: grab
      position: relative
      image
        width: 100%
    &--tooltip
      position: fixed
      opacity: 0
      padding: 12px 18px
      background-color: #fff
      border-radius: 8px
      z-index: 6
      max-width: 240px
      transition: opacity 0.18s
      pointer-events: none
      translate: -50% -130%
      &:after
        content: ''
        width: 12px
        height: 8px
        top: 105%
        left: 50%
        translate: -50% -50%
        display: inline-block
        position: absolute
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'%3E%3Cpath d='M6 8L12 0H0L6 8Z' fill='white'/%3E%3C/svg%3E")
      p
        color: #2A2827
        font-size: var(--fs-16)
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical
      &.is_active
        opacity: 1
  .js-shceme-road
    .item
      padding: 12px
    .item:hover
      color: #144fc3
      background-color: #f2f5fa
    .scheme--action_list
      @media(max-width: 768px)
        right: 0


.popup .is_hidden
  display: none
  opacity: 0
  transition: var(--transition)

.popup-scheme
  padding: 16px
  .popup--content
    width: 100%
    border-radius: 12px
    position: relative
    background-color: white
    max-width: 512px
    padding: 0
  .popup--close
    background-color: #FFFFFF
    border-radius: 50px
    padding: 5px
    .button
      height: 24px
      width: 24px
      padding: 0
      .icon
        align-items: center
        justify-content: center
      .icon:before
        font-size: 20px
  .popup--content_description
    padding: var(--g-32)
  .popup--content_title
    font-weight: 700
    margin-bottom: 8px
    font-size: var(--fs-24)
  .popup--actions
    margin-top: 16px
    display: flex
    gap: 16px
    flex-wrap: wrap
  .popup--content_image
    position: relative
    border-radius: 12px
    overflow: hidden

    img
      height: auto
      aspect-ratio: 480/320
  .slider_controls
    position: absolute
    inset: 0
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 10px
    //.swiper-button-prev,.swiper-button-next
    //  translate: -50% 0
    //  top: 50%
    .swiper--prev
      position: static
      padding: 15px
      left: 10px
      background-color: #FFFFFF
      z-index: 1
    .swiper--next
      padding: 15px
      position: static
      right: 10px
      background-color: #FFFFFF
      z-index: 1
    .swiper_pagination
      z-index: 1
      position: absolute
      left: 6px
      bottom: 6px
      bottom: 6px
      top: auto
      display: flex
      justify-content: center
      gap: 8px
      width: 100%
      .swiper-pagination-bullet
        background: white
        &-active
          scale: 1.1
    .swiper-button-prev:after,
    .swiper-button-next:after
      background-color: white
      border-radius: 50%
      padding: 5px
      font-size: 20px
      color: black




.content_scheme
  .clouds
    position: absolute
    z-index: 4
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: none
    overflow: hidden
    &:after,
    &:before
      pointer-events: none
      content: ''
      position: absolute
      inset: 0
      width: 200%
      left: 50%
      background-size: cover

    @keyframes clouds-top
      0%
        translate: 0% 0%
      50%
        translate: -50% 0%
      100%
        translate: 0% 0%
    @keyframes clouds-bottom
      0%
        translate: 0% 0%
      50%
        translate: -50% 0%
      100%
        translate: 0% 0%
    &:after
      //background-image: url("../img/top.png")
      animation: clouds-top 25s linear infinite
    &:before
      //background-image: url("../img/bottom.png")
      animation: clouds-bottom 20s linear infinite


.zoom_buttons
  z-index: 6
  position: absolute
  top: auto
  left: var(--g-32)
  bottom: var(--g-48)
  align-items: center
  gap: 8px
  display: flex
  @media(max-width: 768px)
    flex-direction: column
    top: var(--g-32)
    left: 10px
    height: max-content
  .panzoom__button--zoomIn,
  .panzoom__button--zoomOut
    padding: 13px
    @media(max-width: 768px)
      padding: 10px
    &.is_disabled
      opacity: 0.6
  .icon-ic-lg-plus,
  .icon-ic-lg-minus
    background-repeat: no-repeat
    background-position: center
    background-size: contain
  .icon-ic-lg-plus
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0_125_1777' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath d='M12.8333 2H11.1667V11.1667H2V12.8333H11.1667V22H12.8333V12.8333H22V11.1667H12.8333V2Z' fill='black'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_125_1777)'%3E%3Crect width='24' height='24' fill='%23111820'/%3E%3C/g%3E%3C/svg%3E")
  .icon-ic-lg-minus
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0_125_1769' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath d='M22 11.1667H2V12.8334H22V11.1667Z' fill='black'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_125_1769)'%3E%3Crect width='24' height='24' fill='%23111820'/%3E%3C/g%3E%3C/svg%3E")

.scheme.active
  &:before
    opacity: 0

#scheme--hand
  pointer-events: none
  position: absolute
  bottom: var(--g-32)
  left: 50%
  translate:  -50% 0
  z-index: 1
  @media(max-width: 768px)
    top: 50%
    translate: -50% -50%
    max-width: 30%

#scheme_panzoom
  max-height: calc(100vh - var(--headerHeight, 96px))
  width: 100%
  @media(max-width: 768px)
    max-height: calc(100* var(--vh, 1vh))
    height: calc(100* var(--vh, 1vh))
.placemark
  cursor: pointer
  transition: var(--transition) opacity
  circle
    transition: var(--transition)
  &:hover
    circle:first-of-type
      r: 17
    circle:nth-child(2)
      r: 16
    circle:last-of-type
      r: 12
.placemark.hidden
  display: block
  opacity: 0

.content_scheme-mobile
  display: none
  @media(max-width: 768px)
    display: block
    &-container
      display: flex
      margin-top: var(--g-section-inner)
      overflow: hidden
      border-radius: var(--border-corner)
      position: relative
      button
        position: absolute
        top: 50%
        left: 50%
        translate: -50% -50%
.content_scheme-desktop
  @media(max-width: 768px)
    margin: 0
    padding: 0
    .block--wrapper
      margin: 0
      padding: 0
    .scheme
      display: none
  .block--head
    display: block
    @media(max-width: 768px)
      display: none
  .scheme.is_active
    display: block
    position: fixed
    top: 0
    left: 0
    z-index: 50
    width: 100vw
    height: calc(100* var(--vh, 1vh))
    margin: 0
    background: #FFFFFF
    border-radius: 0
    max-height: 100vh

  &--close
    display: none

    @media(max-width: 768px)
      display: block
      position: absolute
      top: var(--g-32)
      left: auto
      right: 10px
      .button
        padding: 10px
