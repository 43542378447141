.content_subscription
  background-color: var(--c-bck-dark-blue)
  color: var(--c-surface-default)
  padding: var(--g-content) 0
  .block--wrapper
    display: flex
    align-items: center
    gap: var(--g-20)
    max-width: 1080px
    flex-direction: column
  &:last-child
    margin: 0
  .block--head
    border-bottom: none
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    &_subtitle
      color: var(--accent-2)
    &_title
      text-align: center
    &_caption
      color: #CDD9EC


  fieldset
    display: flex
    gap: 8px
    @media(max-width: 768px)
      flex-direction: column
      .form__input,
      .form__button,
      .form__button .button
        width: 100%
    .form__input
      flex-grow: 1

.form--subscription
  width: 100%
  fieldset
    display: flex
    gap: var(--g-24)
    align-items: center
    @media(max-width: 1240px)
      flex-direction: column

    .form--input
      width: 100%
      border: none
      align-items: center
      @media(max-width: 768px)
        flex-direction: column
      input
        border-bottom: 1px solid rgba(255, 255, 255, 0.7)
        color: var(--c-text-primary)
        @media(max-width: 768px)
          width: 100%

    .form--input > span
      color: #FFFFFF
  .form--button
    position: relative
    margin-top: 0
    @media(max-width: 768px)
      margin-top: 16px
      display: flex
      flex-direction: column
      gap: 8px
      width: 100%
      .button
        width: 100%
  .confirmation
    max-width: 100%
    margin: auto
    @media(min-width: 769px)
      width: 100%
      position: absolute
      left: 110%
      top: 0
      bottom: 0
      right: -20%
      display: flex
      align-items: center
    .access
      color: #FFFFFF
