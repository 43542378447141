.content_advantages
  &:not(.block_list-slider)
    .block--elements
      display: flex
      flex-wrap: wrap
      @media(max-width: 768px)
        flex-direction: column
        flex-wrap: nowrap
    .block--pagination
      grid-column: span 1
      @media(min-width: 640px)
        grid-column: span 3




.content_advantages-2
  &:not(.block_list-slider)
    .block--elements
      display: flex
      flex-direction: column
      gap: 8px
      @media(min-width: 769px)
        display: grid
        grid-template-columns: repeat(24, 1fr)
  .card
    overflow: hidden
    @media(min-width: 768px)
      min-height: 400px
    &--image
      height: 100%
      position: absolute
      translate: 0 100%
      transition: 0.2s linear
    .image-front
      height: 100%
      object-fit: cover
      translate: 0 150%
      transition: 0.2s linear
      position: absolute
      inset: 0
      z-index: 3
    &--content
      gap: clamp(48px, calc(48px + (110 - 48) * ((100vw - 768px) / (1920 - 768))), 110px)
      height: 100%
      justify-content: space-between
    &--title
      transition: 0.2s linear
      text-transform: uppercase
      z-index: 2
      font-size: clamp(32px, calc(32px + (72 - 32) * ((100vw - 1024px) / (1920 - 1024))), 72px)
    &--text
      font-size: var(--fs-24)
      color: #404B57
  .card:nth-child(1)
    grid-column: 1/14
  .card:nth-child(2)
    grid-column: 14/25
  .card:nth-child(3)
    grid-column: 1/12
  .card:nth-child(4)
    grid-column: 12/25

  .card:hover
    .card--image,
    .image-front
      translate: 0 0
    .card--title
      color: var(--c-text-primary)
