article
  .block--wrapper
    max-width: 1600px
    margin: 0 auto
  .block--content

    display: grid
    grid-template-columns: 9fr auto
    grid-template-areas: 'label side' 'cascade side'
    @media(max-width: 998px)
      grid-template-areas: 'side side' 'label label' 'cascade cascade'
      gap: 16px 0
  .is_cascade
    grid-area: cascade
    max-width: 1024px
    width: 100%
    margin: auto
  .block--head
    display: flex
    flex-direction: column
    border-bottom: none
    &_title
      max-width: initial
  .block--article_label
    grid-area: label
    display: flex
    gap: 16px
    & > div
      display: flex
      gap: 16px
      & > div
        display: flex
        align-items: center
        gap: 6px
    & > div:first-of-type
      span
        padding: 8px
        background-color: var(--c-surface-subdued)
        border-radius: 8px

