.accordion
  cursor: pointer
  position: relative
  padding: var(--g-24)
  border-radius: var(--border-corner)
  max-width: 720px
  height: max-content
  background-color: var(--c-bck-default)
  &.is_open
    .accordion--content
      height: auto
      max-height: 100%
      margin: var(--g-24) 0 0
      /* padding: 16px 0; *
      -webkit-transition: .3s ease-out
      -o-transition: .3s ease-out
      transition: .3s ease-out
      pointer-events: auto
      opacity: 0.8
    .icon-plus
      transform: rotate(45deg)
      &:before
        content: "\e914"
  &--button
    cursor: pointer
    display: flex
    justify-content: space-between
    gap: 18px
    align-items: center
    position: relative
    .accordion--button_text
      margin: 0
      font-size: var(--fs-28)
      font-weight: 400
    .icon-plus
      font-size: 1.5rem
      transition: var(--transition)
      transform: rotate(0)
  &--content
    overflow: hidden
    height: 0
    max-height: 0
    transition: all .5s
    pointer-events: none
    opacity: 0
    &.is_cascade
      & > p:first-child
        margin: 0
  & + .accordion
    margin-top: 16px
