.content_offers
  &:not(.block_list-slider)
    .block--elements
      grid-template-columns: repeat(2, 1fr)
      @media(max-width: 640px)
        grid-template-columns: 1fr
      .block--pagination
        grid-column: span 1
        @media(min-width: 640px)
          grid-column: span 2
