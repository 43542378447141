.block_banner
  overflow: hidden
  position: relative

  @media(min-width: 520px) and (orientation: landscape)
    max-height: 1080px
    min-height: 620px
  .block--wrapper
    height: 100%
    position: relative
    z-index: 2
  .block--head,
  .block--actions
    z-index: 2
    position: relative
  .block--actions
    .button
      margin-top: 0
  .block--head_subtitle
    color: #fff
  .block--content
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: 0
    width: 100%
    height: 100%
    overflow: hidden
    img, video
      width: 100%
      height: 100%
      object-position: center
      object-fit: cover
  .block--head
    padding-bottom: 0
    color: var(--c-text-primary)
    border-bottom: none
    & > *
      z-index: 3
      position: relative
    &_title
      display: -webkit-box
      overflow: hidden
      text-overflow: ellipsis
      -webkit-line-clamp: 3
      -webkit-box-orient: vertical
  .banner
    display: flex
    padding: var(--g-container)
    flex-shrink: 0
    flex-grow: 0
    height: auto
    overflow: hidden
    position: relative
    place-content: end start
    place-items: end start


.block_banner.block_banner-group:not(.block_banner-hero)
  .block--wrapper
    overflow: hidden
.block_banner:not(.block_banner-hero)
  .banner
    margin: auto
    padding: 84px var(--g-container)
    place-content: center
    place-items: center
    border-radius: var(--border-corner)
    @media(min-width: 560px)
      height: calc(100vh - var(--headerHeight, 95px))
    &:before
      content: ""
      position: absolute
      inset: 0
      display: block
      background: rgba(20, 26, 37, 0.3)
      pointer-events: none
      z-index: 1
  .block--head
    text-align: center
    align-items: center
    position: relative
    padding: var(--g-container) var(--g-container) 0
    display: flex
    align-items: center
    z-index: 2
    & > div
      text-align: center




.block_banner.block_banner-hero.block_banner-group
  .block--head
    @media(min-width: 998px)
      padding-right: 140px
  .banner
    @media(max-width: 768px)
      padding-bottom: 70px
.block_banner.block_banner-hero
  margin: 0
  position: relative
  background-color: var(--c-surface-dark)
  height: calc(100vh - var(--headerHeight, 77px) - 100px)
  @media(min-width: 769px)
    height: calc(100vh - var(--headerHeight, 95px))
  .block--wrapper
    padding: 0
    max-width: 100%
  .banner
    position: relative
    height: 100%
    min-width: 100%
    padding: var(--headerHeight, 95px)  var(--g-32) 42px
    @media(min-width: 769px)
      padding: var(--headerHeight, 95px)  var(--g-32) var(--g-32)
    &:before
      content: ""
      background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.196516106442577) 33%, rgba(220,220,220,0) 47%, rgba(0,0,0,0.65) 100%)
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      pointer-events: none
      z-index: 2
  .block--content
    margin-top: auto
  .block--head
    color: var(--c-text-primary)
    border-top: 1px solid #fff
    @media(max-width: 998px)
      width: 100%
    &_caption p
      font-size: var(--fs-36)
    &_subtitle
      position: relative
      width: 100%
      p
        position: absolute
        top: -50px
        @media(max-width: 998px)
          top: -30px

  .block--actions
    width: 100%
    justify-content: flex-start
  .slider_controls
    align-items: center
    position: absolute
    padding: 0 var(--g-container) var(--g-container)
    bottom: 0
    right: 0
    z-index: 4
    @media(max-width: 768px)
      width: 100%
      padding:  var(--g-container)  var(--g-container)
  .swiper--prev,
  .swiper--next
    padding: 12px
    border: 1px solid var(--c-surface-subdued)

  .swiper-pagination
    margin-right: auto
    @media(min-width: 768px)
      display: none


.block_banner.banner-no_media
  .banner
    padding: var(--g-content) 0
    height: auto
    min-height: 80vh
    aspect-ratio: unset
  .block--head



