.block_image_text
  .block--head
    display: flex
    flex-direction: column
    border-bottom: none
    border-radius: var(--border-corner) var(--border-corner) 0 0
    grid-column: 1
    &_title
      font-size: var(--fs-72)
      padding-bottom: var(--g-24)
      border-bottom: none
      overflow-wrap: anywhere
      padding-bottom: 0
    .block--actions
      @media(max-width: 475px)
        width: 100%
        .button
          width: 100%
  .block--wrapper
    display: grid
    grid-template-columns: 1fr 1fr
    place-content: start
    gap: 0 8px
    grid-template-rows: repeat(auto-fit, auto)
    align-items: center
    @media(max-width: 768px)
      display: flex
      flex-direction: column


  .block--content
    height: 100%
    border-radius: 0 0 var(--border-corner) var(--border-corner)
    margin-bottom: auto
    grid-column: 2
    @media(max-width: 768px)
      grid-column: 1
      min-height: 15px
  .block--image ~ .block--head,
  .block--image ~ .block--content
    height: 100%
    grid-column: 1
    width: 100%
    //padding-left:  8px
    //padding-right: 8px
    background-color: var(--c-surface-default)
    @media(min-width: 769px)
      padding-left: var(--g-80)
      padding-right: var(--g-80)
  .block--image ~ .block--head
    justify-content: flex-end
  .block--image
    display: flex
    grid-column: 2
    grid-row: 1 / span 2
    min-height: 100%
    max-width: 100%
    border-radius: var(--border-corner)
    @media(max-width: 768px)
      grid-column: 1
      grid-row: 1 / span 1
      aspect-ratio: 1/1
    img, video
      object-fit: cover
      height: 100%
      width: 100%
      border-radius: var(--border-corner)
      overflow: hidden
      aspect-ratio: 1/1
      @media(min-width: 769px)
        position: sticky
        top: calc(var(--headerHeight) )

  .block--head
    align-items: flex-start
    padding-top: var(--g-80)
  .block--content
    margin-top: 0
  &.block_image_text-reversed
    .block--wrapper
      .block--image
        grid-column: 1
      .block--head,
      .block--content
        grid-column: 2
        @media(max-width: 768px)
          grid-column: 1

  &.block_image_text-bg
    .block--head,
    .block--content
      background-color: var(--bg-color)
      color: var(--text-color)
    .block--head_subtitle
      color: var(--subtitle-color)
  &.block_image_text-wrapper_bg
    .block--wrapper
      background-color: var(--bg-color)
    //&.block_image_text-wide
    //  .block--wrapper
    //    padding-top: 0
    //    padding-bottom: 0
  &.block_image_text-no_media
    .block--wrapper
      grid-template-rows: auto
      @media(max-width: 768px)
        padding: var(--g-container)
    .block--head
      width: 100%
      padding-top: 0



  &.block_image_text-mask
    #mask,
    #mask-2
      position: absolute
      width: 0
      height: 0
    .block--image
      img
        border-radius: 1000px 1000px  1000px 0
        height: auto
  &.block_image_text-reversed.block_image_text-mask
    .block--image
      img
        border-radius:  1000px 1000px 0 1000px

  .block--image.block--image-slider
    overflow: hidden
    height: max-content
    overflow: hidden
    min-height: auto
    margin-bottom: auto
    img
      height: auto
      flex-shrink: 0
      flex-grow: 0
    .slider_controls
      .swiper-pagination
        @media(min-width: 769px)
          margin-left: 0
          margin-right: auto
      .button
        padding: 13px
.block_image_text[class*="background"]
  @media(max-width: 768px)
    .block--image ~ .block--head,
    .block--image ~ .block--content
        padding-left:  16px
        padding-right: 16px
        padding-bottom: 16px
    .block--image
      margin-bottom: 8px


