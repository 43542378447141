.content_404

  .block--wrapper
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    min-height: 80vh
  .block--head
    align-items: center
    text-align: center
    border: none
    display: flex
    flex-direction: column
    &_caption p
      font-family: 'Gilroy'
      font-size: var(--fs-72)
      color: #404B57
      line-height: 1
  h1
    font-size: clamp(48px, calc(48px + (96 - 48) * ((100vw - 1024px) / (1920 - 1024))), 96px)
    text-align: center
