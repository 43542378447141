.content_reviews
  &.block_list:not(.block_list-slider) .block--elements
    grid-template-columns: repeat(3, 1fr)
    @media(max-width: 992px)
      grid-template-columns: repeat(2, 1fr)
      @media(max-width: 640px)
        grid-template-columns: 1fr
    .block--pagination
      grid-column: span 4
      @media(max-width: 992px)
        grid-column: span 3
      @media(max-width: 640px)
        grid-column: span 1
  &.block_list-slider
    .card
      @media(min-width: 992px)
        min-width: calc((var(--container) - 48px) / 3)
.content_reviews-video
  &.block_list:not(.block_list-slider)
    .block--elements
      grid-template-columns: repeat(4, 1fr)
      @media(max-width: 992px)
        grid-template-columns: repeat(3, 1fr)
        @media(max-width: 640px)
          grid-template-columns: 1fr
