.content_road_map
  position: relative
  .map
    position: relative
    overflow: hidden
    height: 100%
    border-radius: var(--border-corner)
    @media(max-width: 768px)
      aspect-ratio: 1/1
      width: 100%
      height: auto
  .block--content
    border-radius: var(--border-corner)
    position: relative
    overflow: hidden
    background-color: var(--c-surface-subdued)
    height: 100%
    margin-top: 0
    padding: var(--g-52) var(--g-content)
    .item
      &--title
        font-size: var(--fs-52)
        font-weight: 500
        font-family: 'Gilroy'
  line-height: 1
  .block--elements
    display: flex
    flex-direction: column
    gap: var(--g-8)
    @media(min-width: 769px)
      display: grid
      grid-template-columns: 4fr 8fr
