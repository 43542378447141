.content_gallery
  max-width: 100%
  overflow: hidden
  figcaption
    display: none
  .gallery--item
    overflow: hidden
    width: 100%
    display: flex
    align-items: flex-start
    cursor: pointer
    position: relative
    border-radius: var(--border-corner)
    aspect-ratio: 640 /440
    &:after
      content: ""
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      background-color: rgba(20, 26, 37, 0.35)
      transition: var(--transition)
      z-index: 2
      pointer-events: none
      opacity: 0
    &:hover
      &:after
        opacity: 1
    img, figure
      width: 100%
      height: 100%
      position: relative
      z-index: 1

.gallery:not(.js-gallerySwiper):not([class*='grid'])
  display: grid
  grid-template-columns: repeat(3, 1fr)
  align-items: stretch
  gap: 20px
  @media(max-width: 992px)
    gap: 8px
    grid-template-columns: 1fr 1fr
  @media(max-width: 575px)
    grid-template-columns: 1fr

.gallery[class*='grid']
  grid-template-columns: repeat(3, 1fr)
  align-items: stretch
  display: grid
  gap: 20px
  @media(max-width: 992px)
    gap: 8px
  @media(max-width: 575px)
    grid-template-columns: repeat(2, 1fr)
.gallery--grid
  &:not(.gallery-reversed)
    .gallery--item
      height: 100%
      @media(max-width: 575px)
        aspect-ratio:  180/155
      &:nth-child(6n + 1)
        grid-column: span 2
        grid-row: span 2
        @media(max-width: 575px)
          grid-column: 1
      &:nth-child(n+4):nth-child(6n - 1)
        grid-column: 2/4
        grid-row: span 2
        @media(max-width: 575px)
          grid-column: 2
  &-reversed
    .gallery--item
      height: 100%
      @media(max-width: 575px)
        aspect-ratio:  180/155
      &:nth-child(6n + 2)
        grid-column: 2/4
        grid-row: span 2
        @media(max-width: 575px)
          grid-column: 2
      &:nth-child(n+4):nth-child(6n - 2 )
        grid-column: span 2
        grid-row: span 2
        @media(max-width: 575px)
          grid-column: 1

.js-gallerySwiper
  display: flex
  flex-direction: column
  width: 100%
  position: relative
  @media(max-width: 575px)
    width: 95%
    margin: auto
  figcaption
    display: none
  .gallery--item
    aspect-ratio: 1600 / 920
    width: 100%
    height: 100%
    flex-shrink: 0
    flex-grow: 0
    overflow: hidden
    display: flex
    align-items: flex-start
    @media(max-width: 560px)
      aspect-ratio: 360 / 298
    figure
      width: 100%
      height: 100%
      display: flex
      flex-direction: column
      align-items: flex-start
    img
      width: 100%
      object-fit: cover

.is_cascade__slider
  .gallery--item
    aspect-ratio: 828 / 480

