.block--stripe
  .block--wrapper
    padding-top: 0
    padding-bottom: 0
  .item
    display: flex
    gap: 8px
    @media(max-width: 768px)
      flex-direction: column
    &--image,
    &--actions
      overflow: hidden
      flex: 20% 0 0
      border-radius: var(--border-corner)
    &--content
      display: flex
      flex-direction: column
      gap: var(--g-20)
      width: 100%
      border-radius: var(--border-corner)
      background-color: var(--c-surface-subdued)
      padding: var(--g-48)
    &--title
      text-transform: uppercase
      font-family: 'Gilroy'
      font-size: var(--fs-52)
    &--text
      font-size: var(--fs-24)
    &--image
      display: flex
      aspect-ratio: 420/230
      @media(max-width: 768px)
        aspect-ratio: 360/200

    &--actions
      display: grid
      grid-template-areas: 'icon title' 'icon text' 'auto button'
      grid-template-columns: auto 100%
      gap: 8px 0
      font-size: var(--fs-32)
      font-family: 'Gilroy'
      padding: var(--g-24)
      background-color: var(--bg-color,  #f2f5fa)
      color: var(--text-color)
      &-title
        grid-area: title
        display: flex
        gap: 6px
      .button
        margin-left: auto
        grid-area: button
        margin-top: auto
        padding: 15px
        @media(max-width: 768px)
          padding: 9px
        &:before
          transition: var(--transition)
      .item--text
        font-size: var(--fs-20)
        grid-area: text
      > span[class^="icon-"]
        color: currentColor
        font-size: 1em
        margin-right: 8px
        grid-area: icon
    &--actions:not([href])
      .button
        display: none
