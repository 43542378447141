.swiper-slide,
.card,
.gallery--item,
.card-detail,
.banner-group .banner
  -webkit-transform: translate3d(0, 0, 0)
  -webkit-backface-visibility: hidden

.swiper-wrapper
  -webkit-transform-style: preserve-3d

.swiper--next,
.swiper--prev,
.swiper-button-next,
.swiper-button-prev
  cursor:  pointer
  display: flex
  justify-content: center
  align-items: center
  aspect-ratio: 1/1
  width: clamp(36px, calc(36px + (54 - 36) * ((100vw - 1024px) / (1920 - 1024))), 54px)
  height: clamp(36px, calc(36px + (54 - 36) * ((100vw - 1024px) / (1920 - 1024))), 54px)
  pointer-events: all
  transition: var(--transition)
  border-radius: var(--border-corner-button)
  &:after
    font-size: 1.3rem
    font-family: 'icomoon' !important
  &:before
    transition: .15s linear !important
  &.swiper-button-disabled
    opacity: 0.4
    cursor: auto
    box-shadow: unset !important

.swiper-button-disabled
  pointer-events: none

.swiper-button-prev,
.swiper--prev
  &:after
    z-index: 3
    content: "\e909"

.swiper-button-next,
.swiper--next
  &:after
    z-index: 3
    content: "\e916"

.swiper-pagination
  width: fit-content

.swiper-pagination-bullets
  --swiper-pagination-bottom: 12px


.block_list-slider,
.content_events,
.block_banner:not(.block_banner-hero),
.gallery
  .slider_controls
    margin-top: var(--g-20)
    z-index: 2
    .swiper-pagination-container
      width: 100%
      margin-right: 20px
      position: relative
    .swiper-pagination-progressbar
      translate:  0 -50%
      top: 50%
      border: 1px solid var(--c-border-secondary)
      background-color: transparent
      height: 8px
      width: 100%
      border-radius: 20px
      overflow: hidden
    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill
      background-color: var(--c-surface-dark)
      border-radius: 20px
    .button
      padding: 15px
.slider_controls
  display: flex
  justify-content: space-between
  pointer-events: none
  gap: 10px
.swiper-pagination
  display: flex
  gap: 8px
.swiper-button-prev,
.swiper-button-next
  border: 1px solid var(--c-border-default, #ECEDEE)
  &:hover,
  &:focus
    border-color: rgba(20, 26, 37, 1)
  &.swiper-button-disabled
    opacity: 0.5
.swiper-button-lock,
.swiper-pagination-lock
  display: none
.block_list-slider-v2,
.block_image_text-slider
  .slider_controls
    margin-top: auto
    width: 100%
    align-items: center
    .swiper-button-prev,
    .swiper-button-next
      //color: var(--c-surface-default)
      border: 1px solid currentColor
    .swiper-pagination
      margin-left: auto


.swiper-pagination-bullet
  display: inline-block
  width: 16px
  height: 16px
  border: 1px solid var(--c-surface-default)
  border-radius: 50px
  &.swiper-pagination-bullet-active
    background-color: var(--c-surface-default)

.swiper-notification
  display: none

.block--image-slider
  position: relative
  &:before
    pointer-events: none
    position: absolute
    z-index: 2
    top: 0
    left: 0
    width: 100%
    height: 100%
    content: ''
    -webkit-transition: var(--transition)
    transition: var(--transition)
    opacity: .4
    background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .1965161064) 40%, rgba(220, 220, 220, 0) 100%)
  .slider_controls
    width: auto
    display: flex
    align-items: center
    gap: 16px
    z-index: 2
    bottom: var(--g-32)
    left: var(--g-32)
    right: var(--g-32)
    top: auto
    position: absolute
    @media(max-width: 768px)
      justify-content: center
    .swiper-button-next,
    .swiper-button-prev
      @media(max-width: 768px)
        display: none
    .swiper-pagination
      @media(min-width: 769px)
        margin-left: auto

