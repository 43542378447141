/* Common styles for button */
.button
  position: relative
  display: flex
  align-items: center
  width: fit-content
  gap: 4px
  flex-shrink: 0

  font-size: var(--fs-18)
  line-height: 1.55
  font-weight: 400
  letter-spacing: -0.02em

  border: 1px solid transparent

  cursor: pointer
  z-index: 1
  overflow: hidden
  transition: var(--transition)

  span
    color: inherit
    line-height: inherit
    text-align: center
    z-index: 2
    font-weight: 400
    white-space: nowrap
    letter-spacing: -2%
  span:not([class*=" icon-"])
    transition: 0.1s linear
  span[class^="icon-"],
  span[class*=" icon-"],
  svg[class^="icon"]
    color: inherit
    width: 1.33em
    height: 1.33em
    font-size: 1.2rem
    line-height: 1
    display: flex
    align-items: center
    justify-content: center


/* Button appearance */

.button.button-filled
  background: var(--filled-bgc-base)
  border-color: var(--filled-bc-base)
  color: var(--filled-c-base)
  border-radius: var(--border-corner-button)
  justify-content: center
  padding: 13px 28px
  &:hover,
  &:focus,
  &:active
    //background: var(--filled-bgc-hover)
    border-color: var(--filled-bc-hover)
    color: var(--filled-c-hover)
    &:before
      background: var(--filled-bgc-hover)


.button.button-outlined
  background-color: transparent
  border-color: var(--outlined-bc-base)
  color: var(--outlined-c-base)
  border-radius: var(--border-corner-button)
  align-items: center
  justify-content: center
  padding: 13px 28px
.button.button-outlined:is(:hover, :focus, :active),
.card:is(:hover, :focus, :active) .button.button-outlined,
.footer--lk:is(:hover, :focus, :active) .button.button-outlined,
.events--item .item--text-link:is(:hover, :focus, :active) .button.button-outlined,
.events--item .item--image-link:is(:hover, :focus, :active) .button.button-outlined
    border-color: var(--outlined-bc-hover)
    color: var(--outlined-c-hover)
    background-color: var(--outlined-bgc-hover)
    transition: 0.35s linear background-color
    &:before
      background-color: var(--outlined-bgc-hover)

.button.button-link
  justify-content: flex-start
  background: transparent
  border-color: transparent
  color: var(--link-c-base)
  &:visited
    opacity: 0.8
  &:hover,
  &:focus,
  &:active
    color: var(--link-c-hover)
  span:not([class]),
  span:not([class])
    padding: 0




/* Button  colors  */

.button.button-accent,
.card
  --filled-bgc-base: #FC563B
  --filled-bc-base: #FC563B
  --filled-c-base: #fff
  --filled-bgc-hover: #F63719
  --filled-bc-hover: #F63719
  --filled-c-hover: #fff

  --outlined-bc-base: #FC563B
  --outlined-c-base: #000
  --outlined-bgc-hover: #FC563B
  --outlined-c-hover: #fff

  --link-c-base: #093282
  --link-c-hover: rgba(9, 50, 130, 0.6)
  &.button-dark
    //--filled-bgc-base: #FC563B
    //--filled-bc-base: #FC563B
    //--filled-c-base: #fff
    //--filled-bgc-hover: #F63719
    //--filled-bc-hover: #F63719
    //--filled-c-hover: #fff

    --outlined-bc-base: #FC563B
    --outlined-c-base: #fff
    --outlined-bgc-hover: #FC563B
    --outlined-bc-hover: transparent
    --outlined-c-hover: #fff

    --link-c-base: rgba(255, 255, 255, 1)
    --link-c-hover: rgba(255, 255, 255, 0.8)
.button.button-primary,
.card
  --filled-bgc-base: #093282
  --filled-bc-base: #093282
  --filled-c-base: #FFF
  --filled-bgc-hover: #144FC3
  --filled-bc-hover: #144FC3
  --filled-c-hover: #fff

  --outlined-bc-base: #111820
  --outlined-c-base: #111820
  --outlined-bgc-hover: #093282
  --outlined-c-hover: #fff

  --link-c-base: #093282
  --link-c-hover: #111820
  &.button-dark
    --outlined-bc-base: #fff
    --outlined-c-base: #fff
    --outlined-bgc-hover: #093282
    --outlined-bc-hover: #093282
    --outlined-c-hover: #fff
.button.button-secondary,
.card
  --filled-bgc-base: #F2F5FA
  --filled-bc-base: #F2F5FA
  --filled-c-base: #111820
  --filled-bgc-hover: #E0E7F3
  --filled-bc-hover: #E0E7F3
  --filled-c-hover: #111820

  --outlined-bc-base: rgba(17, 24, 32, 0.4)
  --outlined-c-base: #111820
  --outlined-bc-hover: #E0E7F3
  --outlined-bgc-hover: #E0E7F3
  --outlined-c-hover: #111820

  --link-c-base: #111820
  --link-c-hover: #093282

  &.button-dark
    --outlined-bc-base: #E0E7F3
    --outlined-c-base: #E0E7F3
    --outlined-bc-hover: transparent
    --outlined-bgc-hover: #E0E7F3
    --outlined-c-hover: #111820

    --link-c-base: #E0E7F3
    --link-c-hover: #093282

.button.button-filled,
.button.button-outlined
  &:before
    content: ''
    position: absolute
    height: auto
    aspect-ratio: 1/1
    pointer-events: none
    z-index: -1
    border-radius: 100%
    left: 50%
    top: 100%
    transition: 0.3s linear
    width: 200%
    scale: 0
    translate: -50% -50%
    will-change: transform


.button.button-filled:is(:hover, :focus, :active),
.button.button-outlined:is(:hover, :focus, :active),
.card:is(:hover, :focus, :active) .button.button-outlined,
.card:is(:hover, :focus, :active) .button.button-filled,
.footer--lk:is(:hover, :focus, :active) .button.button-outlined,
.events--item .item--text-link:is(:hover, :focus, :active) .button.button-outlined,
.events--item .item--image-link:is(:hover, :focus, :active) .button.button-outlined
    &:before
      scale: 120%


