.content_hourse
  &:not(.block_list-slider)
    .block--elements
      grid-template-columns: 1fr 1fr
      @media(max-width: 1024px)
        display: flex
        flex-direction: column
      .block--pagination
        @media(min-width: 1024px)
          grid-column: span 2

.block_image_text.content_hourse-detail
  .block--wrapper
    padding-bottom: 8px
  .block--image ~ .block--head,
  .block--image ~ .block--content
    padding-left: 0
    padding-right: 0




