
.popup
  padding: var(--g-40, 40px) 0
  overscroll-behavior: contain
  background-color: var(--others-fog, rgba(20, 26, 37, 0.45))
  @media(max-width: 768px)
    padding: 25px 8px
    place-items: start
    height: -webkit-fill-available
    height: calc(100 * var(--vh, 1vh))
    max-height: calc(100 * var(--vh, 1vh))
  &--content
    background-color: var(--c-surface-default)
    padding: var(--g-container)
    width: 100%
    border-radius: var(--border-corner)
    @media(max-width: 768px)
      padding: 28px 16px
    &-title
      margin-bottom: var(--g-24)
    &-buttons
      margin-top: 24px
      display: flex
      flex-direction: column
      gap: 16px
      .button
        width: 100%
  &--close
    background-color: #F2F5FA
    border-radius: var(--border-corner-button)
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 16px
    right: -56px
    z-index: 42
    cursor: pointer
    transition: 0.3s ease-out
    color: var(--c-text-subdued)
    @media(max-width: 1024px)
      top: 0
      right: 16px
    .icon-ic24-close
      font-size: 24px
    @media(max-width: 768px)
      left: unset
      right: 16px
      top: 16px
    .button
      padding: 7px
      border: none
  fieldset
    border: none
    padding: 0
  .select
    &--button
      display: none
    &--option_wrapper
      position: static
      opacity: 1
      padding: 0
      border: none
    &--option_list
      transform: translateY(0)
      border: none
      padding: 0
  .form--input_select
    &_title
      display: block
  .filter--buttons
    margin-top: 16px
    display: flex
    flex-direction: column
    gap: 16px
    button
      width: 100%
.popup[data-popup='popup_for_filters']
  .popup--content > button
    width: 100%
    margin-top: 16px
.popup.popup-menu
  place-items: start
  padding: 0
  background-color: var(--accent)
  color: var(--c-text-primary)
  top: 0
  height: calc(100 * var(--vh, 1vh))
  z-index: 49
  @media(max-width: 992px)
    padding-top: var(--headerHeight)
  .popup--content
    width: 100%
    height: 100%
    padding: 0 16px
    transition: 0.24s ease-out
    transition-delay: 0.05s
    display: grid
    gap: 24px
    background-color: transparent
    @media(min-width: 1024px)
      grid-template-columns: repeat(12, 1fr)
  .nav
    flex-direction: column
    align-items: flex-start
    gap: 16px
    border-bottom: 1px solid rgba(255, 255, 255, 0.6)
    padding-bottom: 16px
    &--item
      align-items: flex-start
      flex-direction: column
      font-size: 40px
      @media(max-width: 575px)
        width: 100%
      & > .button
        font-size: 40px
        span
          padding: 0
          white-space: initial
          line-height: 1.1
          text-align: left
        .icon-calendar
          justify-content: flex-start
          font-size: inherit
  .is_dropdown.is_open
    .is_dropdown--wrapper
      position: static
      padding: 0
  .is_dropdown-events
    .events--item
      .item--image-link
        aspect-ratio: 340 / 120
      .item--text-link
        .button
          --outlined-bc-base: #e0e7f3
          --outlined-c-base: #e0e7f3
          --outlined-bc-hover: #e0e7f3
          --outlined-bgc-hover: #e0e7f3
          --outlined-c-hover: #111820
    .events--item .item--text-link,
    .events--item .item--image-link
      background-color: #144FC3
      .title [class^=icon-],
      .title [class*=' icon-']
        color: currentColor
  .is_dropdown--content
    display: flex
    padding: 0
    flex-direction: column
    border: none
    background-color: var(--accent)
    gap: 16px
    .is_dropdown--content-col
      display: flex
      flex-direction: column
      gap: 16px
    .is_dropdown--content-title,
    .is_dropdown--content-title .button
      color: var(--c-text-primary)
      font-size: 24px
      text-transform: uppercase
    .button,
    .events--title,
    .item--text-link .title
      color: var(--c-text-primary)
    .button
      span
        pointer-events: none


.popup.popup-cascade
  .popup--content
    max-width: 880px
    width:  100%
    padding-top: var(--g-20)
    //overflow-y: scroll
    max-height: 100%
    overscroll-behavior: contain


.popup-notification.is_active
  pointer-events: none
  .popup--content
    pointer-events: all
.popup-notification
  background-color: transparent
  gap: var(--g-elements)
  padding: var(--g-32)
  display: flex
  align-items: flex-end
  justify-content: flex-end
  .popup--content
    max-width: 540px
    width: 100%
    min-width: 275px
    background-color: var(--c-surface-default)
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05), 0px 4px 24px 0px rgba(23, 29, 40, 0.10)
    padding: var(--fs-24) 48px var(--fs-24) var(--fs-24)
    border-radius: var(--border-corner)
    @media(max-width: 768px)
      max-width: 100vw
      min-width: 225px
    a
      text-decoration: underline
      color: var(--accent)
    .button
      margin-top: 16px
      font-size: var(--fs-24)
      padding: 0
      line-height: 1

.popup-form
  padding: var(--g-56) 8px
  @media(max-width: 768px)
    padding: var(--g-8)
  .popup--content
    transition: transform 0.18s 0.03s ease-in-out
    max-width: 860px
    height: 100%
    padding: var(--g-section-inner) var(--g-48)
    @media(max-width: 768px)
      max-width: 100vw

.popup-tour_map
  position: absolute
  width: auto
  height: auto
  padding: var(--g-content) calc((100vw - var(--container)) / 2 + 32px)
  background: transparent
  place-items: end
  pointer-events: none
  padding: var(--g-56)

  &.is_active
    pointer-events: none
    .popup--content
      pointer-events: auto
  .popup--close
    position: absolute
    left: auto
    right: 16px
    top: 16px
  .popup--content
    pointer-events: none
    width: 100%
    max-width: 760px
    padding: var(--g-32) var(--g-24)
    border: 1px solid var(--border-color, #f5f4f4)
    border-radius: var(--border-corner)
    background: var(--surface-default, #fff)
    background-color: var(--c-surface-default)
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12)
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12)

  .popup--content-title
    font-size: var(--fs-32)
    font-weight: 500
    font-family: 'Gilroy'
    margin-bottom: 12px

.popup-infrastructure
  //position: absolute
  //height: auto
  //width: auto
  //z-index: 20
  //padding: 0
  display: flex
  @media(min-width: 769px)
    position: static
    width: auto
    height: 100%
    z-index: 1
    border-radius: var(--border-corner)
    overflow: hidden
  @media(min-width: 769px)
    padding: 0
  //  margin: 25px 8px
  .popup--number
    display: none
    @media(min-width: 769px)
      background-color: #FFFFFF
      border-radius: 50%
      width: 52px
      position: absolute
      left: 16px
      top: 16px
      aspect-ratio: 1/1
      display: flex
      align-items: center
      justify-content: center
      &:after
        content: attr(data-number)
        font-weight: 500
        font-size: var(--fs-24)
        text-align: center
        display: inline-block
  .popup--content
    border-radius: var(--border-corner)
    overflow: hidden
    height: 100%
    color: var(--c-text-default)
    padding: 0
    display: flex
    flex-direction: column
    @media(max-width: 768px)
      height: auto
  .popup--close
    @media(min-width: 769px)
      display: none
  .popup--content-title,
  .popup--content-text,
  .button_wrapper
    padding: 0 var(--g-container) 0
  .popup--content-image
    aspect-ratio: 560/360
    border-radius: var(--border-corner)
    overflow: hidden
    margin-bottom: var(--g-24)
  .popup--content-title
    font-size: var(--fs-32)
    font-weight: 500
    font-family: 'Gilroy'
    line-height: 1

  .button_wrapper
    padding-bottom: var(--g-container)
    margin-top: 16px
    .button
      margin-top: 0
      padding: 9px 20px


.popup.is_active
  .select--option_wrapper
    pointer-events: auto

