.sidebar
  display: block
  grid-area: side
  min-height: 100%
  align-self: stretch
  flex-shrink: 0
  flex-grow: 0
  height: 100%
  padding-top: 0
  min-height: 100%
  align-self: stretch
  flex-shrink: 0
  flex-grow: 0
  @media(min-width: 998px)
    margin-left: var(--g-32)
  @media(max-width: 998px)
    margin-right: -8px
  &--sticky
    padding: var(--g-24) var(--g-32)
    background-color: var(--c-surface-subdued)
    border-radius: var(--border-corner)
    position: sticky
    top: calc(var(--headerHeight, 137px) + var(--g-32))
    @media(max-width: 998px)
      max-width: 100vw
      overflow-x: auto
      position: static
      overflow-y: hidden
      padding-right: 0
    strong
      margin-bottom: var(--g-24)
      font-weight: 500
      font-family: 'Gilroy'
      font-size: var(--fs-32)
      text-transform: uppercase
      display: block
  &--nav
    @media(max-width: 998px)
      display: flex
      overflow-x: auto
    &_item
      display: block
      padding:  var(--g-24) var(--g-20)
      @media(max-width: 998px)
        white-space: nowrap
        padding: 8px 12px
      &.is_active
        background-color: var(--c-surface-default)
        border-radius: var(--border-corner)



