.nav
  display: flex
  align-items: center
  gap: 0
.footer--col
  .nav
    display: grid
    grid-template-columns: 1fr 1fr
    gap: var(--g-content) 24px
    align-items: flex-start
    width: 100%
    @media(min-width: 768px)
      grid-template-columns: repeat(4, 1fr)
  .nav--item
    &:hover
      color: var(--c-text-disabled)

.nav--list
  list-style: none
  display: flex
  flex-direction: column
  gap: 16px

.nav--list_title
  color: var(--c-surface-default, #fff)
  font-size: var(--fs-28)
  font-family: 'Gilroy'
  font-weight: 300
  line-height: 1
  text-transform: uppercase
  margin-bottom: 4px

.nav--item
  font-size: var(--fs-24)
  display: flex
  align-items: center
  gap: 8px
  font-weight: 400
  transition: var(--transition)
