.block--actions, .button_wrapper
  display: flex
  gap: 8px
  align-items: center
  justify-content: flex-start
  flex-wrap: wrap
  .button
    margin-top: 24px
    @media(max-width: 768px)
      width: 100%
      margin-top: 16px

.block--actions:empty
  display: none
