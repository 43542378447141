.block--sections

  height: max-content
  overflow: hidden
  display: flex
  justify-content: space-between
  align-items: center
  //flex-wrap: wrap
  //gap: 8px var(--g-24)
  grid-area: sections
  margin-top: var(--g-section-inner)
  @media(max-width: 768px)
    //overflow-x: auto
    padding: 20px 8px
    margin: 0 -8px
  button,a
    display: inline-block
    cursor: pointer
    align-items: center
    flex-grow: 0
    flex-shrink: 0
    text-transform: uppercase
    font-size: var(--fs-40)
    padding: 4px 23px
    text-align: center
    white-space: nowrap
    color: var(--c-text-disabled)
    border-radius: var(--border-corner-button)
    border: 1px solid transparent
    @media(max-width: 475px)
      max-width: 260px
      width: max-content
      display: flex
    span
      @media(max-width: 475px)
        display: inline-block
        width: 100%
        max-width: calc(100vw - 35%)
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
    &:hover,
    &:focus
      color: var(--c-text-default)

    &.is_active
      border-color: currentColor
      color: var(--c-text-default)
      position: relative
  &_container
    max-width: calc(100% - 100px - var(--g-24) )
  .slider_controls
    display: none
    @media(min-width: 769px)
      display: flex
      width: max-content
      z-index: 2
      position: relative
      pointer-events: all
      &:after
        background: linear-gradient(90deg, rgba(255,254,254,0) 0%, rgba(255,255,255,1) 15%)
        content: ''
        position: absolute
        left: -40%
        right: -20%
        top: -16px
        bottom: -16px
      .button
        padding: 15px
        &:before
          transition: .15s linear
