.content_services
  color: var(--c-text-primary)
  background-color: var(--bg-color, #093282)
  .block--head
    border-bottom: 1px solid var(--c-border-subdued)
    .block--head_caption
      text-align: right
      @media(max-width: 768px)
        text-align: center
        order: -1
      img
        max-width: 256px
        @media(max-width: 768px)
          max-width: 160px
          margin-bottom: 16px
  .slider_controls
    &:after
      background: linear-gradient(90deg, rgba(255,254,254,0) 0%, var(--accent) 15%)
    .button
      --outlined-bc-base: #e0e7f3
      --outlined-c-base: #e0e7f3
      --outlined-bc-hover: #e0e7f3
      --outlined-bgc-hover: #e0e7f3
      --outlined-c-hover: #111820

  .item
    display: flex
    flex-direction: column
    gap: var(--g-8)
    @media(min-width: 769px)
      display: grid
      grid-template-columns: repeat(2, 1fr)

    &--image

      //aspect-ratio: initial
      border-radius: var(--border-corner)
      overflow: hidden
      display: flex
      img,video
        aspect-ratio: 950 / 640
        width: 100%
        object-fit: cover
    &--content
      padding: var(--g-content) var(--g-section-inner)
      display: flex
      flex-direction: column
      gap: 16px
      border-radius: var(--border-corner)
      color: var(--c-text-default)
      background-color: var(--c-surface-default)
    .is_cascade
       p
        font-size: var(--fs-20)
        opacity: 0.7
    &--actions
      margin-top: 0
      @media(min-width: 769px)
        margin-top: 8px
    &--title
      text-transform: uppercase
      font-size: var(--fs-52)
    &--text
      font-size: var(--fs-24)
    &--links
      display: none
      @media(min-width: 769px)
        margin-top: var(--g-32)
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: var(--g-8)
        .button
          justify-content: space-between
          width: 100%
          span:not([class^=icon-])
            white-space: initial
            display: -webkit-box
            overflow: hidden
            text-overflow: ellipsis
            -webkit-line-clamp: 1
            -webkit-box-orient: vertical
  .block--sections
    .tab
      color: var(--c-text-primary)
      &:hover,
      &:focus
        color: var(--c-text-disabled)
      &.is_active
        color: var(--c-text-primary)

