.block_list
  &:not(.block_list-slider)
    .block--elements
      display: grid
      grid-template-columns: repeat(4, 1fr)
      gap: 20px
      place-items: stretch
      @media(max-width: 1440px)
        grid-template-columns: repeat(3, 1fr)
        gap: 8px
      @media(max-width: 768px)
        grid-template-columns: repeat(2, 1fr)
      .block--sections
        grid-area: sections
      .block--pagination
        grid-column: span 4
        @media(max-width: 1440px)
          grid-column: span 3
        @media(max-width: 640px)
          grid-column: span 2
      @media(max-width: 768px)
        display: flex
        flex-direction: column
        .card
          width: 100%
          height: max-content
        .block--pagination
          grid-column: span 1

  &.block_list-slider
    position: relative
    overflow: hidden
    .block--elements
      display: flex
      flex-direction: column
  &.block_list-slider-v2
    .block--wrapper
      display: flex
      flex-direction: column
      gap: 20px
      @media(min-width: 998px)
        display: grid
        grid-template-columns: 1.5fr 3fr
    .block--head
      display: flex
      flex-direction: column
      background-color: var(--accent)
      color: var(--c-surface-default)
      display: flex
      flex-direction: column
      padding: var(--g-content)
      text-align: left
      align-items: flex-start
      border-radius: var(--border-corner)
      gap: 16px
      h2
        font-size: var(--fs-52)
      &_subtitle

        color: var(--accent-2)
      &_caption
        color: #E0E7F3
        font-size: var(--fs-24)
    .block--elements
      overflow: hidden
      margin: 0
      margin-right: calc(var(--g-container) * -1)
      padding-right: 18%
      @media(max-width: 768px)
        padding-right: 10%
      .card--label
        display: none
      .card--content
        gap: 12px
      .card--actions
        margin-top: auto

.block_list:not(.block_list-slider-v2)
  .block--wrapper
    display: grid
    grid-template-columns: 1fr auto
    grid-template-areas: 'heading heading  ' 'sections  filter '  ' elements elements '
    gap: 0
    align-items: center
    @media(max-width: 768px)
      grid-template-areas: 'heading heading  ' 'sections sections' 'filter filter' ' elements elements '
    .block--head
      grid-area: heading
    .block--elements
      grid-area: elements
    .block--sections
      grid-area: sections

