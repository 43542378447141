.content_gallery-action
  .block--wrapper
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: var(--g-8)
    @media(max-width: 768px)
      display: flex
      flex-direction: column
    .block--content
      margin-top: 0
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: var(--g-8)
      @media(max-width: 768px)
        display: flex
        flex-direction: column
      .item
        display: flex
        flex-direction: column
        border-radius: var(--border-corner)
        padding: var(--g-24)
        background-color: var(--accent)
        color: var(--c-text-primary)
        .button
          margin-top: auto
          margin-left: auto
          padding: 9px
          @media(min-width: 769px)
            margin-top: var(--g-56)
            padding: 13px
          &:before
            transition: var(--transition)
      .item:first-of-type
        padding: var(--g-56)
        grid-column: span 2
      .item:nth-child(2)
        grid-column: 1
      .item:last-of-type
        grid-column: 2
      .item--title
        text-transform: uppercase
        font-family: 'Gilroy'
        font-size: var(--fs-96)
      .item--text
        font-size: var(--fs-32)
    .block--image
      border-radius: var(--border-corner)
      overflow: hidden
  .slider_controls
    .swiper-pagination
      @media(min-width: 769px)
        margin-left: 0
        margin-right: auto
    .button
      padding: 13px
