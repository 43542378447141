.block--head
  display: flex
  flex-direction: column
  gap: 8px
  padding-bottom: var(--g-24)
  border-bottom: 1px solid currentColor
  @media(min-width: 998px)
    display: grid
    grid-template-columns: 2fr 1fr
    grid-template-rows: auto
    grid-template-areas: 'subtitle caption' 'title caption' 'title action'
  &_subtitle
    grid-area: subtitle
    font-family: 'Gilroy'
    font-weight: 500
    //grid-row: 1
    grid-column: 1
    font-size: var(--fs-24)
    width: fit-content
    color: var(--accent)
    &:empty
      display: none
    p
      letter-spacing: 0.28px
      text-transform: uppercase
      @media(max-width: 1024px)
        margin-bottom: 8px
      &:empty
        display: none
  &_caption
    grid-area: caption
    grid-column: 2
    //grid-row: 2
    max-width: 868px
    font-size: var(--fs-32)
    &:empty
      display: none
    p

      font-weight: 300
      letter-spacing: -0.4px
      &:empty
        display: none
    img
      object-fit: contain
  &_title
    grid-area: title
    //grid-row: span 3
    grid-column: 1
    max-width: 1160px
    text-align: left
    h1, h2, h3
      text-transform: uppercase
    a
      &:hover,
      &:focus
        color: var(--c-text-accent-default)
  &_link
    grid-area: action
    display: flex
    justify-content: flex-end
    align-items: flex-end
    //grid-row: span 3
    grid-column: 2
    .button
      text-transform: uppercase
      color: var(--accent-2)
      font-size: var(--fs-32)
      padding-right: 6px
      .icon-arrow-r
        transition: var(--transition) translate
      &:hover
        .icon-arrow-r
          translate: 6px 0

  .block--actions
    grid-area: action
    justify-content: flex-end
    align-items: flex-end
    grid-column: 2
    //grid-row: span 3
    @media(max-width: 998px)
      align-items: flex-start
      justify-content: flex-start

.footer .block--head
  display: flex

  align-items: center
