.note
  position: relative
  //display: none
  background-color: #F2F5FA
  padding: var(--g-20) var(--g-80)
  transition: var(--transition)
  &-close
    position: absolute
    right: var(--g-80)
  &-content
    max-width: 1240px
    padding-right: 32px
  &-title
    display: flex
    gap: 6px
    margin-bottom: 8px
    font-size: var(--fs-20)
    @media(max-width: 768px)
      flex-direction: column
    span:first-of-type
      opacity: 0.5
  &-text
    opacity: 0.7
