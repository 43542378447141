input, textarea, select
  border: none
  outline: none
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

textarea
  resize: none
/* Firefox */
input[type=number]
  -moz-appearance: textfield


.form
  max-width: 868px
  fieldset
    border-color: transparent
    padding: 0
    display: grid
    grid-template-columns: 1fr
  &--title
    font-size: var(--fs-96)
    font-weight: 300
    text-transform: uppercase
    font-family: 'Gilroy'
    margin-bottom: 12px
    letter-spacing: -0.02em
    line-height: 1
  &--text
    color: var(--c-text-subdued)
    font-size: var(--fs-32)
    margin-bottom: 16px
  &--title,
  &--text
    .form--input:first-of-type
      margin-top: var(--g-content)
  &--input:first-of-type
    margin-top: 0
  &--input
    display: flex
    flex-direction: column
    margin-top: var(--g-content)
    & > span
      order: -1
      font-size: 20px
      color: var(--c-text-default)
      font-weight: 300
      text-transform: uppercase
      font-family: 'Gilroy'
      letter-spacing: -0.02em
      transition: var(--transition)
      transform-origin: left top
      margin-bottom: 8px
      & + label
        margin-top: 16px

    & > input, textarea, select
      outline: none
      padding: 0 0 8px
      line-height: 30px
      font-size: var(--fs-20)
      font-weight: 300
      border-bottom: 1px solid #E8E8E8
      background-color: transparent
      transition: var(--transition)
      transform-origin: left top
      &:is(:focus,:active)
        border-bottom: 1px solid rgba(20, 79, 195, 0.4)

      &::placeholder,
      &:placeholder-shown
        color: var(--c-text-disabled)
        font-size: 16px
      &:invalid:not(:placeholder-shown)
        border-color: var(--c-critical)
      &:valid:not(:placeholder-shown)
        border-color: rgba(20, 79, 195, 0.4)
    &.error
      .form--input_error
        opacity: 1
        max-height: 48px
        height: auto
      input, textarea, select
        border-color: #E2A0A0
    &_error
      color: var(--c-critical)
      opacity: 0
      font-size: 14px
      line-height: 20px
      max-height: 0
      height: 0
      overflow: hidden
      transition: opacity 0.03s 0.15s ease-out, max-height .18s linear
      margin-top: 8px
    & > label
      display: flex
      align-items: flex-start
      & + label
        margin-top: 16px
  input[type="radio"],
  input[type="checkbox"]
    display: none
    order: -1
    & ~ span
      order: 1
      font-size: 16px
      margin-left: 12px
      line-height: 20px
    & ~ svg
      flex-shrink: 0
      flex-grow: 0
      cursor: pointer
  input[type="checkbox"]
    & ~ svg
      rect
        stroke: #000
    &:hover ~ svg,
    &:focus ~ svg
      //rect
      //  fill: #144FC3
    &:checked ~ svg
      rect
        fill: #144FC3
  input[type="radio"]
    & ~ svg
      rect:nth-child(1)
        stroke: #000
    &:hover ~ svg,
    &:focus ~ svg
      rect:nth-child(1)
        stroke: #144FC3
    &:checked ~ svg
      rect:nth-child(2)
        stroke: #144FC3
        fill: #144FC3

  input[type="date"]
    appearance: none

  select
    -moz-appearance: none
    -webkit-appearance: none
    appearance: none
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 15L8 10L16 10L12 15Z' fill='%23141A25'/%3E%3C/svg%3E")
    background-position: right 4px center
    background-repeat: no-repeat
    background-size: 24px
    background-color: transparent
    border-radius: 0
    display: block
    padding-right: 48px
    option
      background: #fff
      border-radius: 0
      padding: 4px 6px

  &--policy
    font-size: 16px
    font-weight: 400
    margin-top: 8px
    color: var(--c-text-secondary)
    a:not(.button)
      font-size: 16px
      font-weight: 400
      text-decoration: underline
      color: var(--accent)
      &:hover
        opacity: 0.7

  &--button
    margin-top: var(--g-24)
    display: flex
    align-items: center
    gap: 6px
    @media(max-width: 575px)
      flex-direction: column
    .button
      @media(max-width: 575px)
        width: 100%
      &:after
        content:  attr(data-text)
        display: inline-block

  .switch
    align-items: center
    .slider
      position: relative
      width: 46px
      height: 24px
      cursor: pointer
      -webkit-transition: .4s
      -o-transition: .4s
      transition: .4s
      border-radius: 34px
      background-color: transparent
      border: 1px solid #000
      border-radius: 30px
      &:before
        position: absolute
        top: 50%
        left: 30%
        translate: -50% -50%
        width: 18px
        height: 18px
        content: ''
        transition: .4s
        border-radius: 50%
        background-color: white
        border: 1px solid #000
    input:checked ~ .slider
      background-color: #144FC3
    input:checked ~ .slider:before
      left: 70%

.form.is_sending
  button[type="submit"]
    background: var(--c-surface-subdued)
    pointer-events: none

.confirmation
  max-width: 320px
  @media(max-width: 768px)
    text-align: center
  .access
    color: var(--accent)
  .error
    color: var(--c-critical)

.content_subscription
  margin-top: auto
  .form
    display: flex
    gap: 8px
    flex-direction: column
    margin-top: 24px
    p
      color: var(--c-text-disabled)
    &--input
      margin-top: 0
      gap: 16px
      flex-direction: row
      border-bottom: 1px solid #E8E8E8
      position: relative
      input
        flex-grow: 1
        border-bottom: none
        &:placeholder-shown,
        &::placeholder
          text-transform: uppercase
          border-color: rgba(255, 255, 255, .7)
      & > span
        font-size: var(--fs-28)
      .form--input_error
        position: absolute
        top: 100%



.form--input_select
  display: flex
  flex-direction: column
  & > span
    font-family: 'Gilroy'
    font-size: 20px
    font-weight: 300
    margin-bottom: 8px
    transition: var(--transition)
    transform-origin: left top
    letter-spacing: -.02em
    text-transform: uppercase
    color: var(--c-text-default)
    order: -1
  select
    -moz-appearance: none
    -webkit-appearance: none
    appearance: none
    display: none
    option
      display: none
  &_title
    margin-bottom: 16px
    display: none
    font-weight: 500
    font-family: 'Gilroy'
    line-height: 1
.select
  position: relative
  &:hover
    .select--button
      border-color: #11182066
  &:has(.is_selected)
    .select--button
      border-color: #11182066
      color: var(--c-text-default)
  &--button
    border: 1px solid rgba(0,0,0,0.06)
    border-radius: 12px
    padding: 12px 16px
    position: relative
    font-size: 16px
    cursor: pointer
    transition: var(--transition)
    color: var(--c-text-default)
    display: flex
    align-items: center
    justify-content: space-between
    &:hover,
    &:focus
      border-color: var(--c-accent)
    &:after
      font-family: 'icomoon' !important
      content: "\e907"
      display: flex
      align-items: center
      justify-content: center
      width: 24px
      height: 100%
      right: 10px
      top: 0
  &--option_wrapper
    opacity: 0
    pointer-events: none
    padding-top: 12px
    position: absolute
    width: auto
    min-width: 100%
    height: fit-content
    max-height: 320px
    z-index: 3
    &.is_opened
      opacity: 1
      pointer-events: all
      .select--option_list
        transform: translateY(0)
      .select--option:nth-child(1)
        display: none


  &--option_list
    background-color: #fff
    transition: var(--transition)
    transform: translateY(-8px)
    display: flex
    flex-direction: column
    gap: 8px
    padding: 8px 12px
    border: 1px solid rgba(0,0,0,0.06)
    border-radius: 16px

  &--option
    padding: 12px
    transition: var(--transition)
    cursor: pointer
    border-radius: 8px
    font-size: 20px
    display: flex
    gap: 6px
    align-items: center
    white-space: nowrap
    &:first-of-type
      display: none
    .select--checkbox
      position: relative
      order: -1
      width: 24px
      height: 24px
      aspect-ratio: 1/1
      border-radius: 4px
      border: 1px solid #111820
      &:before
        font-family: 'icomoon' !important
        content: '\e906'
        position: absolute
        left: 50%
        top: 50%
        translate: -50% -50%
        color: #FFFFFF
        padding: 2px
    &:hover,
    &:focus,
    &:active
      .select--checkbox
        border-color: #144FC3

    &.is_selected
      .select--checkbox
        background-color: #144FC3
        border-color: #144FC3


form:not(.filter)
  .select--checkbox
    display: none
  .select--option.is_selected
    background-color: #F2F5FA
    color: #144FC3
  .select--option_wrapper.is_opened.select--option_list
    box-shadow: 0px 4px 24px 0px #171D281A
    //box-shadow: 0px 4px 12px 0px #0000000D

form.is_processing .button[type='submit']
  pointer-events: none
  opacity: 0.7
  &:after
    content: 'Отправляется'

