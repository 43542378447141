.header
  position: sticky
  top: -1px
  left: 0
  width: 100%
  max-width: 100vw
  z-index: 50

  transform: translateY(0)
  transition: 0.18s linear
  --hover--bck-color: rgba(255, 255 ,255 , 0.1)
  will-change: tranform
  @media(max-width: 1024px)
    transition: var(--transition)
  &--main
    color: var( --c-text-primary)
    background: var(--accent)
  .header--logo svg
      fill: #fff
  &.is_scrolled
    .header--main
      color: var(--c-text-default)
      background-color: #FFFFFF
    .header--logo svg
      fill: var(--accent)
  &.is_menu_opened
    position: fixed
  &.header-transparent
    //position: fixed
  &.is_menu_opened
    .header--main
      color: var( --c-text-primary)
      background: var(--accent)
    .header--logo svg
      fill: #fff


.header--main
  padding: 18px var(--g-container)
  display: flex
  @media(max-width: 1024px)
    display: flex
    justify-content: space-between

.header--nav
  display: flex
  align-items: center
  justify-content: flex-start
  //overflow-x: clip
  .nav
    width: auto
    max-width: 100%
    display: flex
    align-items: center
    justify-content: flex-start
    &--item
      padding: 10px 16px
      white-space: nowrap
      border-radius: var(--border-corner-button)
      @media(min-width: 1440px)
        padding: 12px 20px
      &:hover
        background-color: var(--hover--bck-color)
      .is_dropdown--content
        display: flex
        gap: 16px var(--g-32)
        a span
          padding: 0
      .is_dropdown--content-col
        display: flex
        flex-direction: column
        gap: 8px
      .is_dropdown--content-col-mob
        @media(min-width: 1024px)
          display: none

      .is_dropdown--content-title
        font-size: var(--fs-24)
        text-transform: uppercase
        margin-bottom: 16px
        max-width: 200px
        white-space: normal
        display: -webkit-box
        overflow: hidden
        text-overflow: ellipsis
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical




  .nav--item:not(.menu_button)
    @media(max-width: 1024px)
      display: none
  .nav--item.menu_button
    @media(max-width: 1024px)
      .button_text
        display: none

.header--actions
  display: flex
  align-items: center
  margin-left: auto
  gap: clamp(8px, calc(8px + (16 - 8) * ((100vw - 1024px) / (1920 - 1024))), 16px)
  padding-left: 24px
  @media(max-width: 768px)
    padding-left: 16px
  .header--contacts
    display: flex
    flex-direction: column
    align-items: flex-end
    &-adress
      font-size: 12px
      opacity: 0.7
      display: flex
      align-items: center
      gap: 2px
      @media(max-width: 768px)
        font-size: 10px
      span
        @media(max-width: 768px)
          padding: 0
    .icon-chevron-down
      @media(max-width: 998px)
        display: none
    .is_dropdown--content
      padding: var(--g-32)
    .is_dropdown--wrapper
      right: 0
      left: auto

.header--actions_mobile
  .button
    background-color: var(--accent-2)
    border: var(--border-corner-button)
    color: var(--c-text-primary)
    padding: 8px
    border-radius: var(--border-corner-button)
  span
    font-size: 18px



  @media(min-width: 1025px)
    display: none

.header--lk
  @media(max-width: 1024px)
    display: none

.header--logo
  display: flex
  align-items: center
  justify-content: flex-start
  svg
    height: 60px
    @media(min-width: 1025px)
      padding-right: 24px
      min-width: 180px
    @media(max-width: 1024px)
      justify-content: center
      max-width: 120px
      height: 50px

.button.menu_button
  .icon-close
    display: none
  .is_menu_opened &
    .icon-close
      display: inline-flex
    .icon-burger
      display: none

.is_menu_opened
  & ~ .header_mobile
    .icon-close
      display: inline-flex
    .icon-burger
      display: none

