.content_basic
  &:not(.block_list-slider)
    .block--elements
      grid-template-columns: repeat(3, 1fr)
      @media(max-width: 640px)
        grid-template-columns: 1fr
    .block--pagination
      grid-column: span 3
      @media(max-width: 640px)
        grid-column: span 1

