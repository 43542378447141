
.is_dropdown
  position: relative
  overflow: visible
  //cursor: pointer
  &.hidden
    display: none
  .icon-chevron-down
    transform: scaleY(1)
    transform-origin: center
    transition: 0.18s transform ease-in-out
  @media(min-width: 1024px)
    &:hover,
    &:focus
      .icon-chevron-down
        transform: scaleY(-1)
      .is_dropdown--wrapper
        opacity: 1
        pointer-events: all
        transform: translateY(0)
  &.is_open
    .icon-chevron-down
      transform: scaleY(-1)
    .is_dropdown--wrapper
      opacity: 1
      pointer-events: all
      transform: translateY(0)
      z-index: 1

.is_dropdown--wrapper
  opacity: 0
  pointer-events: none
  position: absolute
  left: 0
  top: 100%
  width: fit-content
  min-width: 100%
  padding-top: 24px
  @media(min-width: 992px)
    transition: opacity 0.13s linear, transform 0.18s linear
    transform: translateY(-48px)

.is_dropdown--content
  background-color: var(--c-surface-default)
  color: var(--c-text-default)
  padding: 20px
  border: 1px solid var(--others-divider, #E0E1E3)
  border-radius: var(--border-corner)
  --hover-color: var(--c-text-disabled)
  @media(min-width: 992px)
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.10)

.is_dropdown-events
  .events
    display: flex
    gap: 16px
    &--item
      display: flex
      flex-direction: column
      gap: 16px
      font-size: var(--fs-28)
      .item--text-link,
      .item--image-link
        height: 100%
        border-radius: var(--border-corner)
        overflow: hidden
        padding: 20px
        background-color: var(--c-surface-subdued)
        display: flex
        justify-content: space-between
        gap: 16px
        .title
          z-index: 2
          margin-bottom: auto
        .button
          margin-top: auto
          position: relative
          right: -10px
          bottom: -10px
          padding: 13px
          &:before
            transition: .15s linear

      .item--text-link
        .title
          display: flex
          align-items: center
          [class^="icon-"], [class*=" icon-"]
            color: var(--accent)
      .item--image-link
        position: relative
        color: var(--c-surface-default)

        aspect-ratio: 360 / 250
      .item--image-link img
        filter: brightness(70%)
        position: absolute
        inset: 0
    &--title
      font-size: var(--fs-32)
      font-family: 'Gilroy'


.showMore
  .is_dropdown
    display: block
    padding: 0
    .is_dropdown--wrapper
      display: none
  .nav--item
    padding: 0
    .button span[class^=icon-]
      display: none

