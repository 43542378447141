.block
  &--wrapper
    padding: var(--g-container-vertical) var(--g-container)
    margin: 0 auto


  &--content,
  &--elements
    margin-top: var(--g-section-inner)

main .block:first-child
  .block--wrapper
    padding-top: 0
