body:not(.page-calendar) .content_events
  overflow: hidden
  &:not(.block_list-slider)
    .block--elements
      gap: 0 var(--g-20)
      grid-template-columns: repeat(2, 1fr)
      grid-template-rows: auto
      @media(max-width: 1140px)
        gap: var(--g-8) var(--g-20)
        display: flex
        flex-direction: column
      .card:first-of-type
        @media(min-width: 1024px)
          grid-column: 1
          grid-row: 1/4
      .card:not(:first-of-type)
        @media(min-width: 1024px)
          grid-column: 2
      .card:nth-child(2)
        @media(min-width: 1024px)
          grid-row: 1
      .card:nth-child(3)
        @media(min-width: 1024px)
          grid-row: 2
      .card:nth-child(4)
        @media(min-width: 1024px)
          grid-row: 3
    .block--pagination
      grid-column: span 3
      @media(max-width: 640px)
        grid-column: span 1
.content_events-detail.block_image_text
  .block--image
    grid-row: 1/span 3
.content_events-detail
  .block--wrapper
    padding-bottom: 8px
  .block--head
    padding-bottom: 0
  .date
    color: var(--accent-2)
    font-size: var(--fs-32)
    display: flex
    gap: 6px
    span:last-child
      opacity: 0.7
  .info
    padding: 12px 0
    display: flex
    flex-direction: column
    gap: 12px
    & > *
      display: flex
      align-items: baseline
      //justify-content: space-between
      &:before
        content: ''
        border-bottom: 2px dotted  #cccccc
        flex-grow: 1
        order: 1
        margin: 0 5px
        min-width: 30px
      p:first-child
        color: var( --c-text-subdued)
      p + p
        order: 2


